import React from "react";

const SortIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M4.85869 13.3081C4.74148 13.1908 4.58251 13.125 4.41675 13.125C4.25099 13.125 4.09202 13.1908 3.97481 13.3081C3.8576 13.4253 3.79175 13.5842 3.79175 13.75C3.79175 13.9158 3.8576 14.0747 3.97481 14.1919L6.47481 16.6919C6.59202 16.8092 6.75099 16.875 6.91675 16.875C7.08251 16.875 7.24148 16.8092 7.35869 16.6919L9.85846 14.1922C9.97567 14.075 10.0417 13.9158 10.0417 13.75C10.0417 13.5842 9.9759 13.4253 9.85869 13.3081C9.74148 13.1908 9.58251 13.125 9.41675 13.125C9.25099 13.125 9.09202 13.1908 8.97481 13.3081L6.91675 15.3661L4.85869 13.3081Z"
        fill="#9F9F9F"
      />
      <path
        d="M6.29175 3.75V16.25C6.29175 16.5952 6.57157 16.875 6.91675 16.875C7.26193 16.875 7.54175 16.5952 7.54175 16.25V3.75C7.54175 3.40482 7.26193 3.125 6.91675 3.125C6.57157 3.125 6.29175 3.40482 6.29175 3.75Z"
        fill="#9F9F9F"
      />
      <path
        d="M16.4745 6.69162C16.5917 6.80883 16.751 6.875 16.9167 6.875C17.0825 6.875 17.2415 6.80915 17.3587 6.69194C17.4759 6.57473 17.5417 6.41576 17.5417 6.25C17.5417 6.08424 17.4759 5.92527 17.3587 5.80806L14.8587 3.30806C14.7415 3.19085 14.5825 3.125 14.4167 3.125C14.251 3.125 14.092 3.19085 13.9748 3.30806L11.4748 5.80806C11.3576 5.92527 11.2917 6.08424 11.2917 6.25C11.2917 6.26001 11.292 6.27002 11.2925 6.28002C11.2999 6.43522 11.3649 6.58207 11.4748 6.69194C11.592 6.80915 11.751 6.875 11.9167 6.875C12.0825 6.875 12.2415 6.80915 12.3587 6.69194L14.4167 4.63388L16.4745 6.69162Z"
        fill="#9F9F9F"
      />
      <path
        d="M15.0417 16.25V3.75C15.0417 3.40482 14.7619 3.125 14.4167 3.125C14.0716 3.125 13.7917 3.40482 13.7917 3.75V16.25C13.7917 16.5952 14.0716 16.875 14.4167 16.875C14.7619 16.875 15.0417 16.5952 15.0417 16.25Z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

export default SortIcons;
