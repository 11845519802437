import React from "react";

const Reward2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="reward-2">
        <g id="SVGRepo_iconCarrier">
          <path
            id="secondary"
            d="M16.11 16.66L17 22L12 21L7 22L7.89 16.66"
            stroke="#00B783"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="primary"
            d="M12 17.0001C15.866 17.0001 19.0001 13.866 19.0001 10C19.0001 6.13402 15.866 3 12 3C8.13402 3 5 6.13402 5 10C5 13.866 8.13402 17.0001 12 17.0001Z"
            stroke="#1C1C1C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default Reward2;
