import React from "react";
import Modal from "../../Pages/Landing/Components/Modal";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addRewardProviderApi } from "../../redux/Thunks/RewardsThunk";

const AddNewRewardProviderModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("provider_name", data.Pname);
      formData.append("conversion_rate", data.rate);
      formData.append("balance", data.balance);

      dispatch(addRewardProviderApi({ data: formData }));
      reset();
      closeModal();
    } catch (error) {
      console.error("An error occurred during form submission", error);
    }
  };

  return (
    <Modal
      closeIcon
      isOpen={isModalOpen}
      onClose={() => {
        reset();
        closeModal();
      }}
      closeOnOverlayClick={false}
      title="Add New Provider"
      customTitle
      circleClose
      height={400 + Object.keys(errors).length * 30}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 30,
          gap: 15,
        }}
      >
        <div className="w-full flex flex-col">
          <label htmlFor="Pname" className="text-[14px] font-[600] font-inter">
            Provider Name
          </label>
          <input
            id="Pname"
            name="Pname"
            type="text"
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("Pname", {
              required: {
                value: true,
                message: "Provider Name is required",
              },
            })}
          />
          {errors["Pname"] && (
            <p className="text-red-500">{errors["Pname"].message}</p>
          )}
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="rate" className="text-[14px] font-[600] font-inter">
            Conversion Rate
          </label>
          <input
            id="rate"
            name="rate"
            type="number"
            step="0.1"
            min={0}
            max={100}
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("rate", {
              required: {
                value: true,
                message: "Conversion Rate is required",
              },
            })}
          />
          {errors["rate"] && (
            <p className="text-red-500">{errors["rate"].message}</p>
          )}
        </div>
        <div className="w-full flex flex-col">
          <label
            htmlFor="balance"
            className="text-[14px] font-[600] font-inter"
          >
            Balance
          </label>
          <input
            id="balance"
            name="balance"
            type="number"
            step="1"
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("balance", {
              required: {
                value: true,
                message: "Balanceis required",
              },
            })}
          />
          {errors["balance"] && (
            <p className="text-red-500">{errors["balance"].message}</p>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <button
            style={{
              backgroundColor: "#2B4D70",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              width: 128,
              height: 40,
              borderRadius: 8,
            }}
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddNewRewardProviderModal;
