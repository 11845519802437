import React from "react";

const WaterIcon = () => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.19411 0.81236C4.88387 0.542845 4.14716 0.109633 4.02657 1.23165C3.59359 4.13356 0.47583 6.16162 0.47583 9.02275C0.47583 11.4345 2.43097 13.3896 4.84277 13.3896C7.25453 13.3896 9.20967 11.4345 9.20967 9.02275C9.20963 6.83453 8.33134 3.53768 5.19411 0.81236ZM3.13477 11.3389C3.06713 11.4294 2.9636 11.4773 2.85885 11.4773C2.78795 11.4773 2.71646 11.4554 2.655 11.4099C2.6166 11.3815 1.71159 10.6983 1.51651 9.4069C1.32148 8.11582 2.19579 6.90588 2.23312 6.85499C2.34547 6.70177 2.56087 6.66851 2.71423 6.78104C2.86731 6.8934 2.90053 7.10843 2.78849 7.26175C2.77716 7.27737 2.04547 8.29997 2.19722 9.30413C2.34843 10.3054 3.05872 10.8522 3.06588 10.8576C3.21709 10.9718 3.24824 11.1872 3.13477 11.3389Z"
        fill="#2B4D70"
      />
      <path
        d="M3.06574 10.8575C3.05858 10.852 2.34828 10.3052 2.19708 9.30398C2.04533 8.29982 2.77702 7.27722 2.78834 7.2616C2.90039 7.10829 2.86717 6.89325 2.71408 6.7809C2.56073 6.66841 2.34533 6.70162 2.23298 6.85484C2.19564 6.90574 1.32134 8.11567 1.51637 9.40676C1.7114 10.6981 2.61641 11.3813 2.65486 11.4098C2.71632 11.4553 2.78781 11.4772 2.85871 11.4772C2.96346 11.4772 3.06699 11.4293 3.13463 11.3388C3.24806 11.1871 3.2169 10.9717 3.06574 10.8575Z"
        fill="#F7F1E6"
      />
      <path
        d="M11.2327 11.3226C11.0556 11.1688 10.6352 10.9216 10.5664 11.5619C10.3194 13.218 8.54004 14.3753 8.54004 16.0081C8.54004 17.3845 9.65584 18.5003 11.0322 18.5003C12.4086 18.5003 13.5243 17.3845 13.5243 16.0081C13.5243 14.7594 13.0231 12.8779 11.2327 11.3226ZM10.0575 17.3299C10.0189 17.3816 9.95986 17.4089 9.90001 17.4089C9.85959 17.4089 9.81877 17.3964 9.78368 17.3705C9.76174 17.3542 9.24527 16.9643 9.13399 16.2274C9.02271 15.4906 9.52164 14.8001 9.5429 14.771C9.60704 14.6836 9.73001 14.6646 9.81747 14.7288C9.90485 14.793 9.92383 14.9157 9.85991 15.0031C9.85337 15.0121 9.43583 15.5957 9.52244 16.1687C9.60879 16.7401 10.014 17.0522 10.0182 17.0553C10.1044 17.1204 10.1222 17.2433 10.0575 17.3299Z"
        fill="#2B4D70"
      />
      <path
        d="M10.0183 17.0553C10.0141 17.0522 9.60888 16.7402 9.52253 16.1688C9.43592 15.5958 9.85346 15.0121 9.86 15.0032C9.92392 14.9157 9.90494 14.793 9.81756 14.7289C9.7301 14.6647 9.60714 14.6836 9.54299 14.7711C9.52173 14.8001 9.02281 15.4906 9.13409 16.2274C9.24532 16.9643 9.76179 17.3543 9.78377 17.3705C9.81882 17.3965 9.85964 17.409 9.90011 17.409C9.95995 17.409 10.019 17.3817 10.0576 17.3299C10.1223 17.2434 10.1045 17.1205 10.0183 17.0553Z"
        fill="#F7F1E6"
      />
    </svg>
  );
};

export default WaterIcon;
