import React, { useState } from "react";
import LandingLogo from "../icons/LandingLogo";
import LandingSecLogo from "../icons/LandingSecLogo";
import BurgerIcon from "../icons/BurgerIcon";
import { useCurrentWidth } from "react-socks";
import { useLanguage } from "../../../Contexts/LanguageContext";
import { Link, animateScroll as scroll } from "react-scroll";
import MenuClose from "../icons/MenuClose";

const navlinks = [
  {
    id: 1,
    text: "Home",
    target: "home",
    arText: "الرئيسية",
  },
  {
    id: 2,
    text: "Overview",
    target: "overview",
    arText: "لمحة عامة",
  },
  {
    id: 3,
    text: "Key Advantages",
    target: "keyAdvantages",
    arText: "المزايا الرئيسة",
  },
  {
    id: 4,
    text: "Early Sign up",
    target: "signUp",
    arText: "التسجيل المبكر",
  },
];

const LandingHeader = () => {
  const width = useCurrentWidth();
  const { language, switchLanguage, style } = useLanguage();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const reversedNavLinks =
    language !== "en" ? [...navlinks].reverse() : navlinks;

  const handleLanguageSwitch = () => {
    const newLanguage = language === "en" ? "ar" : "en"; // Toggle between English and Arabic
    switchLanguage(newLanguage);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  const mobileMenuStyle = {
    position: "absolute",
    top: 90,
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    maxHeight: isMobileMenuOpen ? "500px" : "0", // Set a maximum height
    overflow: "hidden",
    opacity: isMobileMenuOpen ? 1 : 0, // Set opacity based on menu state
    transition: "max-height 0.5s ease-in-out, opacity 0.5s ease-in-out", // Use max-height and opacity for animation
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // boxShadow: "0px 4px 40px 0px rgba(51, 51, 51, 0.32)",
    padding: "15px 0",
    borderBottom: "10px",
  };
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: width > 1024 ? 130 : 90,
        padding: width <= 1024 ? "24px 24px 24px 16px" : "24px 96px",
        position: "sticky",
        top: 0,
        zIndex: 9999,
      }}
    >
      <div
        className=" flex items-center justify-between w-full h-full"
        style={{
          width: "100%",
          // flexDirection: language === "en" ? "row" : "row-reverse",
        }}
      >
        <div
          className="w-1/4 flex justify-start items-center"
          style={{
            // justifyContent: language === "en" ? "flex-start" : "flex-end",
            width: width <= 768 && "50%",
          }}
        >
          <Link
            to="home"
            smooth={true}
            duration={500}
            offset={width <= 768 ? -90 : -130}
            onClick={() => {
              scroll.scrollToTop({ smooth: true, duration: 500 });
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <LandingLogo
              width={width > 1024 ? 202 : 123}
              height={width > 1024 ? 72 : 43}
            />
          </Link>
          <div
            style={{
              marginLeft: 35,
            }}
          >
            <LandingSecLogo />
          </div>
        </div>
        <div
          className="w-3/4 "
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            // width: width <= 768 && "50%",
          }}
        >
          {width <= 768 ? (
            <div onClick={handleMobileMenuToggle}>
              {isMobileMenuOpen ? <MenuClose /> : <BurgerIcon />}
              <div style={mobileMenuStyle}>
                {navlinks.map((item) => (
                  <Link
                    key={item.id}
                    to={item.target}
                    smooth={true}
                    duration={500}
                    offset={-90}
                    onClick={() => {
                      if (item.target === "home" || item.target === "signUp") {
                        scroll.scrollToTop({ smooth: true, duration: 500 });
                      }
                      setIsMobileMenuOpen(false);
                    }}
                    style={{
                      ...style,
                      fontSize: 18,
                      backgroundColor: "#F8F8F8",
                      padding: "8px 24px",
                      margin: "8px 0",
                      border: "1px solid #E4E4E4",
                      fontWeight: 400,
                      color: "#2B4D70",
                      width: "95%",
                      borderRadius: "8px",
                      // cursor
                    }}
                  >
                    {language === "en" ? item.text : item.arText}
                  </Link>
                ))}
                <button
                  style={{
                    // ...style,
                    textAlign: language === "en" ? "right" : "left",
                    fontSize: 18,
                    backgroundColor: "#F8F8F8",
                    padding: "8px 24px",
                    margin: "8px 0",
                    border: "1px solid #E4E4E4",
                    fontWeight: 400,
                    color: "#2B4D70",
                    width: "95%",
                    borderRadius: "8px",
                  }}
                  onClick={handleLanguageSwitch}
                >
                  {language === "en" ? "العربية" : "English"}
                </button>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",

                justifyContent: language === "en" ? "flex-end" : "flex-end",
                gap: width > 768 && width <= 1100 ? 35 : 45,
              }}
            >
              {language !== "en" && (
                <button
                  style={{
                    ...style,
                    fontSize: width > 768 && width <= 1250 ? 16 : 24,
                    fontWeight: 400,
                    color: "#2B4D70",
                  }}
                  onClick={handleLanguageSwitch}
                >
                  {language === "en" ? "العربية" : "English"}
                </button>
              )}

              {reversedNavLinks.map((item) => (
                <Link
                  key={item.id}
                  to={item.target}
                  smooth={true}
                  offset={-130}
                  duration={500}
                  onClick={() => {
                    if (item.target === "home" || item.target === "signUp") {
                      scroll.scrollToTop({ smooth: true, duration: 500 });
                    }
                  }}
                  style={{
                    ...style,
                    fontSize: width > 768 && width <= 1250 ? 16 : 24,
                    fontWeight: 600,
                    color: "#2B4D70",
                    cursor: "pointer",
                  }}
                >
                  {language === "en" ? item.text : item.arText}
                </Link>
              ))}
              {language === "en" && (
                <button
                  style={{
                    ...style,
                    fontSize: width > 768 && width <= 1250 ? 16 : 24,
                    fontWeight: 400,
                    color: "#2B4D70",
                  }}
                  onClick={handleLanguageSwitch}
                >
                  {language === "en" ? "العربية" : "English"}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
