import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { useCurrentWidth } from "react-socks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  // responsive: true,
  scales: {
    x: {
      display: true,
      grid: {
        tickLength: 15,
      },
      title: {
        display: true,
        text: "(tCO₂e)",
        color: "#1C1C1C",
        font: {
          size: 16,
          weight: 500,
          family: "Inter",
        },
      },
      ticks: {
        callback: function (value, index, ticks) {
          return value + "K";
        },
        major: true,
        align: "start",
        color: "#9FA1A2",
        font: {
          size: 14,
          weight: 400,
          family: "Inter",
        },
      },
    },
    y: {
      weight: 10,
      grid: {
        display: false,
      },
      beginAtZero: true,
      ticks: {
        crossAlign: "far",
        color: "#1C1C1C",
        padding: 16,
        font: {
          size: 16,
          weight: 500,
          family: "Inter",
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (xDatapoint) => {
          return xDatapoint.label;
        },
        label: (yDatapoint) => {
          return yDatapoint.raw + "K";
        },
      },
    },
  },
};

const HBarChart = ({ totalDataByMonth }) => {
  const [barChartData, setBarChartData] = useState();
  const [barChartLabels, setBarChartLabels] = useState();
  const width = useCurrentWidth();
  useEffect(() => {
    if (totalDataByMonth) {
      const desiredOrder = [
        "Abu Dhabi",
        "Dubai",
        "Sharjah",
        "Ajman",
        "Umm Al Quwain",
        "Ras Al Khaimah",
        "Fujairah",
      ];
      setBarChartLabels(desiredOrder);
      const barValues = desiredOrder.map(
        (label) => totalDataByMonth?.emissions_data[label] / 1000
      );
      setBarChartData(barValues);
    }
  }, [totalDataByMonth, totalDataByMonth?.emissions_data]);

  const modified = {
    labels: barChartLabels,
    datasets: [
      {
        data: barChartData,
        borderColor: "rgb(255, 255, 255)",
        borderWidth: "5px",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(500, 0, 0, 0);
          gradient.addColorStop(0, "rgb(43, 77, 112)");
          gradient.addColorStop(1, "rgb(0, 183, 131)");

          return gradient;
        },

        barThickness: width <= 768 ? 6 : 12,
        borderRadius: width <= 768 ? 6 : 10,
      },
    ],
  };

  const barChartoptions = {
    indexAxis: "y",
    // responsive: true,
    scales: {
      x: {
        display: true,
        grid: {
          tickLength: 15,
        },
        title: {
          display: true,
          text: "(tCO₂e)",
          color: "#1C1C1C",
          font: {
            size: width <= 768 ? 12 : 16,
            weight: 500,
            family: "Inter",
          },
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value + "K";
          },
          major: true,
          align: "start",
          color: "#9FA1A2",
          font: {
            size: width <= 768 ? 12 : 14,
            weight: 400,
            family: "Inter",
          },
        },
      },
      y: {
        weight: 10,
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          crossAlign: "far",
          color: "#1C1C1C",
          padding: 16,
          font: {
            size: width <= 768 ? 10 : 16,
            weight: 500,
            family: "Inter",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (xDatapoint) => {
            return xDatapoint.label;
          },
          label: (yDatapoint) => {
            return yDatapoint.raw + "K";
          },
        },
      },
    },
  };
  return (
    <Bar
      options={barChartoptions}
      data={modified}
      width={480}
      height={width <= 768 ? 400 : 320}
    />
  );
};

export default HBarChart;
