import React from "react";

const AdvantageIcon = ({ width = 69, height = 77 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 69 77"
      fill="none"
    >
      <path
        d="M51.1221 0.243035C50.6391 0.243845 50.1402 0.251132 49.6636 0.267883L49.1108 0.279665C39.7261 0.627149 30.6423 3.68172 22.9544 9.07514C15.2665 14.4686 9.3027 21.9706 5.78204 30.6768C4.61059 33.5685 3.72372 36.5676 3.13399 39.6314C2.89199 40.878 2.69277 42.1157 2.56499 43.3693C2.86996 43.5186 3.1844 43.6455 3.49848 43.7723C4.35154 44.1166 5.20673 44.4225 6.06888 44.6793C13.0786 46.7712 20.6137 46.2033 27.2307 43.0842C33.8477 39.9653 39.0812 34.5145 41.9287 27.7762L53.0208 0.291327C52.3829 0.255876 51.7469 0.246963 51.1221 0.243035Z"
        fill="#00B783"
      />
      <path
        d="M6.32308 39.8716C28.7723 30.8131 27.9514 8.97141 49.6306 2.31912C45.3638 4.56048 41.5042 7.50338 38.2134 11.0247C27.7543 22.9997 24.0368 35.888 6.32308 39.8716Z"
        fill="white"
      />
      <path
        d="M67.5886 39.2503C67.3227 39.0902 67.0446 38.9291 66.7762 38.7805L66.4677 38.6035C61.1798 35.6845 55.1603 34.3572 49.1351 34.7818C43.1098 35.2064 37.336 37.3648 32.5097 40.9966C30.9056 42.2023 29.4229 43.5613 28.0824 45.0545C27.5353 45.6604 27.0156 46.2776 26.5298 46.9262C26.6486 47.1096 26.7798 47.2837 26.911 47.4578C27.2671 47.9303 27.6371 48.3822 28.0268 48.81C31.1971 52.2866 35.5386 54.4712 40.2196 54.9454C44.9006 55.4196 49.592 54.15 53.395 51.3799L68.6195 39.9068C68.2795 39.6752 67.9317 39.4597 67.5886 39.2503Z"
        fill="#00B783"
      />
      <path
        d="M28.0059 46.1967C43.3814 48.6437 50.1695 36.3337 64.3239 39.8529C61.229 39.6737 58.1261 40.0163 55.1449 40.8662C45.4092 44.0011 39.0899 49.8714 28.0055 46.1961L28.0059 46.1967Z"
        fill="white"
      />
    </svg>
  );
};

export default AdvantageIcon;
