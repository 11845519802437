import React from "react";

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M18.6087 4.55782L16.1087 2.05782C16.0506 1.99971 15.9817 1.95361 15.9058 1.92215C15.83 1.8907 15.7486 1.87451 15.6665 1.87451C15.5844 1.87451 15.503 1.8907 15.4272 1.92215C15.3513 1.95361 15.2824 1.99971 15.2243 2.05782L7.72432 9.55782C7.6663 9.6159 7.62029 9.68485 7.58892 9.76072C7.55755 9.8366 7.54144 9.9179 7.5415 10V12.5C7.5415 12.6658 7.60735 12.8247 7.72456 12.9419C7.84177 13.0592 8.00074 13.125 8.1665 13.125H10.6665C10.7486 13.1251 10.8299 13.109 10.9058 13.0776C10.9817 13.0462 11.0506 13.0002 11.1087 12.9422L18.6087 5.44219C18.6668 5.38415 18.7129 5.31522 18.7444 5.23934C18.7758 5.16347 18.792 5.08214 18.792 5C18.792 4.91787 18.7758 4.83654 18.7444 4.76067C18.7129 4.68479 18.6668 4.61586 18.6087 4.55782ZM10.4079 11.875H8.7915V10.2586L13.7915 5.2586L15.4079 6.875L10.4079 11.875ZM16.2915 5.99141L14.6751 4.375L15.6665 3.3836L17.2829 5L16.2915 5.99141ZM18.1665 9.375V16.25C18.1665 16.5815 18.0348 16.8995 17.8004 17.1339C17.566 17.3683 17.248 17.5 16.9165 17.5H4.4165C4.08498 17.5 3.76704 17.3683 3.53262 17.1339C3.2982 16.8995 3.1665 16.5815 3.1665 16.25V3.75C3.1665 3.41848 3.2982 3.10054 3.53262 2.86612C3.76704 2.6317 4.08498 2.5 4.4165 2.5H11.2915C11.4573 2.5 11.6162 2.56585 11.7334 2.68306C11.8507 2.80027 11.9165 2.95924 11.9165 3.125C11.9165 3.29076 11.8507 3.44973 11.7334 3.56695C11.6162 3.68416 11.4573 3.75 11.2915 3.75H4.4165V16.25H16.9165V9.375C16.9165 9.20924 16.9824 9.05027 17.0996 8.93306C17.2168 8.81585 17.3757 8.75 17.5415 8.75C17.7073 8.75 17.8662 8.81585 17.9834 8.93306C18.1007 9.05027 18.1665 9.20924 18.1665 9.375Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default EditIcon;
