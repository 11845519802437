import { ApiUrlConstants } from "../constants";
import http from "../http";

class AdminsList {
  getAdminsList() {
    return http.get(ApiUrlConstants.adminsList);
  }
  getAdminsById({ id }) {
    return http.get(ApiUrlConstants.adminsList, {
      params: { id },
    });
  }
  editAdminsById({ data }) {
    return http.post(ApiUrlConstants.editAdmin, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  addAdmin({ data }) {
    return http.post(ApiUrlConstants.addAdmin, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const AdminsListInstance = new AdminsList();
export default AdminsListInstance;
