import React from "react";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useCurrentWidth } from "react-socks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data }) => {
  const width = useCurrentWidth();

  const options = {
    responsive: true,
    aspectRatio: width < 768 ? 1 : 2.2,
    scales: {
      x: {
        ticks: {
          font: {
            size: 16,
            weight: 400,
          },
          color: "#9FA1A2",
        },
      },
      y: {
        // max: 7,
        display: true,
        title: {
          display: width <= 768 ? false : true,
          text: "(tCO₂e)",
          color: "#1C1C1C",
          font: {
            size: width <= 768 ? 12 : 16,
            weight: 500,
            family: "Inter",
          },
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value + "K";
          },
          major: true,
          color: "#9FA1A2",
          padding: 13,
          font: {
            size: width <= 768 ? 10 : 16,
            weight: 400,
            family: "Inter",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (yDatapoint) => {
            return yDatapoint.raw + "K";
          },
        },
      },
    },
  };

  return <Line options={options} data={data} height={width < 768 && 520} />;
};

export default LineChart;
