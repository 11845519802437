import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import {
  getLast3Months,
  getMonthName,
  getStartAndEndMonths,
} from "../../Constants/months";
import DownArrow from "../../Icons/DownArrow";
import WaterIcon from "../../Icons/WaterIcon";
import ArrowRise from "../../Icons/ArrowRise";
import ElectrsityIcon from "../../Icons/ElectrsityIcon";
import GasIcon from "../../Icons/GasIcon";
import ArrowDown from "../../Icons/ArrowDown";
import DGasIcon from "../../Icons/DGasIcon";
import WasteIcon from "../../Icons/WasteIcon";
import Widget from "../../Components/Widget/Widget";
import Card from "../../Components/Card/Card";
import { chartItems } from "../../Components/DoughnutChart/DoughnutChart";
import { VBarChart } from "../../Components/VBarChart/VBarChart";
import SimpleDoughnt, {
  doughntChartItems,
} from "../../Components/SimpleDoughnt/SimpleDoughnt";
import CloudIcon from "../../Icons/CloudIcon";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import MoreTipsIcon from "../../Icons/MoreTipsIcon";
import TipsCard from "../../Components/TipsCard/TipsCard";
import { useCurrentWidth } from "react-socks";
import TipOutlineCard from "../../Components/TipOutlineCard/TipOutlineCard";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmissionDatabyDate,
  getLastYearData,
  getTotalEmmissionsByDate,
  getTotalLast3Months,
} from "../../redux/Thunks/AdminDashboardThunk";
import {
  calculateTotals,
  calculateTotalsForAllCities,
  calculateTotalsForAllMonths,
} from "../../Constants/calculateTotalValues";

const widgets = [
  {
    id: 1,
    title: "Water",
    bg: "#E6F1FC",
    icon: <WaterIcon />,
    value: 24.14,
    sign: "+",
    percentage: "10",
    persentIcon: <ArrowRise />,
  },
  {
    id: 2,
    title: "Electricity",
    bg: "#F0ECD5",
    icon: <ElectrsityIcon />,
    value: 29.17,
    sign: "+",
    percentage: "07",
    persentIcon: <ArrowRise />,
  },
  {
    id: 3,
    title: "Fuel",
    bg: "#F7EEDD",
    icon: <GasIcon />,
    value: 0,
    sign: "-",
    percentage: "0",
    persentIcon: <ArrowDown />,
    disabled: true,
  },
  {
    id: 4,
    title: "Domestic Gas",
    bg: "#D1DEEB",
    icon: <DGasIcon />,
    value: 0,
    sign: "-",
    percentage: "0",
    persentIcon: <ArrowDown />,
    disabled: true,
  },
  {
    id: 5,
    title: "Waste",
    bg: "#F3EAE9",
    icon: <WasteIcon />,
    value: 0,
    sign: "-",
    percentage: "0",
    persentIcon: <ArrowDown />,
    disabled: true,
  },
];

const options = [
  ...getLast3Months().reverse(),
  {
    label: "3 Months",
    value: 2,
    isDisabled: false,
    type: "monthsGroup",
  },
  {
    label: "6 Months",
    value: 5,
    isDisabled: false,
    type: "monthsGroup",
  },
  {
    label: "12 Months",
    value: 11,
    isDisabled: false,
    type: "monthsGroup",
  },
  {
    label: "2 Years",
    value: 23,
    isDisabled: false,
    type: "monthsGroup",
  },
  {
    label: "3 Years",
    value: 35,
    isDisabled: false,
    type: "monthsGroup",
  },
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownArrow />
    </components.DropdownIndicator>
  );
};
const UserDashboard = () => {
  const [chartData, setChartData] = useState();
  const [period, setPeriod] = useState(options[0]);
  const [doughnutData, setDoughnutData] = useState();

  const width = useCurrentWidth();
  const dispatch = useDispatch();
  const { dataByDateRagne, lastYearData, last3MonthsData } = useSelector(
    (state) => state.adminData
  );

  useEffect(() => {
    const { startMonth, endMonth, startYear, endYear } =
      getStartAndEndMonths(period);
    dispatch(
      getEmissionDatabyDate({
        startMonth,
        endMonth,
        startYear,
        endYear,
      })
    );
  }, [dispatch, period]);

  useEffect(() => {
    dispatch(getLastYearData());
    dispatch(getTotalLast3Months());
  }, [dispatch]);

  useEffect(() => {
    const testedDataForOneYear = calculateTotalsForAllMonths(
      lastYearData?.emissions_data
    );
    const labels = testedDataForOneYear?.map((item) =>
      getMonthName(item?.month)
    );
    const waterData = testedDataForOneYear?.map(
      (item) => item?.totalWater / 1000
    );
    const electricityData = testedDataForOneYear?.map(
      (item) => item?.totalElectricity / 1000
    );

    const finalLineChartData = {
      labels,
      datasets: [
        {
          label: "water",
          data: waterData,
          borderColor: "#2B4D70",
          backgroundColor: "#2B4D70",
          pointRadius: 4,
          pointHoverRadius: 5,
          hitRadius: 2,
          cursor: "pointer",
          borderWidth: 1.5,
        },
        {
          label: "Electricity",
          data: electricityData,
          borderColor: "#00B783",
          backgroundColor: "#00B783",
          pointRadius: 4,
          pointHoverRadius: 5,
          hitRadius: 2,
          borderWidth: 1.5,
        },
        {
          label: "Fuel",
        },
        {
          label: "Domestic gas",
        },
        {
          label: "Waste",
        },
      ],
    };
    setChartData(finalLineChartData);
  }, [lastYearData?.emissions_data]);

  const toggleData = (dataIndex) => {
    const updatedData = {
      ...chartData,
      datasets: chartData?.datasets?.map((dataset, i) => {
        if (i === dataIndex) {
          return {
            ...dataset,
            hidden: !dataset?.hidden,
          };
        }
        return dataset;
      }),
    };

    setChartData(updatedData);
  };

  useEffect(() => {
    if (width < 768 && chartData && chartData?.labels) {
      setChartData((prev) => ({
        ...prev,
        labels: chartData?.labels.slice(-3),
      }));
    }
  }, [width]);

  useEffect(() => {
    const formattedData = calculateTotals(dataByDateRagne?.emissions_data);
    const totals = calculateTotalsForAllCities(formattedData);
    setDoughnutData(totals);
  }, [dataByDateRagne?.emissions_data]);

  const last3MonthsCombinedData = calculateTotalsForAllMonths(
    last3MonthsData?.emissions_data
  );

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>UAE EI | User Portal</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </div>
      <div
        style={{
          paddingBottom: width <= 1300 || width > 1440 ? 20 : 0,
          overflowX: width <= 1300 ? "hidden" : "auto",
          height: "100%",
          width: "100%",
          maxWidth: "1440px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <div className="w-full  xs:h-[60px] sm:h-[60px] md:h-[100px] flex items-center xs:px-[24px] sm:px-[24px] md:px-[48px] justify-between">
          <div className="flex">
            <span className="font-inter xs:text-[12px] sm:text-[12px] md:text-[20px] text-[#1C1C1C] font-bold">
              CO₂ Emissions Percentage
            </span>
          </div>
          <div className="xs:w-[100px] sm:w-[100px] md:w-1/2 flex justify-end">
            <Select
              options={options}
              components={{ DropdownIndicator }}
              defaultValue={options[0]}
              onChange={(option) => setPeriod(option)}
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  width: width < 768 ? "120px" : "195px",
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // width: "195px",
                  width: width < 768 ? "120px" : "195px",

                  borderRadius: "8px",
                  fontSize: "16px",
                  color: "#1C1C1C",
                  fontWeight: "500",
                  border: state.isFocused ? "1px solid #C1C1C1" : "",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid black",
                  },
                }),
                indicatorSeparator: () => {},
                DropdownIndicator: () => {},
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#1C1C1C",
                  backgroundColor: state.isFocused ? "#F6F6F6" : "",
                }),
                SingleValueLabel: (base) => ({
                  ...base,
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#1C1C1C",
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  // fontSize: "16px",
                  fontSize: width < 768 ? "12px" : "16px",
                  fontWeight: "500",
                  color: "#1C1C1C",
                }),
                Input: (baseStyles) => ({
                  ...baseStyles,
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "transparent",
                }),
              }}
              classNamePrefix="select"
            />
          </div>
        </div>
        {width >= 1024 && (
          <div className="w-full flex justify-between items-center px-[48px]">
            {widgets.map((item) => (
              <Widget
                key={item.id}
                bg={item.disabled ? "#D3D3D3" : item.bg}
                icon={item.icon}
                percentage={item.percentage}
                value={item.value}
                sign={item.sign}
                title={item.title}
                percentageIcon={item.persentIcon}
              />
            ))}
          </div>
        )}
        {width < 1024 && (
          <div className="w-full flex flex-col justify-between items-center px-[48px]">
            {widgets.map((item) => (
              <Widget
                key={item.id}
                bg={item.disabled ? "#D3D3D3" : item.bg}
                icon={item.icon}
                percentage={item.percentage}
                value={item.value}
                sign={item.sign}
                title={item.title}
                percentageIcon={item.persentIcon}
              />
            ))}
          </div>
        )}
        <div className="flex w-full flex-col gap-x-[25px] gap-y-[20px] xs:px-[24px] sm:px-[24px] md:px-[48px] mt-[24px]">
          <div className="flex xs:flex-col lg:flex-row sm:flex-col md:flex-col gap-6 flex-row w-full">
            <div className="xs:w-[100%] lg:w-[64%] sm:w-[100%] md:w-[100%]">
              <Card height="360px" title="CO₂ Emissions Mass">
                <div className="flex xs:flex-col sm:flex-col md:flex-row items-center justify-between w-full xs:h-[320px] sm:h-[320px] md:h-[265px]">
                  <div className="w-[50%] flex justify-center items-center">
                    <SimpleDoughnt dataByDateRagne={doughnutData} />
                  </div>
                  {width > 768 && (
                    <div className="w-[1px] h-full bg-[#E7ECF0]" />
                  )}
                  <div className=" sm:w-full xs:w-full md:w-[50%] flex justify-center items-center flex-col">
                    {doughntChartItems.map((item) => (
                      <div
                        key={item.id}
                        className="w-full xs:px-0 sm:px-0 md:px-5 flex justify-between items-center xs:my-[3px] sm:my-[3px] md:my-[9px]"
                      >
                        <div className="flex items-center">
                          <div
                            className={`xs:w-[10px] sm:w-[10px] md:w-[15px] xs:h-[10px] sm:h-[10px] md:h-[15px] mr-2 rounded-full `}
                            style={{
                              backgroundColor: item.disabled
                                ? "#9FA1A2"
                                : item.color,
                            }}
                          />
                          <span
                            className="font-roboto font-normal xs:text-[12px] sm:text-[12px] md:text-[20px]"
                            style={{
                              color: item.disabled ? "#9FA1A2" : "#404040",
                            }}
                          >
                            {item.title}
                          </span>
                        </div>
                        <span
                          className="font-roboto xs:text-[12px] sm:text-[12px] md:text-[20px] font-medium"
                          style={{
                            color: item.disabled ? "#9FA1A2" : "#0A0A0A",
                          }}
                        >
                          {item.title === "Electricity"
                            ? doughnutData?.electricity
                            : item.title === "Water"
                            ? doughnutData?.water
                            : "----"}{" "}
                          Kg
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </Card>
            </div>

            <div className="xs:w-[100%] lg:w-[35%] sm:w-[100%] md:w-[100%]">
              <Card height="360px" title="CO₂ Emissions History">
                <div className="xs:h-[320px] sm:h-[320px] md:h-[265px] gap-[10px] flex xs:flex-col sm:flex-col md:flex-row w-full justify-between items-center">
                  <div className="w-[35%] h-full flex flex-col justify-start items-center">
                    <CloudIcon />
                    <div className="xs:text-[14px] sm:text-[14px] md:text-[20px] font-normal text-[#7C7C7C]">
                      Average
                    </div>
                    <div className="xs:text-[16px] sm:text-[16px] md:text-[24px] font-medium text-[#1C1C1C]">
                      23,760 Kg
                    </div>
                  </div>
                  <div className="xs:w-full sm:w-full md:w-[65%] h-[85%] flex flex-col justify-around items-center">
                    {last3MonthsCombinedData?.reverse()?.map((item) => (
                      <ProgressBar
                        key={item.month}
                        month={getMonthName(item.month)}
                        total={150000}
                        progressValue={item.totalElectricity + item.totalWater}
                      />
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="flex xs:flex-col lg:flex-row sm:flex-col md:flex-col gap-6 flex-row w-full">
            <div className="xs:w-[100%] lg:w-[64%] sm:w-[100%] md:w-[100%]">
              <Card
                height={width < 768 ? "500px" : "460px"}
                title="Emissions Overview"
                options={
                  <div className="flex xs:mt-2 sm:mt-2 md:mt-0  xs:justify-start sm:justify-start md:justify-around lg:w-[55%] sm:w-[100%] xs:w-[100%] md:w-[60%] items-center">
                    {chartItems.map((option, i) => (
                      <div
                        key={option.id}
                        className="flex items-center mx-1 cursor-pointer"
                        onClick={() => {
                          if (option.disabled) {
                            return;
                          }
                          toggleData(i);
                        }}
                      >
                        <div
                          className={`mr-1`}
                          // style={{ backgroundColor: option.color }}
                        >
                          {option.icon}
                        </div>
                        <span
                          className="font-inter xs:text-[8px] lg:text-[16px] sm:text-[12px] md:text-[14px] font-normal"
                          style={{
                            textDecoration: chartData?.datasets[i]?.hidden
                              ? "line-through"
                              : "none",
                            color: option.disabled ? "#9FA1A2" : "#1C1C1C",
                          }}
                        >
                          {option.title}
                        </span>
                      </div>
                    ))}
                  </div>
                }
              >
                {chartData?.labels && chartData?.datasets && (
                  <VBarChart data={chartData} />
                )}
              </Card>
            </div>
            <div className="xs:w-[100%] lg:w-[35%] sm:w-[100%] md:w-[100%]">
              <TipOutlineCard
                height="460px"
                title="Tips"
                optionRes
                options={
                  <button
                    type="button"
                    className="bg-transparent flex items-center justify-between xs:w-[100px] sm:w-[100px] md:w-[140px] py-[8px] px-[16px] sm:h-[25px] xs:h-[25px] md:h-[40px] text-[#1C1C1C] xs:text-[12px] sm-text-[12px] lg:text-[20px] font-medium border-[1px] border-[#E7ECF0] rounded-[8px]"
                  >
                    View All
                    <span className="ml-24px">
                      <MoreTipsIcon width={12} height={12} />
                    </span>
                  </button>
                }
              >
                <TipsCard
                  image="./images/tip1.png"
                  tip="Fix the leaks"
                  title="Showers and Faucets"
                  body="A dripping tab can waste more than 5,500 liters of water a year."
                />
                <TipsCard
                  image="./images/tip2.png"
                  tip="Use a smart irrigation system"
                  title="Irrigation"
                  body="Watering at certain times and keeping a well-maintained irrigation."
                />
                <TipsCard
                  image="./images/tip3.png"
                  tip="Turn it up!"
                  title="Cooling"
                  body="Turning up your air conditioner is one of the easiest ways."
                />
              </TipOutlineCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
