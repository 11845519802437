import React, { useEffect, useState } from "react";
import TestMapsWidthDots from "./TestMapsWidthDots";
import PlusIcon from "../../Icons/PlusIcon";
import Minus from "../../Icons/Minus";
import { calculateTotals } from "../../Constants/calculateTotalValues";
import { useCurrentWidth } from "react-socks";

const ZoomableSVG = ({ selectValue, dataByDateRagne }) => {
  const [zoom, setZoom] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const width = useCurrentWidth();

  const handleZoomIn = () => setZoom(zoom * 1.2);
  const handleZoomOut = () => {
    // Prevent zooming out beyond the original width
    const newZoom = zoom / 1.2;
    if (newZoom * width >= width) {
      setZoom(newZoom);
    }
  };
  const handleMouseDown = (event) => {
    setIsDragging(true);
    event.preventDefault(); // Prevent text selection during drag
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      setPan({
        x: pan.x + event.movementX,
        y: pan.y + event.movementY,
      });
    }
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const [mapNumericData, setMapNumericData] = useState();
  useEffect(() => {
    const formattedData = calculateTotals(dataByDateRagne?.emissions_data);
    const dataArray = Object.entries(formattedData).map(([city, values]) => ({
      city,
      ...values,
    }));

    setMapNumericData(dataArray);
  }, [dataByDateRagne?.emissions_data]);

  const height = 300;

  // lg = 340
  // w<1100=

  return (
    <div className="flex w-full items-center h-[80%]">
      <div
        style={{
          width: "100%",
          height: "330px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "move",
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <svg width="100%" height="100%" viewBox={`0 0 ${340} ${height}`}>
          <g transform={`translate(${pan.x},${pan.y}) scale(${zoom})`}>
            <TestMapsWidthDots
              selectValue={selectValue}
              data={mapNumericData}
            />
          </g>
        </svg>
      </div>
      <div className="h-full flex items-end">
        <div className="sm:h-[70px] xs:h-[70px] md:h-[105px] sm:w-[24px] xs:w-[24px] md:w-[32px] rounded-[8px] flex flex-col justify-around items-center shadow-md bg-gray-100 shadow-gray-300">
          <button onClick={handleZoomIn} className="text-lg">
            <PlusIcon
              width={width < 768 ? 16 : 24}
              height={width < 768 ? 16 : 24}
            />
          </button>
          <div className="h-[2px] w-[16px] bg-[#E7ECF0]" />
          <button onClick={handleZoomOut} className="text-lg">
            <Minus
              width={width < 768 ? 16 : 24}
              height={width < 768 ? 16 : 24}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ZoomableSVG;
