import React from "react";
import Sponser1 from "../icons/Sponser1";
import Sponser2 from "../icons/Sponser2";
import Sponser3 from "../icons/Sponser3";
import { useLanguage } from "../../../Contexts/LanguageContext";
import { useCurrentWidth } from "react-socks";

const Sponsers = () => {
  const { language } = useLanguage();
  const width = useCurrentWidth();

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "white",
        fontFamily: "Inter",
        width: width <= 1024 ? "100%" : "50%",
        height: "100%",
        padding: width <= 1024 ? "10px 16px" : "40px 16px",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {language === "en" ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: width > 1024 ? 45 : 30,
              fontWeight: 500,
              color: "#2B4D70",
              marginBottom: width <= 1024 ? 0 : 65,
              fontFamily: language === "en" ? "Inter" : "cairo",
            }}
          >
            SPONSORED BY
          </span>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              gap: 50,
              marginTop: 10,
              paddingLeft: 10,
            }}
          >
            <Sponser1 width={80} height={62} />
            <Sponser2 width={95} height={58} />
            <Sponser3 width={100} height={45} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: width > 1025 ? 45 : 30,
                fontWeight: 500,
                color: "#2B4D70",
                fontFamily: language === "en" ? "Inter" : "cairo",
              }}
            >
              برعاية{" "}
            </span>
          </div>
          <div
            style={{
              width: "100%",
              flexDirection: "row-reverse",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              gap: 50,
              marginTop: 10,
              paddingLeft: 10,
            }}
          >
            <Sponser1 width={80} height={62} />
            <Sponser2 width={95} height={58} />
            <Sponser3 width={100} height={45} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Sponsers;
