import { createSlice } from "@reduxjs/toolkit";
import {
  getEmissionDatabyDate,
  getLastYearData,
  getTotalEmmissionsByDate,
  getTotalLast3Months,
} from "../Thunks/AdminDashboardThunk";
import { getLast3Months } from "../../Constants/months";

const options = [
  ...getLast3Months().reverse(),
  {
    label: "3 Months",
    value: 2,
    isDisabled: false,
    type: "monthsGroup",
  },
  {
    label: "6 Months",
    value: 5,
    isDisabled: false,
    type: "monthsGroup",
  },
  {
    label: "12 Months",
    value: 11,
    isDisabled: false,
    type: "monthsGroup",
  },
  {
    label: "2 Years",
    value: 23,
    isDisabled: false,
    type: "monthsGroup",
  },
  {
    label: "3 Years",
    value: 35,
    isDisabled: false,
    type: "monthsGroup",
  },
];

export const AdminDashboardSlice = createSlice({
  name: "admin",
  initialState: {
    loading: false,
    error: null,
    dataByDateRagne: null,
    totalDataByMonth: null,
    lastYearData: null,
    last3MonthsData: null,
    periodsOptions: options,
    selectedPeriod: options[0],
  },
  reducers: {
    setPeriod: (state, action) => {
      state.selectedPeriod = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmissionDatabyDate.pending, (state) => {
      state.loading = true;
      state.dataByDateRagne = null;
      state.error = null;
    });
    builder.addCase(getEmissionDatabyDate.fulfilled, (state, action) => {
      state.loading = false;
      state.dataByDateRagne = action.payload;
      state.error = null;
    });
    builder.addCase(getEmissionDatabyDate.rejected, (state, action) => {
      state.loading = false;
      state.dataByDateRagne = null;
      state.error = action.payload;
    });
    builder.addCase(getTotalEmmissionsByDate.pending, (state) => {
      state.loading = true;
      state.totalDataByMonth = null;
      state.error = null;
    });
    builder.addCase(getTotalEmmissionsByDate.fulfilled, (state, action) => {
      state.loading = false;
      state.totalDataByMonth = action.payload;
      state.error = null;
    });
    builder.addCase(getTotalEmmissionsByDate.rejected, (state, action) => {
      state.loading = false;
      state.totalDataByMonth = null;
      state.error = action.payload;
    });
    builder.addCase(getLastYearData.pending, (state, action) => {
      state.loading = true;
      state.lastYearData = null;
      state.error = null;
    });
    builder.addCase(getLastYearData.fulfilled, (state, action) => {
      state.loading = false;
      state.lastYearData = action.payload;
      state.error = null;
    });
    builder.addCase(getLastYearData.rejected, (state, action) => {
      state.loading = false;
      state.lastYearData = null;
      state.error = action.payload;
    });
    builder.addCase(getTotalLast3Months.pending, (state, action) => {
      state.loading = true;
      state.lastYearData = null;
      state.error = null;
    });
    builder.addCase(getTotalLast3Months.fulfilled, (state, action) => {
      state.loading = false;
      state.last3MonthsData = action.payload;
      state.error = null;
    });
    builder.addCase(getTotalLast3Months.rejected, (state, action) => {
      state.loading = false;
      state.lastYearData = null;
      state.error = action.payload;
    });
  },
});

export const { setPeriod } = AdminDashboardSlice.actions;
