import React, { useEffect, useState } from "react";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import WaterIcon from "../../Icons/WaterIcon";
import ElectrsityIcon from "../../Icons/ElectrsityIcon";
import GasIcon from "../../Icons/GasIcon";
import DGasIcon from "../../Icons/DGasIcon";
import WasteIcon from "../../Icons/WasteIcon";
import {
  calculateTotals,
  calculateTotalsForAllCities,
} from "../../Constants/calculateTotalValues";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Water", "Electricity", "Fuel", "Domestic gas", "Waste"],
  datasets: [
    {
      data: [24.14, 29.17, 1, 1, 1],
      backgroundColor: ["#2B4D70", "#00B783", "#D19631", "#3C8DB9", "#A68A64"],
      borderColor: ["white", "white", "white", "white", "white", "white"],
      borderRadius: 5,
      borderWidth: 4,
      hoverBorderWidth: 0,
      borderAlign: "center",
      cutout: "78%",
    },
  ],
};

export const chartItems = [
  {
    id: 1,
    title: "Water",
    color: "#2B4D70",
    value: "24.14",
    disabled: false,
    icon: <WaterIcon />,
  },
  {
    id: 2,
    title: "Electricity",
    color: "#00B783",
    value: "29.17",
    disabled: false,
    icon: <ElectrsityIcon />,
  },
  {
    id: 3,
    title: "Fuel",
    color: "#9FA1A2",
    value: "18.84",
    disabled: true,
    icon: <GasIcon />,
  },
  {
    id: 4,
    title: "Domestic gas",
    color: "#9FA1A2",
    value: "16.21",
    disabled: true,
    icon: <DGasIcon />,
  },
  {
    id: 5,
    title: "Waste",
    color: "#9FA1A2",
    value: "11.65",
    disabled: true,
    icon: <WasteIcon />,
  },
];

export const DoughntChartItems = [
  {
    id: 1,
    title: "Water",
    color: "#2B4D70",
    value: "24.14%",
    disabled: false,
    icon: <WaterIcon />,
  },
  {
    id: 2,
    title: "Electricity",
    color: "#00B783",
    value: "29.17%",
    disabled: false,
    icon: <ElectrsityIcon />,
  },
  {
    id: 3,
    title: "Fuel",
    color: "#D19631",
    value: "---",
    disabled: true,
    icon: <GasIcon />,
  },
  {
    id: 4,
    title: "Domestic gas",
    color: "#3C8DB9",
    value: "---",
    disabled: true,
    icon: <DGasIcon />,
  },
  {
    id: 5,
    title: "Waste",
    color: "#A68A64",
    value: "---",
    disabled: true,
    icon: <WasteIcon />,
  },
];

const DoughnutChart = ({ dataByDateRagne }) => {
  const [doughnutData, setDoughnutData] = useState();

  useEffect(() => {
    const formattedData = calculateTotals(dataByDateRagne?.emissions_data);
    const totals = calculateTotalsForAllCities(formattedData);
    setDoughnutData(totals);
  }, [dataByDateRagne?.emissions_data]);

  const textCenter = {
    id: "textCenter",

    beforeDraw(chart, args, pluginOptions) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;
      ctx.save();
      ctx.font = "500 24px Inter";
      ctx.fillStyle = "#1C1C1C";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        `${pluginOptions?.doughnutData?.combined || 0}`,
        width / 2,
        height / 2.1
      );

      ctx.font = `600 16px Inter`;
      ctx.fillStyle = "#1C1C1C";
      ctx.fillText("(tCO₂e)", width / 2, height / 1.6);
    },
  };

  const options = {
    aspectRatio: 1,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      textCenter: {
        doughnutData,
      },
      tooltip: {
        enabled: false,
        callbacks: {
          title: (xDatapoint) => {
            return xDatapoint.label;
          },
          label: (yDatapoint) => {
            if (yDatapoint.raw === 1) {
              return "0 %";
            } else {
              return yDatapoint.raw + "%";
            }
          },
        },
      },
    },
  };
  const dataModified = {
    labels: ["Water", "Electricity", "Fuel", "Domestic gas", "Waste"],
    datasets: [
      {
        data: [
          ((doughnutData?.water / doughnutData?.combined) * 100).toFixed(2),
          ((doughnutData?.electricity / doughnutData?.combined) * 100).toFixed(
            2
          ),
          1,
          1,
          1,
        ],
        backgroundColor: [
          "#2B4D70",
          "#00B783",
          "#D19631",
          "#3C8DB9",
          "#A68A64",
        ],
        borderColor: ["white", "white", "white", "white", "white", "white"],
        borderRadius: 5,
        borderWidth: 4,
        hoverBorderWidth: 0,
        borderAlign: "center",
        cutout: "78%",
      },
    ],
  };

  return (
    <div className="w-full h-full flex flex-col justify-end items-center">
      <div className="w-full h-full flex justify-center items-center">
        <div className="xs:w-[150px] sm:w-[150px] md:w-[185px] xs:h-[150px] sm:h-[150px] md:h-[185px] flex justify-center items-center">
          <Doughnut
            data={dataModified}
            plugins={[textCenter]}
            options={options}
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        {DoughntChartItems.map((item) => (
          <div
            key={item.id}
            className="w-full px-5 flex justify-between items-center my-1"
          >
            <div className="flex items-center">
              <div
                className={`w-[10px] h-[10px] mr-2 rounded-full `}
                style={{ backgroundColor: item.color }}
              />
              <span
                className="font-inter font-medium"
                style={{
                  color: item.disabled ? "#9FA1A2" : "#1C1C1C",
                }}
              >
                {item.title}
              </span>
            </div>
            <div
              className="font-inter font-medium flex justify-center items-center w-[70px]"
              style={{
                color: item.disabled ? "#9FA1A2" : "#1C1C1C",
              }}
            >
              {item.title === "Water"
                ? `${(
                    (doughnutData?.water / doughnutData?.combined) *
                    100
                  ).toFixed(2)} %`
                : item.title === "Electricity"
                ? `${(
                    (doughnutData?.electricity / doughnutData?.combined) *
                    100
                  ).toFixed(2)} %`
                : item.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DoughnutChart;
