import React, { useEffect, useState } from "react";
import { useCurrentWidth } from "react-socks";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import uaeSpinner from "../../Icons/animation/spinner.json";
import { getAdminsListApi } from "../../redux/Thunks/AdminsThunks";
import EditIcon from "../../Icons/EditIcon";
import EditAdminModal from "../../Components/EditAdminModal/EditAdminModal";
import AddNewAdminModal from "../../Components/AddNewAdminModal/AddNewAdminModal";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import Groups from "../../Icons/Groups";
import BackArrow from "../../Icons/BackArrow";
import SearchIcon from "../../Icons/SearchIcon";
import FiltersIcon from "../../Icons/FiltersIcon";
import PlusIcon from "../../Icons/PlusIcon";
import SortIconDown from "../../Icons/SortIconDown";
import SortIconUp from "../../Icons/SortIconUp";
import SortIcons from "../../Icons/SortIcons";
import CheroRight from "../../Icons/CheroRight";
import CheroLeft from "../../Icons/CheroLeft";

const AdminsManager = () => {
  const dispatch = useDispatch();
  const width = useCurrentWidth();
  let navigate = useNavigate();
  const { admin } = useSelector((state) => state.adminLogin);
  const { adminsList, loading } = useSelector((state) => state.adminsList);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [targetAdmin, setTargetAdmin] = useState(null);
  const [addNewAdminOpen, setAddNewAdminOpen] = useState();
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (admin) {
      navigate("/admins-list");
    } else {
      navigate("/admin/login");
    }
  }, [navigate, admin]);

  useEffect(() => {
    dispatch(getAdminsListApi());
  }, [dispatch]);
  const theme = useTheme([
    getTheme(),
    {
      Table: `
      width: 100%;
      `,
      HeaderRow: `
      background-color: #F4F4F4;
  
    `,
      HeaderCell: `   
      font-size: 16px;
      font-family:Inter;
      font-weight:400;
      & > div > div {
       justify-content: space-between;
      }
      &:first-child {
        border-radius: 8px 0 0 8px;
        border-color: #E8E8E8;
        border-width:0 1px 0 0;  
      }
      &:nth-child(2) {
        border-radius: 8px 0 0 8px;
        border-color: #E8E8E8;
        border-width:0 1px 0 0;
        }
      &:nth-child(3) {
        border-color: #E8E8E8;
        border-width:0 1px 0 0;
        }
      &:nth-child(4) {
        border-color: #E8E8E8;
        border-width:0 1px 0 0;
        } 
      &:nth-child(5) {
        border-color: #E8E8E8;
        border-width:0 1px 0 0;
        }
      &:nth-child(6) {
        border-width:0;
        border-radius:0 8px 8px 0;
        }       
      `,
      Cell: `
      color:#1C1C1C;
      font-size: 16px;
      font-family:Inter;`,
    },
  ]);

  const data = {
    nodes: adminsList.filter((item) =>
      item.email.toLowerCase().includes(search.toLowerCase())
    ),
  };

  function onSortChange(action, state) {
    console.log(action, state);
  }

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        margin: "0px",
        // position: "",
        iconDefault: <SortIcons />,
        iconUp: <SortIconUp />,
        iconDown: <SortIconDown />,
      },
      sortFns: {
        ID: (array) => array.sort((a, b) => a - b),
        EMAIL: (array) => array.sort((a, b) => a.email - b.email),
        TYPE: (array) =>
          array.sort((a, b) => a.admin_type.localeCompare(b.admin_type)),
      },
    }
  );

  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 5,
    },
    onChange: onPaginationChange,
  });
  function onPaginationChange(action, state) {
    console.log(action, state);
  }
  const totalPages = pagination?.state?.getTotalPages(data.nodes);

  if (loading)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Lottie animationData={uaeSpinner} loop />
      </div>
    );

  return (
    <div
      style={{
        paddingBottom: width <= 1300 || width > 1440 ? 20 : 0,
        overflowX: width <= 1300 ? "hidden" : "auto",
        height: "100%",
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "auto",
      }}
    >
      <div className="w-full h-[100px] flex items-center xs:px-[24px] sm:px-[24px] md:px-[24px] lg:px-[48px] justify-between">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/admin")}
        >
          <BackArrow />
          <span className="mx-2 font-inter sm:text-[12px] xs:text-[12px] md:text-[16px] text-[#1C1C1C] font-medium">
            Home
          </span>
        </div>
        <div className="flex items-center">
          <Groups />
          <span className="mx-2 font-inter sm:text-[12px] xs:text-[12px] md:text-[16px] text-[#1C1C1C] font-medium">
            700,000 Accounts
          </span>
        </div>
      </div>
      <div className="xs:px-[24px] sm:px-[24px] md:px-[24px] lg:px-[48px] w-full">
        <div className="w-full flex flex-col items-center justify-center bg-white py-[16px]">
          <div className="w-full flex items-start justify-between mb-[36px] px-[16px]">
            <div className=" flex items-start justify-start">
              <div className="w-[256px] relative">
                <SearchIcon classNames="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                <input
                  id="search"
                  type="text"
                  className="pl-10 pr-4 py-2 w-full h-[40px] border rounded-md focus:border-[#7A7A7A] focus:outline-none"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
              <button className="w-[40px] flex justify-center items-center ml-2 h-[40px] border rounded-md focus:border-[#7A7A7A]">
                <FiltersIcon />
              </button>
            </div>
            <div className="flex justify-end items-center">
              <button
                className="w-[175px] h-[50px] bg-[#2B4D70] flex items-center justify-around rounded-lg"
                onClick={() => setAddNewAdminOpen(true)}
              >
                <PlusIcon color="white" />
                <span className="text-white font-[500] font-inter">
                  Add New Admin
                </span>
              </button>
            </div>
          </div>
          <div className="w-full px-[16px]">
            <Table
              data={data}
              theme={theme}
              sort={sort}
              pagination={pagination}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCellSort sortKey="ID">ID</HeaderCellSort>
                      <HeaderCellSort sortKey="EMAIL">Email</HeaderCellSort>
                      <HeaderCell>Phone</HeaderCell>
                      <HeaderCell>UAE ID</HeaderCell>
                      <HeaderCellSort sortKey="TYPE">Type</HeaderCellSort>
                      <HeaderCell> Edit</HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item) => (
                      <Row key={item.id} item={item}>
                        <Cell>{item.id}</Cell>
                        <Cell>{item.email}</Cell>
                        <Cell>{item.phone}</Cell>
                        <Cell>{item.uae_id}</Cell>
                        <Cell>
                          <span className="bg-[#F3F3F3] p-1 rounded-md text-[#1C1C1C]">
                            {item.admin_type === "super"
                              ? "Super Admin"
                              : item.admin_type === "admin"
                              ? "Admin"
                              : item.admin_type}
                          </span>
                        </Cell>
                        <Cell>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setEditModalOpen(true);
                              setTargetAdmin(item.id);
                            }}
                          >
                            <EditIcon />
                          </div>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>
            <div className="w-full flex items-center justify-end">
              <div className="flex items-center">
                <span></span>
                {pagination.state.page !== 0 && (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      pagination.fns.onSetPage(pagination.state.page - 1)
                    }
                  >
                    <CheroLeft />
                  </div>
                )}
                <span>
                  {pagination.state.getPages(data.nodes).map((_, index) => (
                    <button
                      key={index}
                      type="button"
                      style={{
                        backgroundColor:
                          pagination.state.page === index
                            ? "#00B78329"
                            : "white",
                        width: 28,
                        fontFamily: "Inter",
                        borderRadius: 5,
                      }}
                      onClick={() => pagination.fns.onSetPage(index)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </span>
                {pagination.state.page + 1 !== totalPages && (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      pagination.fns.onSetPage(pagination.state.page + 1)
                    }
                  >
                    <CheroRight />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditAdminModal
        isModalOpen={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
        targetAdmin={targetAdmin}
      />
      <AddNewAdminModal
        isModalOpen={addNewAdminOpen}
        closeModal={() => setAddNewAdminOpen(false)}
      />
    </div>
  );
};

export default AdminsManager;
