import { ApiUrlConstants } from "../constants";
import http from "../http";

class AdminData {
  getEmissionsByDateRange({ startMonth, startYear, endMonth, endYear }) {
    return http.get(ApiUrlConstants.emissionsByDateRange, {
      params: {
        startMonth,
        startYear,
        endMonth,
        endYear,
      },
    });
  }

  getTotalEmissionsByDateRange({ startMonth, startYear, endMonth, endYear }) {
    return http.get(ApiUrlConstants.totalEmissionsByDateRange, {
      params: {
        startMonth,
        endMonth,
        startYear,
        endYear,
      },
    });
  }
}

const AdminDataInstance = new AdminData();
export default AdminDataInstance;
