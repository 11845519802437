import React from "react";
import { useLanguage } from "../../../Contexts/LanguageContext";
import Modal from "./Modal";
import { Controller, useForm } from "react-hook-form";
import ModalTitleIcon from "../icons/ModalTitleIcon";

const LandingFrom = ({
  isModalOpen,
  setModalOpen,
  setSuccessModal,
  setUserFullName,
}) => {
  const { language, style } = useLanguage();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();

  const closeModal = () => {
    setModalOpen(false);
    reset();
  };

  const onSubmit = async (data) => {
    try {
      // Add your logic for form submission here
      const formData = new FormData();
      formData.append("name", data.fullName);
      formData.append("email", data.email);
      formData.append("phone", data.mobile);
      formData.append("uaeid", data.emiratesId);

      const res = await fetch(
        "https://uaeei-api.ucglab.com/api/users/landing.xefrd",
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await res.json();

      if (responseData.success) {
        setUserFullName(responseData.fullname);
        setSuccessModal(true);
        closeModal();
      } else {
        setSuccessModal(false);
      }
    } catch (error) {
      console.error("An error occurred during form submission", error);
    }
  };

  const handlePhoneNumberChange = (value) => {
    const cleanedInput = value.replace(/[^\u0660-\u0669\u06F0-\u06F9\d]/g, "");

    // Update the form value with the cleaned input
    setValue("mobile", cleanedInput);
  };

  const handleEmirateID = (value) => {
    const cleanedInput = value.replace(/[^\u0660-\u0669\u06F0-\u06F9\d]/g, "");

    // Update the form value with the cleaned input
    setValue("emiratesId", cleanedInput);
  };

  return (
    <Modal
      closeIcon
      isOpen={isModalOpen}
      onClose={closeModal}
      closeOnOverlayClick={false}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...style,
          }}
        >
          <ModalTitleIcon />
          <span
            style={{
              fontSize: 22,
              fontWeight: 600,
              // marginBottom: 10,
              marginLeft: 5,
            }}
          >
            {language === "en" ? "Early Sign up" : "التسجيل المبكّر"}
          </span>
        </div>
      }
    >
      <div
        style={{
          padding: 24,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "100%",
          gap: 24,
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label
              htmlFor="fullName"
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#323238",
                fontFamily: "Inter",
                ...style,
              }}
            >
              {language === "en" ? "Full Name" : "الاسم الكامل"}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="fullName"
              placeholder={language === "en" ? "Full Name" : "الاسم الكامل"}
              style={{
                height: 48,
                padding: "8px 16px",
                borderRadius: 4,
                border: `1px solid ${errors?.fullName ? "red" : "#E1E1E6"}`, // Highlight border if there's an error
                width: "100%",
                ...style,
              }}
              {...register("fullName", {
                required: {
                  value: true,
                  message:
                    language === "en"
                      ? "Full Name is required"
                      : "الاسم الكامل مطلوب",
                },
                pattern: {
                  value:
                    /^(?!\\s)[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+(?:[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z\s]+)?$/,
                  message:
                    language === "en"
                      ? "Only letters, spaces are allowed, must start with a letter"
                      : "يسمح فقط بالحروف والمسافات ،يجب ان يبدآ بالحروف",
                },
              })}
            />
            {errors?.fullName && (
              <p style={{ color: "red", ...style }}>
                {errors?.fullName?.message}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label
              htmlFor="email"
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#323238",
                fontFamily: "Inter",
                ...style,
              }}
            >
              {language === "en" ? "Email" : " البريد الإلكتروني"}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="email"
              placeholder={language === "en" ? "Email" : " البريد الإلكتروني"}
              style={{
                height: 48,
                padding: "8px 16px",
                borderRadius: 4,
                border: `1px solid ${errors?.email ? "red" : "#E1E1E6"}`, // Highlight border if there's an error
                width: "100%",
                ...style,
              }}
              {...register("email", {
                required: {
                  value: true,
                  message:
                    language === "en"
                      ? "Email is required"
                      : " البريد الإلكتروني مطلوب",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message:
                    language === "en"
                      ? "Invalid email address"
                      : "عنوان البريد الإلكتروني غير صحيح",
                },
              })}
            />
            {errors?.email && (
              <p style={{ color: "red", ...style }}>{errors?.email?.message}</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label
              htmlFor="mobile"
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#323238",
                fontFamily: "Inter",
                ...style,
              }}
            >
              {language === "en" ? " Mobile Number" : "رقم الهاتف المحمول"}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Controller
              name="mobile"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  placeholder={
                    language === "en" ? "00971XXXXXXXXX" : "٠٠٩٧١XXXXXXXXX"
                  }
                  pattern="\d*"
                  style={{
                    height: 48,
                    padding: "8px 16px",
                    borderRadius: 4,
                    border: `1px solid ${errors?.mobile ? "red" : "#E1E1E6"}`,
                    width: "100%",
                    ...style,
                  }}
                  onChange={(e) => handlePhoneNumberChange(e.target.value)}
                />
              )}
              {...register("mobile", {
                required: {
                  value: true,
                  message:
                    language === "en"
                      ? "Mobile number is required"
                      : "رقم الهاتف المحمول مطلوب",
                },
                pattern: {
                  value: /^[\u0660-\u0669\u06F0-\u06F90-9]+$/,
                  message:
                    language === "en"
                      ? "Please enter a valid mobile number"
                      : "الرجاء إدخال رقم هاتف محمول صحيح",
                },
                minLength: {
                  value: 14,
                  message:
                    language === "en"
                      ? "Mobile number should be 14 digits"
                      : "يجب أن يكون رقم الهاتف المحمول ١٤ رقماً  ",
                },
                maxLength: {
                  value: 14,
                  message:
                    language === "en"
                      ? "Mobile number should be 14 digits"
                      : "يجب أن يكون رقم الهاتف المحمول ١٤ رقماً  ",
                },
              })}
            />

            {errors?.mobile && (
              <p style={{ color: "red", ...style }}>
                {errors?.mobile?.message}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label
              htmlFor="emiratesId"
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#323238",
                fontFamily: "Inter",
                ...style,
              }}
            >
              {language === "en"
                ? "Emirates Id Number"
                : "رقم الهوية الإماراتية"}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Controller
              control={control}
              defaultValue=""
              name="emiratesId"
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  placeholder={
                    language === "en" ? "XXXXXXXXXXXXXXX" : "XXXXXXXXXXXXXXX"
                  }
                  // onChange={handleEmiratesIdChange}
                  style={{
                    height: 48,
                    padding: "8px 16px",
                    borderRadius: 4,
                    border: `1px solid ${
                      errors?.emiratesId ? "red" : "#E1E1E6"
                    }`, // Highlight border if there's an error
                    width: "100%",
                    ...style,
                  }}
                  onChange={(e) => handleEmirateID(e.target.value)}
                />
              )}
              {...register("emiratesId", {
                required: {
                  value: true,
                  message:
                    language === "en"
                      ? "Emirates Id Number is required"
                      : " رقم الهوية الإماراتية مطلوب",
                },
                pattern: {
                  value: /^[\u0660-\u0669\u06F0-\u06F90-9]+$/,
                  message:
                    language === "en"
                      ? "Please enter a valid Emirates Id"
                      : "الرجاء إدخال رقم هوية إماراتية صحيح",
                },
                minLength: {
                  value: 15,
                  message:
                    language === "en"
                      ? "Emirates Id should be 15 digits"
                      : "يجب ان يكون رقم الهوية ١٥ رقما",
                },
                maxLength: {
                  value: 15,
                  message:
                    language === "en"
                      ? "Emirates Id should be 15 digits"
                      : "يجب ان يكون رقم الهوية ١٥ رقما",
                },
              })}
            />

            {errors?.emiratesId && (
              <p style={{ color: "red", ...style }}>
                {errors?.emiratesId?.message}
              </p>
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              height: "100%",
              alignItems: "flex-end",
            }}
          >
            <button
              style={{
                backgroundColor: "#00B783",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                width: 128,
                height: 40,
                borderRadius: 8,
              }}
            >
              {language === "en" ? "Submit" : "تسجيل"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default LandingFrom;
