import React from "react";

const Management = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3684 10.4211C14.6938 10.4211 16.5789 8.53594 16.5789 6.21053C16.5789 3.88512 14.6938 2 12.3684 2C10.043 2 8.15784 3.88512 8.15784 6.21053C8.15784 8.53594 10.043 10.4211 12.3684 10.4211Z"
        stroke="#1C1C1C"
        strokeWidth="2"
      />
      <path
        d="M16.5789 6.21053C16.5789 3.88512 14.6938 2 12.3684 2C10.043 2 8.15784 3.88512 8.15784 6.21053"
        stroke="#2B4D70"
        strokeWidth="2"
      />
      <path
        d="M12.3684 22C16.4379 22 19.7368 20.1149 19.7368 17.7895C19.7368 15.4641 16.4379 13.5789 12.3684 13.5789C8.29895 13.5789 5 15.4641 5 17.7895C5 20.1149 8.29895 22 12.3684 22Z"
        stroke="#00B783"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Management;
