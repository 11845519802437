import React from "react";

const MoreTipsIcon = ({ width ,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M8.99792 17.9997L14.9979 11.9997L8.99792 5.9997"
          stroke="#1C1C1C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default MoreTipsIcon;
