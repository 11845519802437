export const BACKEND_BASE_URL = "https://uaeei-api.ucglab.com/api/";

export const ApiUrlConstants = {
  emissionsByDateRange: "emissions_range_api.xefrd",
  totalEmissionsByDateRange: "total_emissions_api.xefrd",
  emissionsByMonth: "emission_api.xefrd",
  adminLogin: "admin/admin_login.xefrd",
  adminsList: "admin/admin_list.xefrd",
  editAdmin: "admin/admin_edit.xefrd",
  addAdmin: "admin/admin_add.xefrd",
  rewardsProviderList: "green-points/list_reward_provider.xefrd",
  addRewardProviderList: "green-points/add_reward_provider.xefrd",
  editRewardProviderList: "green-points/edit_reward_provider.xefrd",
  rewardsRulesList: "green-points/list_reward_rule.xefrd",
  addRewardRulesList: "green-points/add_reward_rule.xefrd",
  editRewardRulesList: "green-points/edit_reward_rule.xefrd",
};
