import React from "react";

const AdSectionIcon5 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 80 83"
      fill="none"
    >
      <path
        d="M1.03564 0C1.77174 2.01353 2.49082 3.89709 3.15108 5.80182C4.88145 10.7953 8.28289 14.4408 12.3349 17.5719C15.1796 19.7705 18.1007 21.8781 20.8433 24.1993C22.6257 25.6723 24.096 27.4946 25.1662 29.5568C26.2364 31.619 26.8845 33.8788 27.0715 36.2007C27.0837 36.381 27.0847 36.5619 27.0744 36.7423C26.5613 35.5289 25.985 34.3438 25.3479 33.1922C23.7751 30.6244 21.2746 29.3596 18.475 28.7669C16.3429 28.3639 14.192 28.0704 12.0305 27.8876C9.49887 27.6174 7.02267 27.1984 4.77011 25.8992C4.69212 25.8654 4.61055 25.8408 4.52702 25.8259C4.4959 25.8874 4.46478 25.9485 4.43318 26.0105C5.09781 26.391 5.73765 26.8282 6.43194 27.1428C8.67136 28.1505 11.0605 28.6108 13.4546 29.0579C15.2336 29.3902 17.0203 29.721 18.7614 30.2054C22.9957 31.3825 25.4053 34.2507 26.0933 38.6239C26.1269 39.0327 26.1944 39.438 26.295 39.8354C26.5094 40.47 26.209 40.6669 25.681 40.8505C23.7178 41.5564 21.6302 41.8372 19.5532 41.6746C17.4762 41.512 15.456 40.9098 13.6233 39.9068C6.20294 36.0668 1.88405 29.913 0.623826 21.6083C-0.228477 15.9891 -0.123459 10.3345 0.435668 4.68478C0.571317 3.31519 0.741486 1.94953 0.900958 0.582398C0.91992 0.422891 0.970971 0.270276 1.03564 0Z"
        fill="#00B783"
      />
      <path
        d="M23.4128 16.5504C26.5245 19.3698 27.7308 23.0744 28.2364 27.0951C28.5721 30.249 28.5202 33.4329 28.0818 36.5739C28.0774 36.6118 28.0375 36.6458 27.9773 36.7388C27.7638 35.6188 27.5961 34.5387 27.3496 33.4778C26.2474 28.7374 23.585 25.018 19.8938 22.0071C17.5328 20.0812 15.0444 18.3104 12.752 16.3062C8.38012 12.4839 5.3584 7.68295 3.27408 2.25429C3.24053 2.16863 3.22011 2.07706 3.1958 1.99238C3.25366 1.96038 3.30617 1.91361 3.32464 1.92445C6.62252 3.87595 10.2388 5.03976 13.8348 6.25329C17.7982 7.59089 21.7082 9.06239 25.327 11.2177C27.294 12.3265 29.0562 13.7722 30.5356 15.4904C33.2967 18.8381 34.0513 22.7854 33.6473 26.9882C33.2141 31.5061 31.158 35.4067 28.7139 39.1482C28.7678 38.7194 28.8179 38.2891 28.8762 37.8603C28.9346 37.4315 29.0114 37.0012 29.0644 36.57C29.554 32.5867 29.6585 28.6119 28.691 24.6788C28.0035 21.885 26.8352 19.3491 24.6279 17.4198C24.393 17.2145 24.1451 17.0259 23.8937 16.8403C23.743 16.7296 23.5743 16.6439 23.4128 16.5504Z"
        fill="#00B783"
      />
      <path
        d="M41.1153 29.8721C40.3331 31.0007 39.7508 32.2586 39.3947 33.5895C38.574 36.6841 36.5261 38.6765 33.5769 40.1691C33.4548 38.6437 33.4181 37.1125 33.467 35.5828C33.8929 30.6179 36.1522 26.7174 40.4371 24.1712C42.8841 22.7169 45.6448 22.298 48.4316 22.2541C50.814 22.2172 53.2022 22.4127 55.5885 22.4368C57.2454 22.4535 58.9038 22.3546 60.4504 22.3108C59.3876 24.1933 54.8105 29.5299 52.8235 31.1048C52.7588 29.6003 53.0272 28.087 52.1997 26.7302C52.2607 27.8809 52.249 29.0345 52.1647 30.1837C52.0815 30.7501 51.8887 31.2943 51.5973 31.7849C51.3058 32.2756 50.9215 32.7032 50.4664 33.043C46.887 35.828 43.047 38.1349 38.6518 39.3692C37.9196 39.5749 37.1733 39.731 36.3214 39.9378C36.4211 39.7851 36.4474 39.7039 36.5033 39.6675C38.5701 38.2949 39.6334 36.3109 40.0822 33.8893C40.3379 32.5084 40.775 31.1619 41.1115 29.8726"
        fill="#00B783"
      />
      <path
        d="M34.2732 41.7919C39.0797 41.8077 43.7132 42.0568 48.0447 44.1983C46.9153 42.8959 45.4281 41.9642 43.773 41.5221C42.1554 41.0475 40.4892 40.7443 39.0316 40.4085C47.8041 37.4467 55.3314 31.5713 60.3931 23.7351L60.5677 23.8296C60.3543 25.2731 60.1787 26.7229 59.9215 28.157C59.3223 31.9297 58.1028 35.574 56.314 38.9385C54.7095 41.8165 52.6276 44.2012 49.5782 45.6122C45.5175 47.4913 38.2012 47.2048 34.2712 41.7914"
        fill="#00B783"
      />
      <path
        d="M22.3918 54.8138L34.7433 69.9999L74 33.6624"
        stroke="white"
        strokeWidth="17"
      />
    </svg>
  );
};

export default AdSectionIcon5;
