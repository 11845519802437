import React from "react";
import Modal from "../../Pages/Landing/Components/Modal";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addAdminApi } from "../../redux/Thunks/AdminsThunks";
import Select from "react-select";

const options = [
  { id: 1, label: "Admin", value: "admin" },
  {
    id: 2,
    label: "Super Admin",
    value: "super",
  },
];

const AddNewAdminModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("uae_id", data.uae_id);
      formData.append("password", data.password);
      formData.append("admin_type", data.admin_type);
      dispatch(addAdminApi({ data: formData }));
      reset();
      closeModal();
    } catch (error) {
      console.error("An error occurred during form submission", error);
    }
  };

  return (
    <Modal
      closeIcon
      isOpen={isModalOpen}
      onClose={() => {
        reset();
        closeModal();
      }}
      closeOnOverlayClick={false}
      title="Add New Admin"
      customTitle
      circleClose
      height={550 + Object.keys(errors).length * 30}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 30,
          gap: 15,
        }}
      >
        <div className="w-full flex flex-col">
          <label htmlFor="phone" className="text-[14px] font-[600] font-inter">
            Phone Number
          </label>
          <input
            id="phone"
            name="phone"
            type="number"
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("phone", {
              required: {
                value: true,
                message: "phone is required",
              },

              minLength: {
                value: 14,
                message: "phone should be 14 digits",
              },
              maxLength: {
                value: 14,
                message: "phone should be 14 digits",
              },
            })}
          />
          {errors["phone"] && (
            <p className="text-red-500">{errors["phone"].message}</p>
          )}
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="uae_id" className="text-[14px] font-[600] font-inter">
            UAE ID
          </label>
          <input
            id="uae_id"
            name="uae_id"
            type="number"
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("uae_id", {
              required: {
                value: true,
                message: "UAE ID is required",
              },
            })}
          />
          {errors["uae_id"] && <p className="text-red-500">Invalid UAE ID</p>}
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="email" className="text-[14px] font-[600] font-inter">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("email", {
              required: {
                value: true,
                message: "Email is required",
              },
            })}
          />
          {errors["email"] && <p className="text-red-500">Invalid Email</p>}
        </div>
        <div className="w-full flex flex-col">
          <label
            htmlFor="password"
            className="text-[14px] font-[600] font-inter"
          >
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("password", {
              required: {
                value: true,
                message: "password is required",
              },
            })}
          />
          {errors["password"] && <p className="text-red-500">Invalid Email</p>}
        </div>
        <div className="w-full flex flex-col">
          <label
            htmlFor="admin_type"
            className="text-[14px] font-[600] font-inter"
          >
            Type
          </label>
          <Controller
            control={control}
            id="admin_type"
            name="admin_type"
            rules={{
              required: {
                value: true,
                message: "Type is required",
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    height: 40,
                    backgroundColor: "#f8f8f8",
                    borderRadius: 8,
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: 8,
                    backgroundColor: "#f8f8f8",
                  }),
                }}
                inputRef={ref}
                value={value ? options.find((x) => x.value === value) : value}
                onChange={(option) => onChange(option ? option.value : option)}
                options={options}
              />
            )}
          />
          {errors["admin_type"] && (
            <p className="text-red-500">{errors["admin_type"].message}</p>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <button
            style={{
              backgroundColor: "#2B4D70",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              width: 128,
              height: 40,
              borderRadius: 8,
            }}
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddNewAdminModal;
