const TipOutlineCard = ({ title, height, options, children, optionRes }) => {
  return (
    <div
      className="w-full relative flex justify-between items-center h-full bg-white flex-col p-5 rounded-2xl"
      style={{
        height: height,
      }}
    >
      <div className="w-full flex flex-row xs:items-start sm:items-start md:items-center justify-between">
        <span className="font-inter xs:text-[12px] lg:text-[20px] text-[#1C1C1C] sm:text-[14px] md:text-[16px] font-bold leading-5">
          {title}
        </span>
        {options && options}
      </div>
      {children}
    </div>
  );
};

export default TipOutlineCard;
