import React from "react";
import { useCurrentWidth } from "react-socks";

const VideoBackground = ({ videoUrl }) => {
  const width = useCurrentWidth();

  return (
    <div
      style={{
        position: "sticky",
        top: width > 1024 ? 130 : 90,
        left: 0,
        width: "100%",
        height: width <= 1024 ? "505px" : "750px", // Set the height to 100%
        overflow: "hidden",
        zIndex: -1,
      }}
    >
      <video
        controls={false}
        loop
        muted
        autoPlay
        style={{
          width: "100%",
          height: width <= 1024 ? "505px" : "750px",
          objectFit: "fill",
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoBackground;
