import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";

const Layout = () => {
  const location = useLocation();

  return (
    <div>
      <Header user={location.pathname === "/user"} />
      <Outlet />
    </div>
  );
};

export default Layout;
