import { createBrowserRouter } from "react-router-dom";
import Home from "../Pages/Home/Home";
import { ROUTES_MAP } from "./routesMap";
import Layout from "../Components/Layout/Layout";
import UserDashboard from "../Pages/UserDashboard/UserDashboard";
import Landing from "../Pages/Landing/Landing";
import AdminLogin from "../Pages/AdminLogin/AdminLogin";
import AdminsManager from "../Pages/AdminsManager/AdminsManager";
import RewardManeger from "../Pages/RewardManeger/RewardManeger";

const routes = [
  {
    element: <Layout />,
    children: [
      {
        path: ROUTES_MAP.admin,
        element: <Home />,
      },
      {
        path: ROUTES_MAP.adminsList,
        element: <AdminsManager />,
      },
      {
        path: ROUTES_MAP.user,
        element: <UserDashboard />,
      },
      {
        path: ROUTES_MAP.rewards,
        element: <RewardManeger />,
      },
    ],
  },
  {
    path: ROUTES_MAP.landing,
    element: <Landing />,
  },
  {
    path: ROUTES_MAP.adminLogin,
    element: <AdminLogin />,
  },
];

export const router = createBrowserRouter(routes);
