import React from "react";
import { useLanguage } from "../../../Contexts/LanguageContext";
import MobileWeb from "../icons/MobileWeb";
import { useCurrentWidth } from "react-socks";
import OverviewBGIcon from "../icons/OverviewBGIcon";

const MobileLaptopSection = () => {
  const { language, style } = useLanguage();
  const width = useCurrentWidth();
  const myGif = "./images/UAEEIEn.gif";
  const myArGif = "./images/UAEEIAr.gif";
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#F2F2F2",
        fontFamily: "Inter",
        padding: "40px 16px",
        flexDirection:
          language === "en" && width >= 1440
            ? "row"
            : language !== "en" && width >= 1440
            ? "row-reverse"
            : "column",

        justifyContent: "space-between",
        position: "relative",
        // height: width < 1440 ? 550 : 890,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: width >= 1440 ? "center" : "flex-start",
          alignItems:'flex-start'
        }}
      >
        <p
          style={{
            ...style,
            color: "#2B4D70",
            fontSize:
              language === "en" && width >= 1440
                ? 88
                : language !== "en" && width >= 1440
                ? 50
                : 24,
            fontWeight: 500,
            marginTop: width >= 1440 ? 20 : 0,
            width: "100%",
          }}
        >
          {language === "en" ? "Web / Mobile " : " موقع الكتروني / تطبيق ذكي"}
        </p>
        {language === "en" && width >= 768 ? (
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: width >= 768 ? "40%" : "30%",
            }}
          >
            <MobileWeb />
          </div>
        ) : language !== "en" && width >= 768 ? (
          <div
            style={{
              position: "absolute",
              righ: 0,
              bottom: 0,
              width: width >= 768 ? "40%" : "30%",
            }}
          >
            <OverviewBGIcon stroke="#2B4D70" />
          </div>
        ) : null}
      </div>
      {language === "en" ? (
        <div
          style={{
            width: width < 1440 ? "100%" : "50%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: width < 1440 ? "flex-start" : "flex-end",
          }}
        >
          <img
            src={myGif}
            alt="My GIF"
            style={{ 
              width:
                width > 1920
                  ? "80%"
                  : width < 1440 && width >= 768
                  ? "40%"
                  : width < 768
                  ? "90%"
                  : "100%",
            }}
          />
        </div>
      ) : (
        <div
          style={{
            width: width < 1440 ? "100%" : "50%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <img
            src={myArGif}
            alt="My GIF"
            style={{
              width:
                width > 1920
                  ? "80%"
                  : width < 1440 && width >= 768
                  ? "40%"
                  : width < 768
                  ? "90%"
                  : "100%",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MobileLaptopSection;
