import React from "react";
import { objectives } from "../constants/landingObjective";
import { useLanguage } from "../../../Contexts/LanguageContext";
import { useCurrentWidth } from "react-socks";

const Objectives = () => {
  const { language, style } = useLanguage();
  const width = useCurrentWidth();

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#2B4D70",
        fontFamily: "Inter",
        padding: width <= 1024 ? "40px 16px" : "110px 96px",
        flexDirection:
          width <= 1024
            ? "column"
            : width > 1024 && language === "en"
            ? "row"
            : "row-reverse",
        justifyContent: width <= 1024 ? "center" : "flex-start",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: width <= 1024 ? "100%" : "50%",
        }}
      >
        <p
          style={{
            ...style,
            color: "white",
            fontSize:
              width >= 1920 ? 88 : width < 1920 && width >= 1024 ? 50 : 40,
            // language === "en" && width <= 1024
            //   ? 40
            //   : width > 1024 && width <= 1920
            //   ? 88
            //   : 36,
            fontWeight: 500,
          }}
        >
          {language === "en" ? "Forward-looking" : "الأهداف والتطلّعات "}
        </p>
        <p
          style={{
            ...style,
            color: "#00B783",
            fontSize:
              width >= 1920 ? 88 : width < 1920 && width >= 1024 ? 50 : 40,
            fontWeight: 500,
          }}
        >
          {language === "en" ? "Objectives" : "المستقبلية"}
        </p>
      </div>
      <div
        style={{
          marginTop: 16,
          marginBottom: 21,
          color: "white",
          display: "flex",
          columnGap: 50,
          marginLeft: language === "en" ? 10 : 0,
          flexDirection:
            width <= 1024
              ? "column"
              : language === "en"
              ? "row"
              : "row-reverse",
          alignItems: language === "en" ? "flex-start" : "flex-end",
        }}
      >
        {objectives.map((item, index) => (
          <div
            key={item.id}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: language === "en" ? "flex-start" : "flex-end",
              marginTop: index === 1 && width <= 1024 ? 30 : 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: language === "en" ? "flex-start" : "flex-end",
              }}
            >
              <img
                src={item.image}
                alt="Objective-placeholder"
                style={{
                  height: width <= 390 ? 225 : 270,
                  objectFit: "cover",
                  borderRadius: 8,
                }}
              />
              <p
                style={{
                  margin: "15px 0",
                  fontSize:
                    width >= 1920
                      ? 30
                      : width < 1920 && width >= 1024
                      ? 28
                      : 24,
                  fontWeight: language === "en" ? 400 : 300,
                  width: width >= 1024 ? "85%" : "100%",
                  ...style,
                }}
              >
                {language === "en" ? item.enBody : item.arBody}
              </p>
            </div>
            <div
              style={{
                height: 4,
                width: width <= 390 ? 225 : 363,
                backgroundColor: "#D19631",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Objectives;
