import React from "react";

const QrCode = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3111_1075)">
        <path
          d="M43.52 10.24C44.9344 10.24 46.08 9.0944 46.08 7.68V2.56C46.08 1.1456 44.9344 0 43.52 0C42.1056 0 40.96 1.1456 40.96 2.56V5.12C40.96 5.12 42.8812 5.12 43.52 5.12C43.9833 5.12 44.7424 5.12 45.3145 5.12H40.96V7.68C40.96 9.0944 42.1056 10.24 43.52 10.24Z"
          fill="white"
        />
        <path
          d="M53.76 10.24C55.1744 10.24 56.32 9.0944 56.32 7.68V5.12H58.88C60.2944 5.12 61.44 3.9744 61.44 2.56C61.44 1.1456 60.2944 0 58.88 0H56.32C53.4924 0 51.2 2.29248 51.2 5.12V7.68C51.2 9.0944 52.3456 10.24 53.76 10.24Z"
          fill="white"
        />
        <path
          d="M84.4799 15.3601C85.8943 15.3601 87.0399 14.2145 87.0399 12.8001V7.68012C87.0399 6.26572 85.8943 5.12012 84.4799 5.12012C83.0655 5.12012 81.9199 6.26572 81.9199 7.68012V10.2401C81.9199 10.2401 83.8412 10.2401 84.4799 10.2401C84.9433 10.2401 85.7023 10.2401 86.2745 10.2401H81.9199V12.8001C81.9199 14.2145 83.0655 15.3601 84.4799 15.3601Z"
          fill="white"
        />
        <path
          d="M43.52 20.4799C44.9338 20.4799 46.08 19.3337 46.08 17.9199C46.08 16.506 44.9338 15.3599 43.52 15.3599C42.1061 15.3599 40.96 16.506 40.96 17.9199C40.96 19.3337 42.1061 20.4799 43.52 20.4799Z"
          fill="white"
        />
        <path
          d="M84.4799 25.6C85.8938 25.6 87.0399 24.4538 87.0399 23.04C87.0399 21.6261 85.8938 20.48 84.4799 20.48C83.0661 20.48 81.9199 21.6261 81.9199 23.04C81.9199 24.4538 83.0661 25.6 84.4799 25.6Z"
          fill="white"
        />
        <path
          d="M74.2399 35.8402C75.6538 35.8402 76.7999 34.6941 76.7999 33.2802C76.7999 31.8664 75.6538 30.7202 74.2399 30.7202C72.8261 30.7202 71.6799 31.8664 71.6799 33.2802C71.6799 34.6941 72.8261 35.8402 74.2399 35.8402Z"
          fill="white"
        />
        <path
          d="M46.08 40.96H51.2V48.64C51.2 50.0544 52.3456 51.2 53.76 51.2C55.1744 51.2 56.32 50.0544 56.32 48.64V40.96H61.44V43.52C61.44 44.9344 62.5856 46.08 64 46.08C65.4144 46.08 66.56 44.9344 66.56 43.52V30.72H69.12C70.5344 30.72 71.68 29.5744 71.68 28.16C71.68 26.7456 70.5344 25.6 69.12 25.6H66.56V20.48H71.68V23.04C71.68 24.4544 72.8256 25.6 74.24 25.6C75.6544 25.6 76.8 24.4544 76.8 23.04V20.48C76.8 17.6525 74.5075 15.36 71.68 15.36V2.56C71.68 1.1456 70.5344 0 69.12 0C67.7056 0 66.56 1.1456 66.56 2.56V5.12C66.56 5.12 68.4812 5.12 69.12 5.12C69.5833 5.12 70.3424 5.12 70.9145 5.12H66.56V10.24H61.44C58.6124 10.24 56.32 12.5325 56.32 15.36C53.4924 15.36 51.2 17.6525 51.2 20.48H48.64C47.2256 20.48 46.08 21.6269 46.08 23.04C46.08 24.4531 47.2256 25.6 48.64 25.6H51.2C54.0275 25.6 56.32 23.3075 56.32 20.48H61.44V25.6H58.88C57.4656 25.6 56.32 26.7469 56.32 28.16C56.32 29.5731 57.4656 30.72 58.88 30.72H61.44V35.84H56.32V33.28C56.32 31.8656 55.1744 30.72 53.76 30.72C52.3456 30.72 51.2 31.8656 51.2 33.28V35.84C51.2 35.84 53.1212 35.84 53.76 35.84C54.2233 35.84 54.9824 35.84 55.5545 35.84H46.08V33.28C46.08 31.8656 44.9344 30.72 43.52 30.72C42.1056 30.72 40.96 31.8656 40.96 33.28V35.84C40.96 35.84 42.8812 35.84 43.52 35.84C43.9833 35.84 44.7424 35.84 45.3145 35.84H40.96C40.96 38.6675 43.2524 40.96 46.08 40.96Z"
          fill="white"
        />
        <path
          d="M2.56 46.08C3.97385 46.08 5.12 44.9338 5.12 43.52C5.12 42.1061 3.97385 40.96 2.56 40.96C1.14615 40.96 0 42.1061 0 43.52C0 44.9338 1.14615 46.08 2.56 46.08Z"
          fill="white"
        />
        <path
          d="M28.1601 46.08H38.4001C39.8145 46.08 40.9601 44.9344 40.9601 43.52C40.9601 42.1056 39.8145 40.96 38.4001 40.96H28.1601C26.7457 40.96 25.6001 42.1068 25.6001 43.52C25.6001 44.9331 26.7457 46.08 28.1601 46.08Z"
          fill="white"
        />
        <path
          d="M74.2399 46.08C75.6538 46.08 76.7999 44.9338 76.7999 43.52C76.7999 42.1061 75.6538 40.96 74.2399 40.96C72.8261 40.96 71.6799 42.1061 71.6799 43.52C71.6799 44.9338 72.8261 46.08 74.2399 46.08Z"
          fill="white"
        />
        <path
          d="M94.7199 46.08C96.1338 46.08 97.2799 44.9338 97.2799 43.52C97.2799 42.1061 96.1338 40.96 94.7199 40.96C93.3061 40.96 92.1599 42.1061 92.1599 43.52C92.1599 44.9338 93.3061 46.08 94.7199 46.08Z"
          fill="white"
        />
        <path
          d="M110.08 46.08C111.494 46.08 112.64 44.9338 112.64 43.52C112.64 42.1061 111.494 40.96 110.08 40.96C108.666 40.96 107.52 42.1061 107.52 43.52C107.52 44.9338 108.666 46.08 110.08 46.08Z"
          fill="white"
        />
        <path
          d="M79.36 51.2002H81.92V53.7602C81.92 55.1746 83.0656 56.3202 84.48 56.3202C85.8944 56.3202 87.04 55.1746 87.04 53.7602V51.2002H89.6C91.0144 51.2002 92.16 50.0546 92.16 48.6402C92.16 47.2258 91.0144 46.0802 89.6 46.0802H87.04V33.2802C87.04 31.8658 85.8944 30.7202 84.48 30.7202C83.0656 30.7202 81.92 31.8658 81.92 33.2802V35.8402C81.92 35.8402 83.8413 35.8402 84.48 35.8402C84.9434 35.8402 85.7025 35.8402 86.2746 35.8402H81.92V46.0802H79.36C77.9456 46.0802 76.8 47.2271 76.8 48.6402C76.8 50.0533 77.9456 51.2002 79.36 51.2002Z"
          fill="white"
        />
        <path
          d="M97.28 51.2001C97.28 51.2001 99.2013 51.2001 99.84 51.2001C100.303 51.2001 101.062 51.2001 101.635 51.2001H97.28V53.7601C97.28 55.1745 98.4256 56.3201 99.84 56.3201C101.254 56.3201 102.4 55.1745 102.4 53.7601V48.6401C102.4 47.2257 101.254 46.0801 99.84 46.0801C98.4256 46.0801 97.28 47.2257 97.28 48.6401V51.2001Z"
          fill="white"
        />
        <path
          d="M120.32 51.2C121.734 51.2 122.88 50.0544 122.88 48.64V46.08H125.44C126.854 46.08 128 44.9344 128 43.52C128 42.1056 126.854 40.96 125.44 40.96H122.88C120.052 40.96 117.76 43.2524 117.76 46.08V48.64C117.76 50.0544 118.906 51.2 120.32 51.2Z"
          fill="white"
        />
        <path
          d="M66.5601 56.3198H58.8801C57.4657 56.3198 56.3201 57.4667 56.3201 58.8798C56.3201 60.2929 57.4657 61.4398 58.8801 61.4398H74.2401C75.6545 61.4398 76.8001 60.2942 76.8001 58.8798C76.8001 57.4654 75.6545 56.3198 74.2401 56.3198H66.5601Z"
          fill="white"
        />
        <path
          d="M33.28 66.5599C34.6938 66.5599 35.84 65.4138 35.84 63.9999C35.84 62.5861 34.6938 61.4399 33.28 61.4399C31.8661 61.4399 30.72 62.5861 30.72 63.9999C30.72 65.4138 31.8661 66.5599 33.28 66.5599Z"
          fill="white"
        />
        <path
          d="M79.36 66.5599C80.7739 66.5599 81.92 65.4138 81.92 63.9999C81.92 62.5861 80.7739 61.4399 79.36 61.4399C77.9462 61.4399 76.8 62.5861 76.8 63.9999C76.8 65.4138 77.9462 66.5599 79.36 66.5599Z"
          fill="white"
        />
        <path
          d="M122.88 61.4398H117.76C117.76 58.6123 115.468 56.3198 112.64 56.3198H110.08C108.666 56.3198 107.52 57.4667 107.52 58.8798C107.52 60.2929 108.666 61.4398 110.08 61.4398H112.64C112.64 64.2673 114.932 66.5598 117.76 66.5598H122.88V69.1198C122.88 70.5342 124.026 71.6798 125.44 71.6798C126.854 71.6798 128 70.5342 128 69.1198V66.5598C128 63.7323 125.708 61.4398 122.88 61.4398Z"
          fill="white"
        />
        <path
          d="M58.88 66.5602H56.32C56.32 63.7327 54.0275 61.4402 51.2 61.4402V56.3202C51.2 53.4927 48.9075 51.2002 46.08 51.2002H33.28C31.8656 51.2002 30.72 52.3471 30.72 53.7602C30.72 55.1733 31.8656 56.3202 33.28 56.3202H40.96C40.96 59.1477 43.2525 61.4402 46.08 61.4402V76.8002C46.08 79.6277 48.3725 81.9202 51.2 81.9202H53.76C55.1744 81.9202 56.32 80.7746 56.32 79.3602C56.32 77.9458 55.1744 76.8002 53.76 76.8002H51.2V71.6802H58.88C60.2944 71.6802 61.44 70.5346 61.44 69.1202C61.44 67.7058 60.2944 66.5602 58.88 66.5602Z"
          fill="white"
        />
        <path
          d="M84.4799 71.6801C85.8938 71.6801 87.0399 70.5339 87.0399 69.1201C87.0399 67.7062 85.8938 66.5601 84.4799 66.5601C83.0661 66.5601 81.9199 67.7062 81.9199 69.1201C81.9199 70.5339 83.0661 71.6801 84.4799 71.6801Z"
          fill="white"
        />
        <path
          d="M74.2401 76.8001C75.6545 76.8001 76.8001 75.6545 76.8001 74.2401V71.6801C76.8001 68.8525 74.5076 66.5601 71.6801 66.5601H69.1201C67.7057 66.5601 66.5601 67.7069 66.5601 69.1201C66.5601 70.5332 67.7057 71.6801 69.1201 71.6801H71.6801V74.2401C71.6801 75.6545 72.8257 76.8001 74.2401 76.8001Z"
          fill="white"
        />
        <path
          d="M117.76 76.8002H120.32C121.734 76.8002 122.88 75.6546 122.88 74.2402C122.88 72.8258 121.734 71.6802 120.32 71.6802H115.2C113.785 71.6802 112.64 72.8271 112.64 74.2402C112.64 75.6533 113.785 76.8002 115.2 76.8002H117.76Z"
          fill="white"
        />
        <path
          d="M2.56 81.9198C3.97385 81.9198 5.12 80.7737 5.12 79.3598C5.12 77.946 3.97385 76.7998 2.56 76.7998C1.14615 76.7998 0 77.946 0 79.3598C0 80.7737 1.14615 81.9198 2.56 81.9198Z"
          fill="white"
        />
        <path
          d="M40.96 76.8002C40.96 73.9727 38.6675 71.6802 35.84 71.6802H33.28C31.8656 71.6802 30.72 72.8271 30.72 74.2402C30.72 75.6533 31.8656 76.8002 33.28 76.8002H35.84V79.3602C35.84 80.7746 36.9856 81.9202 38.4 81.9202C39.8144 81.9202 40.96 80.7746 40.96 79.3602V76.8002Z"
          fill="white"
        />
        <path
          d="M66.5599 81.9202H69.1199C70.5343 81.9202 71.6799 80.7746 71.6799 79.3602C71.6799 77.9458 70.5343 76.8002 69.1199 76.8002H66.5599V74.2402C66.5599 72.8258 65.4143 71.6802 63.9999 71.6802C62.5855 71.6802 61.4399 72.8258 61.4399 74.2402V76.8002C61.4399 76.8002 63.3612 76.8002 63.9999 76.8002C64.4633 76.8002 65.2223 76.8002 65.7945 76.8002H61.4399C61.4399 79.6277 63.7324 81.9202 66.5599 81.9202Z"
          fill="white"
        />
        <path
          d="M79.36 81.9198C80.7739 81.9198 81.92 80.7737 81.92 79.3598C81.92 77.946 80.7739 76.7998 79.36 76.7998C77.9462 76.7998 76.8 77.946 76.8 79.3598C76.8 80.7737 77.9462 81.9198 79.36 81.9198Z"
          fill="white"
        />
        <path
          d="M125.44 76.7998C124.026 76.7998 122.88 77.9454 122.88 79.3598V81.9198C122.88 81.9198 124.801 81.9198 125.44 81.9198C125.903 81.9198 126.662 81.9198 127.235 81.9198H107.52V66.5598C107.52 63.7323 105.228 61.4398 102.4 61.4398H97.2801C97.2801 58.6123 94.9876 56.3198 92.16 56.3198C89.3325 56.3198 87.04 58.6123 87.04 61.4398C87.04 64.2673 89.3325 66.5598 92.16 66.5598C92.16 69.3873 94.4525 71.6798 97.2801 71.6798H102.4V76.7998C99.5725 76.7998 97.2801 79.0923 97.2801 81.9198H92.16V74.2398C92.16 72.8254 91.0144 71.6798 89.6 71.6798C88.1856 71.6798 87.04 72.8254 87.04 74.2398V76.7998C87.04 76.7998 88.9613 76.7998 89.6 76.7998C90.0634 76.7998 90.8225 76.7998 91.3946 76.7998H87.04V81.9198C84.2125 81.9198 81.92 84.2123 81.92 87.0398V92.1598C79.0925 92.1598 76.8 94.4523 76.8 97.2798C76.8 100.107 79.0925 102.4 81.92 102.4C81.92 105.227 84.2125 107.52 87.04 107.52H92.16V112.64C89.3325 112.64 87.04 114.932 87.04 117.76H81.92V115.2C81.92 113.785 80.7744 112.64 79.36 112.64C77.9456 112.64 76.8 113.785 76.8 115.2V117.76C76.8 117.76 78.7213 117.76 79.36 117.76C79.8234 117.76 80.5825 117.76 81.1546 117.76H76.8C76.8 120.587 79.0925 122.88 81.92 122.88H92.16C94.9876 122.88 97.2801 120.587 97.2801 117.76V107.52H102.4V122.88H99.84C98.4256 122.88 97.2801 124.027 97.2801 125.44C97.2801 126.853 98.4256 128 99.84 128H125.44C126.854 128 128 126.854 128 125.44C128 124.025 126.854 122.88 125.44 122.88H117.76V117.76C120.588 117.76 122.88 115.467 122.88 112.64H125.44C126.854 112.64 128 111.494 128 110.08C128 108.665 126.854 107.52 125.44 107.52H122.88V97.2798C125.708 97.2798 128 94.9873 128 92.1598V79.3598C128 77.9454 126.854 76.7998 125.44 76.7998ZM102.4 92.1598V102.4H87.04V87.0398H102.4V92.1598ZM112.64 117.76V122.88H107.52V112.64H112.64V117.76ZM117.76 102.4V107.52H112.64C112.64 104.692 110.348 102.4 107.52 102.4V87.0398H112.64V92.1598C112.64 94.9873 114.933 97.2798 117.76 97.2798V102.4ZM122.88 92.1598H117.76V87.0398H122.88V92.1598Z"
          fill="white"
        />
        <path
          d="M33.28 81.92H30.72C30.72 79.0924 28.4275 76.8 25.6 76.8V69.12C25.6 67.7056 24.4544 66.56 23.04 66.56C21.6256 66.56 20.48 67.7056 20.48 69.12V71.68C20.48 71.68 22.4013 71.68 23.04 71.68C23.5034 71.68 24.2624 71.68 24.8346 71.68H15.36C15.36 68.8524 13.0675 66.56 10.24 66.56V51.2H15.36C15.36 54.0275 17.6525 56.32 20.48 56.32H23.04C24.4544 56.32 25.6 55.1744 25.6 53.76C25.6 52.3456 24.4544 51.2 23.04 51.2H20.48V46.08C20.48 43.2524 18.1875 40.96 15.36 40.96C12.5325 40.96 10.24 43.2524 10.24 46.08C7.41248 46.08 5.12 48.3724 5.12 51.2V56.32H2.56C1.1456 56.32 0 57.4668 0 58.88C0 60.2931 1.1456 61.44 2.56 61.44H5.12V71.68C5.12 74.5075 7.41248 76.8 10.24 76.8H15.36C15.36 79.6275 17.6525 81.92 20.48 81.92H25.6C25.6 84.7475 27.8925 87.04 30.72 87.04H33.28C34.6944 87.04 35.84 85.8944 35.84 84.48C35.84 83.0656 34.6944 81.92 33.28 81.92Z"
          fill="white"
        />
        <path
          d="M58.8801 87.0399C60.2939 87.0399 61.4401 85.8938 61.4401 84.4799C61.4401 83.0661 60.2939 81.9199 58.8801 81.9199C57.4662 81.9199 56.3201 83.0661 56.3201 84.4799C56.3201 85.8938 57.4662 87.0399 58.8801 87.0399Z"
          fill="white"
        />
        <path
          d="M94.7199 97.2802C96.1338 97.2802 97.2799 96.134 97.2799 94.7202C97.2799 93.3063 96.1338 92.1602 94.7199 92.1602C93.3061 92.1602 92.1599 93.3063 92.1599 94.7202C92.1599 96.134 93.3061 97.2802 94.7199 97.2802Z"
          fill="white"
        />
        <path
          d="M48.64 97.2799H46.08V84.4799C46.08 83.0655 44.9344 81.9199 43.52 81.9199C42.1056 81.9199 40.96 83.0655 40.96 84.4799V87.0399C40.96 87.0399 42.8812 87.0399 43.52 87.0399C43.9833 87.0399 44.7424 87.0399 45.3145 87.0399H40.96V97.2799C40.96 100.107 43.2524 102.4 46.08 102.4H48.64C50.0544 102.4 51.2 101.254 51.2 99.8399C51.2 98.4255 50.0544 97.2799 48.64 97.2799Z"
          fill="white"
        />
        <path
          d="M48.64 107.52H46.08C43.2524 107.52 40.96 109.812 40.96 112.64V115.2C40.96 116.614 42.1056 117.76 43.52 117.76C44.9344 117.76 46.08 116.614 46.08 115.2V112.64H48.64C50.0544 112.64 51.2 111.494 51.2 110.08C51.2 108.666 50.0544 107.52 48.64 107.52Z"
          fill="white"
        />
        <path
          d="M74.24 112.64C75.6544 112.64 76.8 111.494 76.8 110.08C76.8 108.666 75.6544 107.52 74.24 107.52H71.68C71.68 104.693 69.3875 102.4 66.56 102.4V92.16H69.12C70.5344 92.16 71.68 91.0144 71.68 89.6C71.68 88.1856 70.5344 87.04 69.12 87.04H66.56C63.7324 87.04 61.44 89.3325 61.44 92.16H56.32V89.6C56.32 88.1856 55.1744 87.04 53.76 87.04C52.3456 87.04 51.2 88.1856 51.2 89.6V92.16C51.2 92.16 53.1212 92.16 53.76 92.16C54.2233 92.16 54.9823 92.16 55.5545 92.16H51.2C51.2 94.9876 53.4924 97.28 56.32 97.28H61.44V102.4H58.88C57.4656 102.4 56.32 103.547 56.32 104.96C56.32 106.373 57.4656 107.52 58.88 107.52H61.44V112.64H58.88C57.4656 112.64 56.32 113.787 56.32 115.2C56.32 116.613 57.4656 117.76 58.88 117.76H66.56C69.3875 117.76 71.68 115.468 71.68 112.64H74.24Z"
          fill="white"
        />
        <path
          d="M53.76 117.76H51.2C48.3724 117.76 46.08 120.052 46.08 122.88H43.52C42.1056 122.88 40.96 124.027 40.96 125.44C40.96 126.853 42.1056 128 43.52 128H46.08C48.9075 128 51.2 125.707 51.2 122.88H53.76C55.1744 122.88 56.32 121.734 56.32 120.32C56.32 118.905 55.1744 117.76 53.76 117.76Z"
          fill="white"
        />
        <path
          d="M63.9999 128C65.4138 128 66.5599 126.854 66.5599 125.44C66.5599 124.026 65.4138 122.88 63.9999 122.88C62.5861 122.88 61.4399 124.026 61.4399 125.44C61.4399 126.854 62.5861 128 63.9999 128Z"
          fill="white"
        />
        <path
          d="M74.2399 128C75.6538 128 76.7999 126.854 76.7999 125.44C76.7999 124.026 75.6538 122.88 74.2399 122.88C72.8261 122.88 71.6799 124.026 71.6799 125.44C71.6799 126.854 72.8261 128 74.2399 128Z"
          fill="white"
        />
        <path
          d="M23.744 35.84H12.1062C5.4336 35.84 0.00768 30.3488 0.00768 23.6045L0 0H23.744C30.4141 0 35.84 5.4912 35.84 12.2355V35.84H23.744ZM30.464 12.2355C30.464 8.45312 27.4496 5.376 23.744 5.376H5.37728L5.3824 23.6019C5.3824 27.3869 8.39808 30.464 12.1062 30.464H30.464V12.2355Z"
          fill="#00B783"
        />
        <path
          d="M115.904 0H104.266C97.5923 0 92.1664 5.4912 92.1664 12.2355L92.1587 35.84H115.903C122.573 35.84 127.999 30.3488 127.999 23.6045V0H115.903H115.904ZM122.624 23.6045C122.624 27.3869 119.61 30.464 115.904 30.464H97.5373L97.5424 12.2381C97.5424 8.45312 100.558 5.376 104.266 5.376H122.624V23.6045Z"
          fill="#00B783"
        />
        <path
          d="M23.744 92.1602H12.1062C5.4336 92.1602 0.00768 97.6514 0.00768 104.396L0 128H23.744C30.4141 128 35.84 122.509 35.84 115.765V92.1602H23.744ZM30.464 115.765C30.464 119.547 27.4496 122.624 23.744 122.624H5.37728L5.3824 104.398C5.3824 100.613 8.39808 97.5362 12.1062 97.5362H30.464V115.765Z"
          fill="#00B783"
        />
        <path
          d="M21.413 25.6038H14.4216C14.062 25.6038 13.7138 25.5578 13.381 25.4694C12.6463 25.2762 11.9922 24.887 11.4738 24.3584C11.3791 24.2624 11.2882 24.1613 11.205 24.0576C10.6085 23.3254 10.2514 22.3834 10.2514 21.3606L10.2476 10.2451H21.413C23.7132 10.2451 25.582 12.1498 25.582 14.4896V21.3619L25.6076 25.6038C25.6076 25.6038 22.8517 25.6064 21.413 25.6064V25.6038Z"
          fill="#00B783"
        />
        <path
          d="M113.573 10.236H106.582C106.222 10.236 105.874 10.282 105.541 10.3704C104.806 10.5636 104.152 10.9528 103.634 11.4814C103.539 11.5774 103.448 11.6785 103.365 11.7822C102.769 12.5144 102.412 13.4564 102.412 14.4792L102.408 25.5947H113.573C115.873 25.5947 117.742 23.69 117.742 21.3502V14.4779L117.768 10.236C117.768 10.236 115.012 10.2334 113.573 10.2334V10.236Z"
          fill="#00B783"
        />
        <path
          d="M21.413 102.396H14.4216C14.062 102.396 13.7138 102.442 13.381 102.531C12.6463 102.724 11.9922 103.113 11.4738 103.642C11.3791 103.738 11.2882 103.839 11.205 103.942C10.6085 104.675 10.2514 105.617 10.2514 106.639L10.2476 117.755H21.413C23.7132 117.755 25.582 115.85 25.582 113.51V106.638L25.6076 102.396C25.6076 102.396 22.8517 102.394 21.413 102.394V102.396Z"
          fill="#00B783"
        />
      </g>
      <defs>
        <clipPath id="clip0_3111_1075">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QrCode;
