import React from "react";
import { useLanguage } from "../../../../Contexts/LanguageContext";
import SignUpIcon from "../../icons/SignUpIcon";
import PlayStore from "../../icons/PlayStore";
import AppStore from "../../icons/AppStore";
import { useCurrentWidth } from "react-socks";
import EarlyButtonIcon from "../../icons/EarlyButtonIcon";
import LogoutIcon from "../../icons/LogoutIcon";

const OverlayComponent = ({ openModal, code, uaeUserType }) => {
  const { language, style } = useLanguage();
  const width = useCurrentWidth();
  const uaepassbtn = "./images/UAEPASS_Sign_in_Btn_Default_White_Active.png";
  const ARuaepassbtn = "./images/AR_UAEPASS_Sign_in_Btn_White_Active.png";
  console.log(uaeUserType);
  return (
    <div
      style={{
        width: "100%",
        height: width <= 1024 ? "505px" : "750px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: language === "en" ? "start" : "end",
        padding: width <= 1024 ? "40px 16px" : "40px 96px",
        position: "absolute",
        top: width > 1024 ? 130 : 90,
      }}
    >
      <p
        style={{
          fontSize: width <= 1024 ? 32 : language === "en" ? 85 : 64,
          fontFamily: "Inter",
          fontWeight: 700,
          color: "white",
          ...style,
        }}
      >
        {language === "en" ? (
          <div>
            UAEEI Launching <br /> Ceremony
          </div>
        ) : (
          <div>
            حفل إطلاق مبادرة <br /> الهوية البيئية الإماراتية
          </div>
        )}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <button
          style={{
            display: "none",
            width: "310px",
            // padding: "12px 18px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#00B783",
            color: "white",
            borderRadius: 16,
            marginTop: 24,
            fontFamily: "Inter",
            height: "50px",
          }}
          onClick={() => openModal()}
        >
          {language === "en" ? <SignUpIcon /> : <EarlyButtonIcon />}
          <span
            style={{
              fontSize: 20,
              fontWeight: 400,
              fontFamily: "Inter",
              marginLeft: 7,
            }}
          >
            {language === "en" ? "Early Sign up" : "التسجيل المبكّر"}
          </span>
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 20,
              fontWeight: 600,
              fontFamily: "Inter",
              color: "white",
              margin: "10px 0",
            }}
          >
            {/*language === "en" ? "Or" : "أو"*/}
          </span>
        </div>
        {uaeUserType === "SOP1" ||
        uaeUserType === "SOP2" ||
        uaeUserType === "SOP3" ? (
          <a
            href={process.env.REACT_APP_UAEPASS_LOGOUT}
            style={{
              display: "flex",
              width: 310,
              height: 50,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F6F6F6",
              color: "black",
              borderRadius: 16,
              fontFamily: "Inter",
            }}
          >
            <LogoutIcon />
            <span
              style={{
                fontSize: 20,
                fontWeight: 600,
                fontFamily: "Inter",
                marginLeft: 7,
              }}
            >
              {language === "en" ? "Logout" : "تسجيل الخروج"}
            </span>
          </a>
        ) : (
          <a
            href={
              language === "en"
                ? process.env.REACT_APP_UAEPASS_LINK
                : process.env.REACT_APP_UAEPASS_LINK_AR
            }
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
              borderRadius: 6,
              fontFamily: "Inter",
            }}
          >
            {language === "en" ? (
              <img src={uaepassbtn} alt="uae-pass" />
            ) : (
              <img src={ARuaepassbtn} alt="uae-pass" />
            )}
          </a>
        )}
      </div>

      <div
        style={{
          width: 310,
          display: "flex",
          justifyContent: "space-between",
          marginTop: 80,
        }}
      >
        <a
          href="https://play.google.com/store/apps/details?id=ae.gov.moccae.uaeei"
          target="_blank"
          rel="noreferrer"
        >
          <PlayStore width={150} height={56}/>
        </a>
        <a
          href="https://apps.apple.com/us/app/uaeei/id6473444618"
          target="_blank"
          rel="noreferrer"
        >
          <AppStore width={150} height={56}/>
        </a>
      </div>
    </div>
  );
};

export default OverlayComponent;
