/* eslint-disable no-multi-str */
import React from "react";
import { useLanguage } from "../../../Contexts/LanguageContext";
import { useCurrentWidth } from "react-socks";
import OverviewBGIcon from "../icons/OverviewBGIcon";
import OverviewBGAr from "../icons/OverviewBGAr";

const OverviewSection = () => {
  const { language, style } = useLanguage();
  const width = useCurrentWidth();

  return (
    <div
      style={{
        backgroundColor: "#2B4D70",
        display: "flex",
        color: "white",
        fontFamily: "Inter",
        padding:
          width <= 1024
            ? "40px 16px"
            : width > 1920
            ? "150px 96px"
            : "87px 96px",
        flexDirection: width > 1024 ? "row" : "column",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {width > 1024 ? (
        <div
          style={{
            display: "flex",
            flexDirection: language === "en" ? "row" : "row-reverse",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: 835,
              justifyContent: "center",
              // alignItems: language === "en" ? "flex-start" : "flex-end",
              ...style,
            }}
          >
            <p
              style={{
                fontSize: 88,
                fontWeight: 500,
                fontFamily: language === "en" ? "Inter" : "cairo",
                ...style,
              }}
            >
              {language === "en" ? "Overview" : "لمحة عامة"}
            </p>
            <p
              style={{
                fontSize: 28,
                fontWeight: language === "en" ? 400 : 300,
                fontFamily: language === "en" ? "Inter" : "cairo",
                ...style,
              }}
            >
              {language === "en" ? (
                "A national project designed to create a global role model, the objective of this initiative is to quantitatively assess the environmental impact of individuals on a national scale. This will be achieved through the utilization of an advanced system that will assist policymakers and authorities by providing them with precise information and data. These resources will serve as a basis for establishing future policies that address environmental concerns."
              ) : (
                <div>
                  مشروع الهوية البيئية الإماراتية هو مبادرة وطنية و تهدف إلى
                  إنشاء نموذج عالمي يحتذى به، ويتمثّل هدفه الأساسي بالتقييم
                  الكمّي للبصمة البيئية للأفراد على مستوى الدولة عبر نظام ذكي
                  يعزز من إمداد صنّاع القرار والمسؤولين بمعلومات وبيانات واضحة
                  يتم بناءً عليها رسم السياسات المستقبلية في هذا المجال.
                </div>
              )}
            </p>
          </div>
          {language === "en" ? (
            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: width >= 1440 ? "40%" : "30%",
              }}
            >
              <OverviewBGIcon />
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: width >= 1440 ? "40%" : "30%",
              }}
            >
              <OverviewBGAr />
            </div>
          )}
        </div>
      ) : (
        <>
          <p
            style={{
              fontSize: language === "en" ? 40 : 36,
              fontWeight: 500,
              fontFamily: language === "en" ? "Inter" : "cairo",
              ...style,
            }}
          >
            {language === "en" ? "Overview" : "لمحة عامة"}
          </p>
          <p
            style={{
              fontSize: 18,
              fontWeight: language === "en" ? 400 : 300,
              fontFamily: language === "en" ? "Inter" : "cairo",
              ...style,
            }}
          >
            {language === "en"
              ? "A national project designed to create a global role model, the objective of this initiative is to quantitatively assess the environmental impact of individuals on a national scale. This will be achieved through the utilization of an advanced system that will assist policymakers and authorities by providing them with precise information and data. These resources will serve as a basis for establishing future policies that address environmental concerns."
              : "مشروع الهوية البيئية الإماراتية هو مبادرة وطني يهدف إلى إنشاء نموذج عالمي يحتذى به، ويتمثّل هدفه الأساسي بالتقييم الكمّي للبصمة البيئية للأفراد على مستوى الدولة عبر نظام ذكي يعزز من إمداد صنّاع القرار والمسؤولين بمعلومات وبيانات واضحة يتم بناءً عليها رسم السياسات المستقبلية في هذا المجال."}
          </p>
        </>
      )}
    </div>
  );
};

export default OverviewSection;
