import React from "react";

const AdminLoginIcon = ({ width = "100%", height = "auto" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 525 445"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.36">
        <path
          d="M645.681 -1.93458C649.069 0.09745 651.629 3.26429 652.904 7.00393C654.179 10.7436 654.089 14.8141 652.648 18.4933C651.207 22.1724 648.51 25.2222 645.034 27.1013C641.558 28.9804 637.529 29.5674 633.662 28.758C586.99 18.9654 538.622 20.8467 492.852 34.2349C447.081 47.6231 405.324 72.1041 371.286 105.505C368.119 108.607 363.849 110.324 359.416 110.278C354.983 110.232 350.749 108.426 347.647 105.259C344.545 102.092 342.828 97.8217 342.874 93.3885C342.92 88.9553 344.726 84.722 347.893 81.6198C385.848 44.3701 432.413 17.068 483.454 2.13737C534.495 -12.7932 588.433 -14.8904 640.479 -3.96783C642.316 -3.59082 644.075 -2.90321 645.681 -1.93458Z"
          stroke="#2B4D70"
          strokeWidth="3"
        />
        <path
          d="M442.622 -59.6766C445.302 -58.0649 447.477 -55.7331 448.898 -52.9465C450.319 -50.1599 450.929 -47.0305 450.659 -43.9142C450.389 -40.7979 449.25 -37.8199 447.371 -35.3191C445.493 -32.8182 442.95 -30.8949 440.032 -29.768C363.556 -0.191422 300.755 52.6971 258.417 123.16L256.685 126.176C255.153 128.69 254.13 131.481 253.676 134.39C253.222 137.298 253.346 140.268 254.04 143.129C254.733 145.99 255.984 148.687 257.72 151.065C259.456 153.442 261.643 155.455 264.156 156.988C266.67 158.52 269.461 159.543 272.37 159.997C275.279 160.451 278.248 160.327 281.109 159.633C283.971 158.94 286.667 157.689 289.045 155.953C291.423 154.218 293.435 152.03 294.968 149.517L296.908 146.288C301.764 138.206 307.027 130.253 312.535 122.639C315.152 119.086 319.069 116.712 323.43 116.034C327.792 115.357 332.244 116.432 335.816 119.024C339.388 121.617 341.79 125.517 342.498 129.873C343.206 134.23 342.163 138.689 339.596 142.28C334.647 149.109 329.93 156.246 325.567 163.508L323.627 166.737C319.849 173.024 314.87 178.505 308.974 182.868C303.078 187.231 296.38 190.39 289.264 192.164C282.147 193.938 274.75 194.294 267.496 193.21C260.242 192.125 253.273 189.623 246.986 185.845C240.699 182.068 235.217 177.089 230.854 171.193C226.491 165.297 223.333 158.599 221.558 151.482C219.784 144.366 219.429 136.969 220.513 129.715C221.597 122.461 224.099 115.491 227.877 109.204L229.603 106.197C275.96 29.0455 344.503 -28.6602 427.963 -60.9445C430.34 -61.868 432.898 -62.234 435.439 -62.0142C437.981 -61.7944 440.438 -60.9947 442.622 -59.6766Z"
          stroke="#2B4D70"
          strokeWidth="3"
        />
        <path
          d="M663.616 -76.6847C667.016 -74.6415 669.579 -71.4561 670.846 -67.6975C672.113 -63.939 672.003 -59.8522 670.535 -56.1676C669.066 -52.4829 666.335 -49.4405 662.83 -47.5839C659.325 -45.7273 655.274 -45.1775 651.401 -46.0328C597.999 -57.6937 542.673 -57.3352 489.427 -44.9832C487.289 -44.4857 485.073 -44.4143 482.907 -44.7731C480.74 -45.132 478.666 -45.914 476.802 -47.0745C474.938 -48.2351 473.32 -49.7514 472.042 -51.5369C470.764 -53.3225 469.85 -55.3423 469.353 -57.481C468.855 -59.6198 468.784 -61.8356 469.143 -64.0019C469.502 -66.1682 470.284 -68.2426 471.444 -70.1067C472.605 -71.9708 474.121 -73.5881 475.907 -74.8662C477.692 -76.1442 479.712 -77.0581 481.851 -77.5556C539.955 -91.0201 600.326 -91.402 658.597 -78.6735C660.368 -78.2903 662.064 -77.6184 663.616 -76.6847Z"
          stroke="#2B4D70"
          strokeWidth="3"
        />
        <path
          d="M608.872 67.0916C612.144 69.0554 614.645 72.0789 615.961 75.6602C617.277 79.2414 617.328 83.165 616.107 86.7796C614.885 90.3942 612.464 93.4823 609.246 95.5314C606.028 97.5804 602.205 98.4671 598.413 98.0442C557.454 93.499 516.048 100.729 479.053 118.886C477.082 119.852 474.939 120.421 472.749 120.559C470.558 120.697 468.361 120.403 466.284 119.692C464.207 118.981 462.29 117.868 460.644 116.417C458.997 114.966 457.652 113.204 456.686 111.233C455.719 109.262 455.151 107.12 455.013 104.929C454.875 102.738 455.169 100.541 455.88 98.4642C456.591 96.3873 457.703 94.4706 459.155 92.8237C460.606 91.1767 462.368 89.8318 464.339 88.8657C507.04 67.9005 554.836 59.556 602.115 64.8126C604.506 65.0747 606.811 65.8522 608.872 67.0916Z"
          stroke="#2B4D70"
          strokeWidth="3"
        />
        <path
          d="M434.521 119.082C436.814 120.458 438.744 122.363 440.148 124.639C441.552 126.915 442.39 129.495 442.59 132.162C442.79 134.829 442.347 137.504 441.299 139.965C440.25 142.425 438.627 144.597 436.564 146.3C417.692 161.914 401.617 180.627 389.028 201.638C352.177 262.969 272.301 282.884 210.981 246.039C149.651 209.188 129.736 129.313 166.587 67.9821C175.24 53.6164 184.634 39.7095 194.729 26.3174C196.051 24.565 197.705 23.0902 199.597 21.977C201.488 20.8639 203.581 20.1343 205.755 19.8298C207.929 19.5254 210.141 19.6521 212.266 20.2027C214.391 20.7533 216.386 21.717 218.139 23.0389C219.891 24.3607 221.366 26.0148 222.479 27.9066C223.592 29.7984 224.322 31.891 224.626 34.0648C224.931 36.2386 224.804 38.4511 224.253 40.5759C223.703 42.7007 222.739 44.6963 221.417 46.4487C212.03 58.9059 203.3 71.8455 195.264 85.2146C188.745 96.0545 184.426 108.073 182.553 120.582C180.68 133.092 181.29 145.848 184.349 158.122C187.408 170.396 192.855 181.946 200.379 192.114C207.904 202.282 217.357 210.868 228.201 217.381C273.721 244.732 333.012 229.949 360.369 184.42C374.907 160.159 393.472 138.553 415.267 120.528C417.932 118.325 421.215 117.003 424.664 116.744C428.112 116.485 431.556 117.302 434.521 119.082Z"
          stroke="#2B4D70"
          strokeWidth="3"
        />
        <path
          d="M569.541 135.838C572.587 137.665 574.972 140.415 576.35 143.689C577.728 146.963 578.027 150.591 577.204 154.046C576.381 157.501 574.479 160.605 571.774 162.906C569.068 165.208 565.7 166.587 562.157 166.845C538.882 168.532 516.352 175.784 496.463 187.992C476.575 200.201 459.909 217.008 447.87 236.999C442.226 246.4 435.824 255.325 428.729 263.685C425.859 267.064 421.764 269.164 417.345 269.524C415.157 269.702 412.955 269.448 410.866 268.775C408.776 268.103 406.84 267.025 405.166 265.604C403.493 264.183 402.116 262.446 401.114 260.493C400.112 258.54 399.505 256.409 399.327 254.221C399.149 252.033 399.403 249.831 400.076 247.741C400.748 245.652 401.826 243.715 403.247 242.042C409.163 235.066 414.502 227.621 419.211 219.779C434.013 195.193 454.505 174.523 478.962 159.509C503.419 144.495 531.126 135.577 559.749 133.506C563.175 133.256 566.595 134.071 569.541 135.838Z"
          stroke="#2B4D70"
          strokeWidth="3"
        />
        <path
          d="M382.585 274.999C384.829 276.341 386.722 278.196 388.109 280.412C389.282 282.268 390.077 284.336 390.449 286.5C390.821 288.663 390.762 290.878 390.277 293.019C389.792 295.16 388.889 297.184 387.621 298.975C386.352 300.767 384.743 302.29 382.885 303.459C318.298 344.145 237.565 345.018 172.184 305.733C153.57 294.571 136.892 280.461 122.8 263.953C119.982 260.573 118.611 256.217 118.984 251.832C119.358 247.447 121.445 243.386 124.794 240.531C128.143 237.675 132.482 236.255 136.871 236.579C141.26 236.903 145.344 238.945 148.237 242.262C159.987 256.015 173.89 267.772 189.404 277.074C215.959 293.132 246.468 301.462 277.499 301.128C308.53 300.793 338.852 291.807 365.054 275.18C367.672 273.527 370.698 272.634 373.794 272.602C376.89 272.57 379.934 273.4 382.585 274.999Z"
          stroke="#2B4D70"
          strokeWidth="3"
        />
        <path
          d="M124.79 27.0719C128.546 29.3326 131.263 32.9776 132.358 37.2222C133.453 41.4668 132.837 45.9716 130.643 49.7666C104.626 94.7755 100.927 148.279 120.502 196.563C122.168 200.673 122.132 205.275 120.404 209.359C118.676 213.442 115.397 216.672 111.287 218.337C107.178 220.003 102.575 219.967 98.4918 218.239C94.4084 216.511 91.1788 213.232 89.5134 209.122C66.0446 151.217 70.4816 87.031 101.692 33.0339C103.916 29.1985 107.571 26.4014 111.854 25.2558C116.137 24.1102 120.7 24.7096 124.541 26.9226L124.79 27.0719Z"
          stroke="#2B4D70"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default AdminLoginIcon;
