import React from "react";

const AdSectionIcon4 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 76 70"
      fill="none"
    >
      <mask
        id="mask0_3129_940"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="76"
        height="70"
      >
        <path d="M75.5852 0H0V70H75.5852V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3129_940)">
        <path
          d="M8.83626 0C9.55556 1.94315 10.2583 3.76116 10.9035 5.5997C12.5949 10.4185 15.9186 13.9369 19.8785 16.9593C22.6586 19.0811 25.5132 21.1139 28.1947 23.3554C29.9366 24.7771 31.3734 26.5359 32.4193 28.5263C33.4651 30.5166 34.0983 32.6977 34.2809 34.9387C34.2928 35.112 34.2938 35.2858 34.2839 35.4593C33.7826 34.2881 33.2195 33.1444 32.5971 32.033C31.0599 29.5547 28.6162 28.3344 25.8806 27.7622C23.7972 27.3725 21.6953 27.0885 19.5832 26.9113C17.1089 26.6505 14.6882 26.2441 12.4883 24.9927C12.4119 24.9601 12.3319 24.9364 12.25 24.9221C12.2195 24.9817 12.1895 25.0407 12.1584 25.1003C12.8092 25.4677 13.4333 25.8897 14.1106 26.1915C16.299 27.1641 18.6336 27.6081 20.9732 28.0395C22.7116 28.3599 24.4576 28.6793 26.1594 29.1468C30.297 30.2825 32.6521 33.0511 33.3244 37.2728C33.3573 37.6674 33.4233 38.0585 33.5216 38.4421C33.7313 39.0542 33.4375 39.2429 32.9209 39.4216C31.0025 40.1031 28.9624 40.3743 26.9326 40.2177C24.9028 40.061 22.9285 39.48 21.1374 38.5121C13.8859 34.805 9.66518 28.8655 8.43431 20.8521C7.68356 15.4373 7.62183 9.94928 8.25061 4.519C8.38326 3.19754 8.55094 1.87908 8.70511 0.56012C8.72313 0.409954 8.77419 0.259788 8.83626 0.000500554"
          fill="#00B783"
        />
        <path
          d="M30.7035 15.9732C33.7443 18.6942 34.9231 22.2697 35.4172 26.15C35.7456 29.1938 35.6951 32.2667 35.267 35.2981C35.263 35.3346 35.224 35.3677 35.1669 35.4573C34.9587 34.3761 34.7945 33.3339 34.5532 32.3103C33.476 27.7352 30.8742 24.1457 27.2672 21.2395C24.9601 19.381 22.5279 17.6716 20.2884 15.7374C16.0132 12.0484 13.0594 7.41523 11.0207 2.17593C10.9881 2.09283 10.9706 2.00474 10.9441 1.92565C11.0007 1.89462 11.0522 1.84957 11.0702 1.86008C14.2943 3.74316 17.8277 4.86641 21.3411 6.0377C25.2144 7.32863 29.0351 8.7487 32.5715 10.8285C34.4936 11.8987 36.2156 13.2938 37.6612 14.9521C40.3622 18.1846 41.099 21.9889 40.7035 26.0469C40.2801 30.4072 38.2708 34.1693 35.8822 37.7828C35.9323 37.3689 35.9823 36.9539 36.0409 36.54C36.0994 36.126 36.173 35.7111 36.2246 35.2946C36.7031 31.4503 36.8052 27.6141 35.8597 23.8184C35.1879 21.1219 34.0457 18.6747 31.8888 16.8106C31.6595 16.6104 31.4173 16.4287 31.1715 16.2515C31.0243 16.1449 30.8591 16.0633 30.7015 15.9712"
          fill="#00B783"
        />
        <path
          d="M48.0027 28.83C47.2382 29.9193 46.6693 31.1334 46.3214 32.4179C45.5205 35.4042 43.5182 37.3274 40.6361 38.768C40.5167 37.2959 40.4808 35.8182 40.5284 34.3421C40.9449 29.5508 43.1523 25.7856 47.34 23.3299C49.7311 21.9283 52.4291 21.5219 55.1526 21.4778C57.4822 21.4423 59.8148 21.631 62.1469 21.654C63.7662 21.6705 65.387 21.5744 66.8956 21.5324C65.8575 23.3499 61.3825 28.5006 59.4424 30.0208C59.3793 28.5692 59.6426 27.1086 58.8327 25.7991C58.8935 26.9092 58.8833 28.022 58.8022 29.1308C58.7209 29.6774 58.5325 30.2026 58.2476 30.6762C57.9628 31.1498 57.5871 31.5624 57.1423 31.8904C53.6445 34.5778 49.8918 36.8043 45.5991 37.9971C44.8833 38.1973 44.1545 38.3475 43.322 38.5477C43.4196 38.4006 43.4452 38.322 43.4997 38.2869C45.5195 36.9625 46.5586 35.0479 46.9971 32.7103C47.2474 31.3773 47.6744 30.0779 48.0027 28.8335"
          fill="#00B783"
        />
        <path
          d="M41.3171 40.3336C46.0143 40.3486 50.5424 40.5894 54.775 42.6557C53.6713 41.3989 52.2178 40.4998 50.6004 40.0733C49.0197 39.6153 47.3914 39.3225 45.9673 38.9986C54.5409 36.1406 61.8976 30.4702 66.8444 22.9068L67.0151 22.9979C66.8069 24.391 66.6367 25.79 66.3834 27.1741C65.7978 30.8153 64.6062 34.3327 62.858 37.5801C61.2913 40.3576 59.254 42.6592 56.2757 44.0207C52.3073 45.8347 45.1589 45.5584 41.3171 40.3336Z"
          fill="#00B783"
        />
        <path
          d="M17.138 69.9995L10.5307 66.1407L3.60649 69.3943L5.2348 61.9185L0 56.3409L7.61293 55.579L11.3015 48.8761L14.3784 55.8809L21.8932 57.3174L16.1819 62.4086L17.138 69.9995Z"
          fill="white"
        />
        <path
          d="M43.0137 69.9995L36.4064 66.1407L29.4822 69.3943L31.1105 61.9185L25.8752 56.3409L33.4882 55.579L37.1753 48.8761L40.2542 55.8809L47.769 57.3174L42.0577 62.4086L43.0137 69.9995Z"
          fill="white"
        />
        <path
          d="M70.8299 69.9995L64.2226 66.1407L57.2984 69.3943L58.9267 61.9185L53.6919 56.3409L61.3048 55.579L64.9934 48.8761L68.0703 55.8809L75.5851 57.3174L69.8723 62.4086L70.8299 69.9995Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default AdSectionIcon4;
