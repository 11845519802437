import { createSlice } from "@reduxjs/toolkit";
import {
  addAdminApi,
  editAdminApi,
  getAdminsByIdApi,
  getAdminsListApi,
} from "../Thunks/AdminsThunks";

const AdminsListSlice = createSlice({
  name: "admin",
  initialState: {
    loading: false,
    adminsList: [],
    error: null,
    getOneAdminLoading: false,
    getOneAdminError: null,
    oneAdmin: {},
    message: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminsListApi.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.adminsList = [];
    });
    builder.addCase(getAdminsListApi.fulfilled, (state, action) => {
      state.loading = false;
      state.adminsList = action.payload.admins;
      state.error = null;
    });
    builder.addCase(getAdminsListApi.rejected, (state, action) => {
      state.loading = false;
      state.adminsList = [];
      state.error = action.payload.error;
    });
    builder.addCase(getAdminsByIdApi.pending, (state) => {
      state.getOneAdminLoading = true;
      state.getOneAdminError = null;
    });
    builder.addCase(getAdminsByIdApi.fulfilled, (state, action) => {
      state.getOneAdminLoading = false;
      state.getOneAdminError = null;
      state.oneAdmin = action.payload.admin;
    });
    builder.addCase(getAdminsByIdApi.rejected, (state, action) => {
      state.getOneAdminLoading = false;
      state.getOneAdminError = action?.payload?.error;
      state.oneAdmin = {};
    });

    builder.addCase(editAdminApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editAdminApi.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action?.payload?.message;
    });
    builder.addCase(editAdminApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(addAdminApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAdminApi.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action?.payload?.message;
    });
    builder.addCase(addAdminApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });
  },
});

export default AdminsListSlice;
