import React from "react";

const BurgerIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="32"
        y="32"
        width="32"
        height="32"
        rx="6.4"
        transform="rotate(-180 32 32)"
        fill="#E4E4E4"
        fillOpacity="0.7"
      />
      <path
        d="M23.3601 21.12L8.64011 21.12"
        stroke="#2B4D70"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M23.3601 16L8.64011 16"
        stroke="#2B4D70"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M23.3601 10.88L8.64011 10.88"
        stroke="#2B4D70"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BurgerIcon;
