import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCurrentWidth } from "react-socks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   // responsive: true,
//   aspectRatio: 2.2,
//   plugins: {
//     legend: {
//       display: false,
//       //   position: "top",
//     },
//   },

//   scales: {
//     x: {
//       grid: {
//         display: false,
//       },
//       ticks: {
//         font: {
//           size: 16,
//           weight: 400,
//         },
//         color: "#9FA1A2",
//       },
//     },
//     y: {
//       max: 70,
//       display: true,
//       ticks: {
//         callback: function (value, index, ticks) {
//           return value + "K";
//         },
//         major: true,
//         color: "#9FA1A2",
//         padding: 13,
//         font: {
//           size: 15,
//           weight: 400,
//           family: "Inter",
//         },
//       },
//     },
//   },
// };

export function VBarChart({ data }) {
  const width = useCurrentWidth();
  const options = {
    // responsive: true,
    aspectRatio: width < 768 ? 1 : 2.2,
    plugins: {
      legend: {
        display: false,
        //   position: "top",
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: width < 768 ? 12 : 16,
            weight: 400,
          },
          color: "#9FA1A2",
        },
      },
      y: {
        // max: 70,
        display: true,
        ticks: {
          callback: function (value, index, ticks) {
            return value + "K";
          },
          major: true,
          color: "#9FA1A2",
          padding: 13,
          font: {
            size: width < 768 ? 10 : 15,
            weight: 400,
            family: "Inter",
          },
        },
      },
    },
  };

  return <Bar options={options} data={data} height={width < 768 && 220} />;
}
