import React, { useEffect, useState } from "react";
import Logo from "../../Icons/Logo";
import Management from "../../Icons/Management";
import Reward2 from "../../Icons/Reward2";
import NotificationIcon from "../../Icons/NotificationIcon";
import { useCurrentWidth } from "react-socks";
import { Popover } from "react-tiny-popover";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/Slices/AuthSlice";
import { Link } from "react-router-dom";

const navLinks = [
  {
    id: 1,
    title: "Users Management",
    icon: <Management />,
  },
  {
    id: 2,
    title: "Rewards Management",
    icon: <Reward2 />,
  },
  // {
  //   id: 3,
  //   title: "",
  //   icon: (
  //     <div className="sm:mx-2 xs:mx-2 md:mx-0 ">
  //       <NotificationIcon />
  //     </div>
  //   ),
  // },
];

const Header = ({ user }) => {
  const [isSliding, setIsSliding] = useState(true);
  const width = useCurrentWidth();
  const [startTransition, setStartTransition] = useState(false);
  // const [showDropDown, setShowDropDown] = useState(false);
  const dispatch = useDispatch();

  const handleDivClick = () => {
    setIsSliding(!isSliding);
  };
  // const toggleMenu = () => {
  //   setShowDropDown(!showDropDown);
  // };

  useEffect(() => {
    if (width < 568) {
      setIsSliding(false);
    }
  }, [width]);
  useEffect(() => {
    if (isSliding) {
      setTimeout(() => {
        setStartTransition(true);
      }, 10);
    } else {
      setStartTransition(false);
    }
  }, [isSliding, width]);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div className="flex w-[100%] h-[80px] justify-center bg-white">
      <div
        className="flex w-full xs:px-[8px] lg:px-[48px] sm:px-[10px] md:px-[14px] justify-between items-center"
        style={{
          width: width > 1440 ? "1440px" : "100%",
        }}
      >
        <Logo />
        <div
          className={`flex xs:w-[60%] lg:w-[50%] sm:w-[70%] md:w-[70%] xs:justify-end sm:justify-end ${
            user ? "justify-end" : "justify-center"
          }  items-center`}
        >
          {!user ? (
            <div className="flex items-center ">
              {navLinks.map((item, index) => (
                <Link key={item.id} to={item.id === 2 ? "/rewards" : "/admins-list"}>
                  <div className="flex " key={item.id}>
                    <div className="flex mx-[16px] font-inter xs:text-[10px] text-[#1C1C1C] lg:text-[14px] sm:text-[14px] md:text-[14px] font-medium">
                      {item.icon}
                      <span className="mx-2 xs:hidden sm:hidden md:flex">
                        {item.title}
                      </span>
                    </div>
                    {/* {index === 1 && width > 690 && (
                  <div className="w-[1px] h-[24px] bg-[#E7ECF0]" />
                )} */}
                  </div>
                </Link>
              ))}
              <Popover
                isOpen={isPopoverOpen}
                positions={["top", "bottom", "left", "right"]}
                onClickOutside={() => setIsPopoverOpen(false)}
                content={({ position, childRect, popoverRect }) => (
                  <div className="flex flex-col justify-around items-start rounded-[10px] w-[200px] bg-white border-[1px] py-2 border-[#E7ECF0]  absolute top-14 right-3 z-10">
                    <div className="py-2 px-3 hover:bg-[#F6F6F6] w-full">
                      Account Details
                    </div>
                    <div className="py-2 px-3 hover:bg-[#F6F6F6] w-full">
                      Switch Account
                    </div>
                    <div className="w-full h-[1px] bg-[#E7ECF0]" />
                    <button
                      className="py-2 px-3 hover:bg-[#F6F6F6] w-full text-left"
                      onClick={() => dispatch(logout())}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              >
                <div
                  className="flex justify-center items-center h-[40px] w-[40px] rounded-full bg-[#2B4D70] text-white text-[18px] cursor-pointer"
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  U
                </div>
              </Popover>
            </div>
          ) : (
            <div className="w-[350px] self-end flex justify-between items-center">
              <div
                className="w-[220px] h-[40px] flex relative justify-end"
                style={
                  {
                    // justifyContent: width < 560 && "end",
                  }
                }
              >
                <div
                  className={`absolute top-0 left-0 w-[190px] h-[40px] rounded-3xl flex items-center xs:px-2 sm:px-2 md:px-3 justify-start bg-[#00B783] font-bold text-white text-[16px] transition-opacity duration-2000 ${
                    startTransition
                      ? `${
                          width < 560
                            ? " transform translate-x-[81px]"
                            : " transform translate-x-7"
                        }`
                      : "transform translate-x-full invisible"
                  }`}
                  style={{
                    width: width < 560 && "140px",
                    fontSize: width < 560 && "12px",
                  }}
                >
                  200 Green Points
                </div>
                <div
                  className="flex justify-center items-center rounded-full z-10 bg-white border-[1px] border-[#2B4D70] h-[40px] w-[40px] cursor-pointer"
                  onClick={handleDivClick}
                >
                  <Reward2 />
                </div>
              </div>
              <div className="w-[1px] h-[24px] bg-[#E7ECF0]" />
              <div className="sm:mx-2 xs:mx-2 md:mx-0 ">
                <NotificationIcon />
              </div>

              <Popover
                isOpen={isPopoverOpen}
                positions={["top", "bottom", "left", "right"]}
                onClickOutside={() => setIsPopoverOpen(false)}
                content={({ position, childRect, popoverRect }) => (
                  <div className="flex flex-col justify-around items-start rounded-[10px] w-[200px] bg-white border-[1px] py-2 border-[#E7ECF0]  absolute top-14 right-3 z-10">
                    <div className="py-2 px-3 hover:bg-[#F6F6F6] w-full">
                      Account Details
                    </div>
                    <div className="py-2 px-3 hover:bg-[#F6F6F6] w-full">
                      Switch Account
                    </div>
                    <div className="w-full h-[1px] bg-[#E7ECF0]" />
                    <div className="py-2 px-3 hover:bg-[#F6F6F6] w-full">
                      Log Out
                    </div>
                  </div>
                )}
              >
                <div
                  className="flex justify-center items-center h-[40px] w-[40px] rounded-full bg-[#2B4D70] text-white text-[18px] cursor-pointer"
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  U
                </div>
              </Popover>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
