import { configureStore } from "@reduxjs/toolkit";
import { AdminDashboardSlice } from "./Slices/AdminDashboardSlice";
import authSlice from "./Slices/AuthSlice";
import AdminsListSlice from "./Slices/AdminsSlice";
import RewardsSlice from "./Slices/RewardsSlice";

const store = configureStore({
  reducer: {
    adminData: AdminDashboardSlice.reducer,
    adminLogin: authSlice.reducer,
    adminsList: AdminsListSlice.reducer,
    rewards: RewardsSlice.reducer,
  },
});

export default store;
