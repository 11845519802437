import React from "react";
import { useLanguage } from "../../../Contexts/LanguageContext";
import Modal from "./Modal";
import LandingLogo from "../icons/LandingLogo";

const FailureModal = ({ setFailModal, failModal }) => {
  const { language } = useLanguage();

  const handleCloseFailure = () => {
    setFailModal(false);
  };

  return (
    <Modal
      isOpen={failModal}
      onClose={handleCloseFailure}
      // title={<LandingLogo width={182} height={64} />}
      height={355}
      innerHeight={200}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          color: "#2B4D70",
          fontSize: 24,
          padding: 32,
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        {language === "en" ? (
          <span
            style={{
              direction: "ltr",
              fontFamily: "Inter",
            }}
          >
            User cancelled the login
          </span>
        ) : (
          <span
            style={{
              direction: "rtl",
              fontFamily: "cairo",
            }}
          >
            قام المستخدم بإلغاء تسجيل الدخول
          </span>
        )}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <button
          style={{
            width: 118,
            height: 56,
            backgroundColor: "#00B783",
            color: "white",
            borderRadius: 8,
            alignSelf: "center",
          }}
          onClick={handleCloseFailure}
        >
          {language === "en" ? "Close" : "إغلاق"}
        </button>
      </div>
    </Modal>
  );
};

export default FailureModal;
