import React, { useEffect, useState } from "react";
import { useCurrentWidth } from "react-socks";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RewardsProvider from "../../Components/RewardsProvider/RewardsProvider";
import AddNewRewardProviderModal from "../../Components/AddNewRewardProvider/AddNewRewardProvider";
import EditRewardsProviderModal from "../../Components/EditRewardsProviderModal/EditRewardsProviderModal";
import RewardRules from "../../Components/RewardRules/RewardRules";
import EditRewardsRuleModal from "../../Components/EditRewardRulesModal/EditRewardRulesModal";
import AddNewRewardRuleModal from "../../Components/AddNewRewardRule/AddNewRewardRule";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../Icons/BackArrow";
import Groups from "../../Icons/Groups";

const RewardManeger = () => {
  const width = useCurrentWidth();
  const [rewardProviderOpen, setRewardProviderOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);

  const [rewardRuleOpen, setRewardRuleOpen] = useState(false);
  const [editRuleModalOpen, setEditRuleModalOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const { admin } = useSelector((state) => state.adminLogin);
  let navigate = useNavigate();

  useEffect(() => {
    if (admin) {
      navigate("/rewards");
    } else {
      navigate("/admin/login");
    }
  }, [navigate, admin]);

  return (
    <div
      style={{
        paddingBottom: width <= 1300 || width > 1440 ? 20 : 0,
        overflowX: width <= 1300 ? "hidden" : "auto",
        height: "100%",
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "auto",
      }}
    >
      <div className="w-full h-[100px] flex items-center xs:px-[24px] sm:px-[24px] md:px-[24px] lg:px-[48px] justify-between">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/admin")}
        >
          <BackArrow />
          <span className="mx-2 font-inter sm:text-[12px] xs:text-[12px] md:text-[16px] text-[#1C1C1C] font-medium">
            Home
          </span>
        </div>
        <div className="flex items-center">
          <Groups />
          <span className="mx-2 font-inter sm:text-[12px] xs:text-[12px] md:text-[16px] text-[#1C1C1C] font-medium">
            700,000 Accounts
          </span>
        </div>
      </div>
      <div className="w-full flex justify-center items-center xs:px-[24px] sm:px-[24px] md:px-[24px] lg:px-[48px]">
        <Tabs className="w-[100%] bg-white py-2 ">
          <TabList className="w-[100%] xs:px-[24px] sm:px-[24px] md:px-[24px] lg:px-[20px]  ">
            <Tab>Rewards Providers</Tab>
            <Tab>Rewards Rules</Tab>
          </TabList>

          <TabPanel
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <RewardsProvider
              openModal={() => setRewardProviderOpen(true)}
              setSelectedReward={setSelectedReward}
              setEditModalOpen={setEditModalOpen}
            />
            <AddNewRewardProviderModal
              isModalOpen={rewardProviderOpen}
              closeModal={() => setRewardProviderOpen(false)}
            />
            <EditRewardsProviderModal
              isModalOpen={editModalOpen}
              closeModal={() => setEditModalOpen(false)}
              selectedRewardToEdit={selectedReward}
            />
          </TabPanel>
          <TabPanel
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <RewardRules
              openModal={() => setRewardRuleOpen(true)}
              setSelectedReward={setSelectedRule}
              setEditModalOpen={setEditRuleModalOpen}
            />
            <EditRewardsRuleModal
              isModalOpen={editRuleModalOpen}
              closeModal={() => setEditRuleModalOpen(false)}
              selectedRewardToEdit={selectedRule}
            />

            <AddNewRewardRuleModal
              isModalOpen={rewardRuleOpen}
              closeModal={() => setRewardRuleOpen(false)}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default RewardManeger;
