import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminDataInstance from "../../api/Services/adminDataService";
import {
  getCurrentAndPreviousMonth,
  getLastThreeMonths,
  monthToNumber,
} from "../../Constants/months";

export const getEmissionDatabyDate = createAsyncThunk(
  "admin/getEmissionByDate",
  async ({ startMonth, startYear, endMonth, endYear }, { rejectWithValue }) => {
    try {
      const response = await AdminDataInstance.getEmissionsByDateRange({
        startMonth,
        startYear,
        endMonth,
        endYear,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTotalEmmissionsByDate = createAsyncThunk(
  "admin/getTotalEmmissionsByDate",
  async ({ startMonth, startYear, endMonth, endYear }, { rejectWithValue }) => {
    try {
      const response = await AdminDataInstance.getTotalEmissionsByDateRange({
        startMonth,
        startYear,
        endMonth,
        endYear,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getLastYearData = createAsyncThunk(
  "admin/getLastYearData",
  async (_, { rejectWithValue }) => {
    try {
      const { currentMonth, previousMonth, currentYear, preYear } =
        getCurrentAndPreviousMonth();

      const response = await AdminDataInstance.getEmissionsByDateRange({
        startMonth: monthToNumber(previousMonth),
        startYear: preYear,
        endMonth: monthToNumber(currentMonth),
        endYear: currentYear,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTotalLast3Months = createAsyncThunk(
  "admin/getTotalLast3Months",
  async (_, { rejectWithValue }) => {
    try {
      const { currentMonth, previousMonth, currentYear, preYear } =
        getLastThreeMonths();

      const response = await AdminDataInstance.getEmissionsByDateRange({
        startMonth: monthToNumber(previousMonth),
        startYear: preYear,
        endMonth: monthToNumber(currentMonth),
        endYear: currentYear,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
