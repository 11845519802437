import React from "react";

const ElectrsityIcon = () => {
  return (
    <svg
      width="10"
      height="19"
      viewBox="0 0 10 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.21125 0.859039H3.17218L1.30484 9.99712H3.80788L0.78833 18.1419L9.21125 9.00382H6.02414L9.21125 0.859039Z"
        fill="#00B783"
      />
      <path
        d="M0.788373 18.5002C0.724046 18.5002 0.659217 18.4829 0.601166 18.4473C0.455158 18.3578 0.392803 18.1775 0.452325 18.0169L3.2927 10.3553H1.30499C1.19732 10.3553 1.0953 10.3069 1.02721 10.2235C0.959121 10.1401 0.932122 10.0305 0.953671 9.925L2.82108 0.786949C2.85518 0.620036 3.00201 0.500168 3.17237 0.500168H9.21144C9.32995 0.500168 9.44078 0.558722 9.50762 0.656574C9.57442 0.754463 9.58854 0.879028 9.54537 0.989359L6.5497 8.64495H9.21144C9.35383 8.64495 9.48269 8.72922 9.53985 8.85959C9.59701 8.98996 9.57158 9.14185 9.47506 9.24652L1.05221 18.3846C0.982249 18.4605 0.885867 18.5002 0.788373 18.5002ZM1.74427 9.63825H3.80807C3.92543 9.63825 4.03536 9.69569 4.10238 9.79204C4.16943 9.88839 4.18507 10.0114 4.14426 10.1215L1.75632 16.5626L8.39326 9.36212H6.02433C5.90582 9.36212 5.79499 9.30357 5.72815 9.20571C5.66135 9.10783 5.64722 8.98326 5.6904 8.87293L8.6861 1.2173H3.46507L1.74427 9.63825Z"
        fill="#00B783"
      />
      <path
        d="M3.46542 6.85946C3.43982 6.85946 3.41382 6.8567 3.38776 6.85097C3.19438 6.80826 3.07225 6.61689 3.11496 6.42352L4.0539 2.17229C4.0966 1.97888 4.2879 1.85683 4.48135 1.8995C4.67472 1.9422 4.79685 2.13357 4.75414 2.32694L3.8152 6.57817C3.77827 6.74548 3.63 6.85946 3.46542 6.85946Z"
        fill="white"
      />
      <path
        d="M2.97397 9.08417C2.94836 9.08417 2.92237 9.08141 2.8963 9.07567C2.70293 9.033 2.5808 8.8416 2.6235 8.64823L2.77267 7.97276C2.81537 7.77935 3.00674 7.65722 3.20011 7.69996C3.39349 7.74263 3.51562 7.93403 3.47291 8.12741L3.32375 8.80287C3.28681 8.97018 3.13855 9.08417 2.97397 9.08417Z"
        fill="white"
      />
    </svg>
  );
};

export default ElectrsityIcon;
