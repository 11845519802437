import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import adminLoginInstance from "../../api/Services/adminLogin";
import { toast } from "react-toastify";

const storedAdmin = localStorage.getItem("uaeeiAdmin");
const initialAdmin = storedAdmin ? JSON.parse(storedAdmin) : null;

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  error: null,
  admin: initialAdmin,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    logout(state) {
      localStorage.removeItem("uaeeiAdmin");
      state.isLoggedIn = false;
      state.admin = null;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    checkLocalStorage: (state) => {
      const storedAdmin = localStorage.getItem("uaeeiAdmin");

      if (storedAdmin) {
        state.admin = JSON.parse(storedAdmin);
      }
    },
  },
});

export default authSlice;

export const {
  setIsLoggedIn,
  setLoading,
  loginFailure,
  checkLocalStorage,
  logout,
} = authSlice.actions;

export const loginAdmin = createAsyncThunk(
  "auth/loginAdmin",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const res = await adminLoginInstance.loginAdmin({ data });
      if (res.data.success) {
        localStorage.setItem("uaeeiAdmin", JSON.stringify(res.data.success));
        dispatch(setIsLoggedIn(true));
      }
      dispatch(setLoading(false));
      return Promise.resolve(res);
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
      dispatch(loginFailure(err.response.data));
      return rejectWithValue(err.response.data);
    }
  }
);
