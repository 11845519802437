import { ApiUrlConstants } from "../constants";
import http from "../http";

class Rewards {
  getRewardsProviderList() {
    return http.get(ApiUrlConstants.rewardsProviderList);
  }
  getRewardProviderById({ id }) {
    return http.get(ApiUrlConstants.rewardsProviderList, {
      params: { id },
    });
  }
  editRewardProvider({ data }) {
    return http.post(ApiUrlConstants.editRewardProviderList, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  addRewardProvider({ data }) {
    return http.post(ApiUrlConstants.addRewardProviderList, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getRewardsRuleList() {
    return http.get(ApiUrlConstants.rewardsRulesList);
  }
  getRewardRuleById({ id }) {
    return http.get(ApiUrlConstants.rewardsRulesList, {
      params: { id },
    });
  }
  editRewardRule({ data }) {
    return http.post(ApiUrlConstants.editRewardRulesList, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  addRewardRule({ data }) {
    return http.post(ApiUrlConstants.addRewardRulesList, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const RewardsInstance = new Rewards();
export default RewardsInstance;
