import React from "react";
import { useLanguage } from "../../../Contexts/LanguageContext";
import { useCurrentWidth } from "react-socks";
import Initiative1 from "../icons/Initiative1";
import Initiative2 from "../icons/Initiative2";

const Partners = () => {
  const { language, style } = useLanguage();
  const width = useCurrentWidth();

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "white",
        fontFamily: "Inter",
        width: width <= 1024 ? "100%" : "50%",
        height: "100%",
        padding: width <= 1024 ? "10px 16px" : "40px 16px",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {language === "en" ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: width > 1025 ? 45 : 30,
              fontWeight: 500,
              color: "#2B4D70",
            }}
          >
            AN INITIATIVE BY
          </span>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 50,
              marginTop: 10,
            }}
          >
            <Initiative1
              width={width <= 1024 ? 120 : 240}
              height={width <= 1024 ? 90 : 188}
            />
            <Initiative2
              width={width <= 1024 ? 77 : 280}
              height={width <= 1024 ? 35 : 117}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: width > 1025 ? 45 : 30,
                fontWeight: 500,
                color: "#2B4D70",
                fontFamily: language === "en" ? "Inter" : "cairo",
              }}
            >
              مبادرة من
            </span>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "center",
              alignItems: "center",
              gap: 50,
              marginTop: 10,
            }}
          >
            <Initiative1
              width={width <= 1024 ? 120 : 240}
              height={width <= 1024 ? 90 : 188}
            />
            <Initiative2
              width={width <= 1024 ? 77 : 280}
              height={width <= 1024 ? 35 : 117}
            />{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default Partners;
