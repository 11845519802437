import React, { useEffect, useState } from "react";
import { getMonthName, getStartAndEndMonths } from "../../Constants/months";
import Select, { components } from "react-select";
import Groups from "../../Icons/Groups";
import Card from "../../Components/Card/Card";
import HBarChart from "../../Components/HBarChart/HBarChart";
import DoughnutChart, {
  chartItems,
} from "../../Components/DoughnutChart/DoughnutChart";
import LineChart from "../../Components/LineChart/LineChart";
import ZoomableSVG from "../../Components/TestMapsWidthDots/TestZoom";
import DownArrow from "../../Icons/DownArrow";
import TotalIcon from "../../Icons/TotalIcon";
import WaterIcon from "../../Icons/WaterIcon";
import ElectrsityIcon from "../../Icons/ElectrsityIcon";
import GasIcon from "../../Icons/GasIcon";
import DGasIcon from "../../Icons/DGasIcon";
import WasteIcon from "../../Icons/WasteIcon";
import { useCurrentWidth } from "react-socks";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmissionDatabyDate,
  getLastYearData,
  getTotalEmmissionsByDate,
} from "../../redux/Thunks/AdminDashboardThunk";
import { calculateTotalsForAllMonths } from "../../Constants/calculateTotalValues";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import uaeSpinner from "../../Icons/animation/spinner.json";
import { setPeriod } from "../../redux/Slices/AdminDashboardSlice";

const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <div className="flex items-center mr-1">
    <div className="flex items-center mr-1">{customAbbreviation}</div>
    <div className="text-[12px]">{label}</div>
  </div>
);

const mapIndicator = [
  {
    id: 4,
    title: "Very High",
    value: 20,
  },
  {
    id: 3,
    title: "High",
    value: 15,
  },
  {
    id: 2,
    title: "Mid",
    value: 12,
  },
  {
    id: 1,
    title: "Low",
    value: 8,
  },
];

const mapOptions = [
  {
    value: "total",
    label: "Total",
    customAbbreviation: <TotalIcon />,
    color: "#B22222",
  },
  {
    value: "water",
    label: "Water",
    customAbbreviation: <WaterIcon />,
    color: "#2B4D70",
  },
  {
    value: "electricity",
    label: "Electricity",
    customAbbreviation: <ElectrsityIcon />,
    color: "#00B783",
  },
  {
    value: "Fuel",
    label: "Fuel",
    customAbbreviation: <GasIcon />,
    disabled: true,
    color: "#D19631",
  },
  {
    value: "Domestic gas",
    label: "Domestic gas",
    customAbbreviation: <DGasIcon />,
    disabled: true,
    color: "#3C8DB9",
  },
  {
    value: "waste",
    label: "Waste",
    customAbbreviation: <WasteIcon />,
    disabled: true,
    color: "#A68A64",
  },
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownArrow />
    </components.DropdownIndicator>
  );
};

const Home = () => {
  const [chartData, setChartData] = useState();
  const [selectValue, setSelectValue] = useState(mapOptions[0]);
  const width = useCurrentWidth();
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.adminLogin);
  let navigate = useNavigate();

  const {
    totalDataByMonth,
    dataByDateRagne,
    lastYearData,
    loading,
    periodsOptions,
    selectedPeriod,
  } = useSelector((state) => state.adminData);

  useEffect(() => {
    const { startMonth, endMonth, startYear, endYear } =
      getStartAndEndMonths(selectedPeriod);
    dispatch(
      getTotalEmmissionsByDate({
        startMonth,
        endMonth,
        startYear,
        endYear,
      })
    );
    dispatch(
      getEmissionDatabyDate({
        startMonth,
        endMonth,
        startYear,
        endYear,
      })
    );
  }, [dispatch, selectedPeriod]);

  useEffect(() => {
    if (admin) {
      navigate("/admin");
    } else {
      navigate("/admin/login");
    }
  }, [navigate, admin]);

  useEffect(() => {
    dispatch(getLastYearData());
  }, [dispatch]);

  useEffect(() => {
    const testedDataForOneYear = calculateTotalsForAllMonths(
      lastYearData?.emissions_data
    );
    const labels = testedDataForOneYear?.map((item) =>
      getMonthName(item?.month)
    );
    console.log(testedDataForOneYear);
    const waterData = testedDataForOneYear?.map(
      (item) => item?.totalWater / 1000
    );
    const electricityData = testedDataForOneYear?.map(
      (item) => item?.totalElectricity / 1000
    );

    const finalLineChartData = {
      labels,
      datasets: [
        {
          label: "water",
          data: waterData,
          borderColor: "#2B4D70",
          backgroundColor: "#2B4D70",
          pointRadius: 4,
          pointHoverRadius: 5,
          hitRadius: 2,
          cursor: "pointer",
          borderWidth: 1.5,
        },
        {
          label: "Electricity",
          data: electricityData,
          borderColor: "#00B783",
          backgroundColor: "#00B783",
          pointRadius: 4,
          pointHoverRadius: 5,
          hitRadius: 2,
          borderWidth: 1.5,
        },
        {
          label: "Fuel",
        },
        {
          label: "Domestic gas",
        },
        {
          label: "Waste",
        },
      ],
    };
    setChartData(finalLineChartData);
  }, [lastYearData?.emissions_data]);

  const toggleData = (dataIndex) => {
    const updatedData = {
      ...chartData,
      datasets: chartData.datasets.map((dataset, i) => {
        if (i === dataIndex) {
          return {
            ...dataset,
            hidden: !dataset?.hidden,
          };
        }
        return dataset;
      }),
    };

    setChartData(updatedData);
  };

  if (loading)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Lottie animationData={uaeSpinner} loop />
      </div>
    );

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>UAEEI | Dashboard</title>
        </Helmet>
      </div>
      <div
        style={{
          paddingBottom: width <= 1300 || width > 1440 ? 20 : 0,
          overflowX: width <= 1300 ? "hidden" : "auto",
          height: "100%",
          width: "100%",
          maxWidth: "1440px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <div className="w-full h-[100px] flex items-center xs:px-[24px] sm:px-[24px] md:px-[24px] lg:px-[48px] justify-between">
          <Select
            options={periodsOptions}
            components={{ DropdownIndicator }}
            defaultValue={selectedPeriod}
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                width: width < 768 ? "120px" : "195px",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: width < 768 ? "120px" : "195px",
                borderRadius: "8px",
                fontSize: "16px",
                color: "#1C1C1C",
                fontWeight: "500",
                border: state.isFocused ? "1px solid #C1C1C1" : "",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid black",
                },
              }),
              indicatorSeparator: () => {},
              DropdownIndicator: () => {},
              option: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "16px",
                fontWeight: "500",
                color: state.isDisabled ? "#9FA1A2" : "#1C1C1C",
                backgroundColor: state.isFocused ? "#F6F6F6" : "",
              }),
              SingleValueLabel: (base) => ({
                ...base,
                fontSize: "16px",
                fontWeight: "500",
                color: "#1C1C1C",
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                fontSize: "16px",
                fontWeight: "500",
                color: "#1C1C1C",
              }),
              Input: (baseStyles) => ({
                ...baseStyles,
                fontSize: "16px",
                fontWeight: "500",
                color: "transparent",
              }),
            }}
            classNamePrefix="select"
            onChange={(option) => dispatch(setPeriod(option))}
          />
          <div className="flex items-center">
            <Groups />
            <span className="mx-2 font-inter sm:text-[12px] xs:text-[12px] md:text-[16px] text-[#1C1C1C] font-medium">
              700,000 Accounts
            </span>
          </div>
        </div>
        <div className="flex w-full flex-col gap-x-[25px] gap-y-[25px] xs:px-[24px] sm:px-[24px] md:px-[24px] lg:px-[48px]">
          <div className="flex xs:flex-col lg:flex-row sm:flex-col laptop:flex-row gap-6 flex-row w-full">
            <div className="xs:w-[100%] sm:w-[100%] md:w-[100%] laptop:w-[50%] lg:w-[60%]">
              <Card height="402px" title="CO₂ Emissions Heat Map">
                <div className="flex self-start">
                  <Select
                    isOptionDisabled={(option) => option.disabled}
                    components={{ DropdownIndicator }}
                    onChange={(option) => setSelectValue(option)}
                    defaultValue={{
                      value: "total",
                      label: "Total",
                      customAbbreviation: <TotalIcon />,
                    }}
                    formatOptionLabel={formatOptionLabel}
                    options={mapOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        width: "132px",
                        border: "none",
                        fontSize: "16px",
                        color: "#1C1C1C",
                        fontWeight: "500",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid black",
                        },
                      }),
                      container: (baseStyles) => ({
                        ...baseStyles,
                        width: "fit-content",
                      }),
                      indicatorSeparator: () => {},
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: state.isDisabled ? "#9FA1A2" : "#1C1C1C",
                        backgroundColor: state.isFocused ? "#F6F6F6" : "",
                      }),
                      SingleValueLabel: (base) => ({
                        ...base,
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#1C1C1C",
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        width: "max-content",
                      }),
                      Input: (baseStyles) => ({
                        ...baseStyles,
                        color: "transparent",
                      }),
                    }}
                    classNamePrefix="select"
                  />
                </div>
                <ZoomableSVG
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                  options={mapOptions}
                  dataByDateRagne={dataByDateRagne}
                />
                <div className="flex w-full justify-center">
                  {mapIndicator.map((item) => (
                    <div key={item.id} className="flex items-center mx-3">
                      <div className="mr-1">
                        <div
                          // className={`w-[${item.value}px] h-[${item.value}px] bg-[${selectValue.color}] rounded-full`}
                          style={{
                            width: width <= 768 ? item.value / 2 : item.value,
                            height: width <= 768 ? item.value / 2 : item.value,
                            backgroundColor: selectValue.color,
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <span className="font-inter xs:text-[12px] sm:text-[12px]">
                        {item.title}
                      </span>
                    </div>
                  ))}
                </div>
              </Card>
            </div>
            <div className="xs:w-[100%] sm:w-[100%] md:w-[100%] laptop:w-[50%] lg:w-[40%]">
              <Card
                height={width < 768 ? "300px" : "402px"}
                title="CO₂ Emissions per Emirates"
              >
                {totalDataByMonth &&
                Object.keys(totalDataByMonth.emissions_data)?.length > 0 ? (
                  <HBarChart totalDataByMonth={totalDataByMonth} />
                ) : (
                  <div className="w-full h-full flex justify-center items-center">
                    <span className="sm:text-base xs:text-sm md:text-2xl text-center">
                      Sorry, there is no data to display <br /> this month
                    </span>
                  </div>
                )}
              </Card>
            </div>
          </div>
          <div className="flex xs:flex-col lg:flex-row sm:flex-col laptop:flex-row gap-6 flex-row w-full">
            <div className="xs:w-[100%] sm:w-[100%] md:w-[100%] laptop:w-[50%] lg:w-[60%]">
              <Card
                height={width < 768 ? "320px" : "402px"}
                title="UAE CO₂ Emissions by Period"
                options={
                  width >= 1440 || width < 1024 ? (
                    <div className="flex xs:justify-between sm:justify-between md:justify-around lg:justify-around  xs:w-[100%] lg:w-[55%] sm:w-[1000%] md:w-[60%] items-center">
                      {chartItems?.map((option, i) => (
                        <div
                          key={option.id}
                          className="flex items-center mx-1 cursor-pointer"
                          onClick={() => {
                            if (option?.disabled) {
                              return;
                            }
                            toggleData(i);
                          }}
                        >
                          <div
                            className={`w-[10px] h-[10px] mr-1 rounded-full`}
                            style={{ backgroundColor: option.color }}
                          />
                          <span
                            className="font-inter xs:text-[8px] lg:text-[16px] sm:text-[12px] md:text-[14px] font-normal"
                            style={{
                              textDecoration: chartData?.datasets[i].hidden
                                ? "line-through"
                                : "none",
                              color: option.disabled ? "#9FA1A2" : "#1C1C1C",
                            }}
                          >
                            {option.title}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : null
                }
              >
                {width >= 1024 && width < 1440 ? (
                  <div className="flex justify-between w-full">
                    {chartItems?.map((option, i) => (
                      <div
                        key={option.id}
                        className="flex items-center mx-1 cursor-pointer"
                        onClick={() => {
                          if (option?.disabled) {
                            return;
                          }
                          toggleData(i);
                        }}
                      >
                        <div
                          className={`w-[10px] h-[10px] mr-1 rounded-full`}
                          style={{ backgroundColor: option.color }}
                        />
                        <span
                          className="font-inter xs:text-[8px] lg:text-[16px] sm:text-[12px] md:text-[14px] font-normal"
                          style={{
                            textDecoration: chartData?.datasets[i].hidden
                              ? "line-through"
                              : "none",
                            color: option.disabled ? "#9FA1A2" : "#1C1C1C",
                          }}
                        >
                          {option.title}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : null}
                {chartData?.labels && chartData?.datasets && (
                  <LineChart data={chartData} />
                )}
              </Card>
            </div>
            <div className="xs:w-[100%] sm:w-[100%] md:w-[100%] laptop:w-[50%] lg:w-[40%]">
              <Card
                height={width < 768 ? "400px" : "402px"}
                title="UAE Total CO₂ Emissions"
              >
                {dataByDateRagne &&
                Object.keys(dataByDateRagne?.emissions_data)?.length > 0 ? (
                  <DoughnutChart dataByDateRagne={dataByDateRagne} />
                ) : (
                  <div className="w-full h-full flex justify-center items-center">
                    <span className="sm:text-base xs:text-sm md:text-2xl text-center">
                      Sorry, there is no data to display <br /> this month
                    </span>
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
