export const objectives = [
  {
    id: 1,
    arBody: "تضمين القطاعات السكنية والتجارية والصناعية.",
    enBody: "Including residential, commercial, Industrial sector  ",
    image: "./images/objective-1.png",
  },
  {
    id: 2,
    arBody: "خفض الانبعاثات الكربونية في العالم إلى 43٪ بحلول عام 2030.",
    enBody: "Reducing global emissions by 43% by 2030",
    image: "./images/objective-2.png",
  },
];
