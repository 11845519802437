import React from "react";

const ModalTitleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="48"
      viewBox="0 0 43 48"
      fill="none"
    >
      <path
        d="M12.4625 41.0902C12.7702 41.073 13.0873 41.0511 13.3904 41.0242L13.741 41.0008C19.7028 40.4543 25.3828 38.1556 30.0963 34.3819C34.8097 30.6082 38.3554 25.5205 40.3059 19.7323C40.955 17.8096 41.4194 15.8269 41.6925 13.8121C41.8054 12.9922 41.8908 12.1795 41.9299 11.3594C41.7304 11.2723 41.5265 11.2006 41.322 11.1286C40.7673 10.9336 40.2129 10.7635 39.6553 10.626C35.1244 9.50363 30.3479 10.1303 26.2406 12.3861C22.1334 14.6419 18.984 18.3681 17.3958 22.8512L11.2524 41.1237C11.6598 41.1254 12.0648 41.1087 12.4625 41.0902Z"
        fill="#00B783"
      />
      <path
        d="M39.656 13.7612C25.6688 20.425 26.9198 34.6169 13.3434 39.6878C15.9838 38.0837 18.3417 36.0371 20.3187 33.6335C26.5775 25.4768 28.5141 16.9593 39.656 13.7612Z"
        fill="white"
      />
      <path
        d="M0.678869 16.2578C0.854254 16.3528 1.03589 16.4478 1.21186 16.536L1.41534 16.6404C4.8782 18.36 8.75338 19.0186 12.5738 18.5367C16.3943 18.0548 19.997 16.4531 22.9476 13.9245C23.9284 13.0849 24.8269 12.1496 25.6304 11.1317C25.9581 10.7182 26.2689 10.2991 26.5564 9.86029C26.477 9.74727 26.3858 9.63614 26.2964 9.52715C26.0539 9.23154 25.8052 8.95213 25.541 8.68512C23.4076 6.52914 20.5714 5.25411 17.576 5.1043C14.5805 4.95449 11.6363 5.94045 9.30745 7.87327L-0.000132919 15.862C0.225711 16.0034 0.454555 16.1323 0.678869 16.2578Z"
        fill="#00B783"
      />
      <path
        d="M24.5249 10.4128C14.6563 9.34319 10.7462 17.5901 1.61986 15.782C3.59574 15.7928 5.55933 15.4637 7.4285 14.8084C13.5195 12.4363 17.3472 8.39811 24.5249 10.4128Z"
        fill="white"
      />
    </svg>
  );
};

export default ModalTitleIcon;
