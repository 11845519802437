import React from "react";

const DGasIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82957 15H7.17042C4.58553 15 3.29307 15 2.41177 14.2411C1.53047 13.4823 1.34769 12.212 0.982128 9.6714L0.77305 8.21842C0.48847 6.2406 0.34618 5.25172 0.751525 4.40621C1.15687 3.5607 2.01964 3.04676 3.74518 2.01886L4.78381 1.40015C6.35078 0.466717 7.13427 0 8 0C8.86572 0 9.64925 0.466717 11.2161 1.40015L12.2548 2.01886C13.9803 3.04676 14.8431 3.5607 15.2484 4.40621C15.6538 5.25172 15.5115 6.2406 15.2269 8.21842L15.0179 9.6714C14.6523 12.212 14.4695 13.4823 13.5882 14.2411C12.7069 15 11.4144 15 8.82957 15Z"
        fill="#9FA1A2"
      />
      <path
        d="M9.47368 6.7C8.52632 5.36667 8.52632 3.76667 8.52632 1.5C5.57895 2.96667 6.31579 6.96667 6.21053 8.3C5.47368 7.5 5.26316 5.63333 5.26316 5.63333C4.42105 6.16667 4 7.5 4 8.56667C4 11.2333 5.78947 13.5 8 13.5C10.2105 13.5 12 11.3667 12 8.56667C12 6.96667 11.0526 6.16667 11.0526 4.03333C9.68421 4.56667 9.47368 5.9 9.47368 6.7Z"
        fill="#9FA1A2"
      />
    </svg>
  );
};

export default DGasIcon;
