import React from "react";

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M2.47424 1.7113L13.263 12.5"
        stroke="#2B4D70"
        strokeWidth="1.65843"
        strokeLinecap="round"
      />
      <path
        d="M13.263 1.7113L2.47422 12.5"
        stroke="#2B4D70"
        strokeWidth="1.65843"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseIcon;
