import React from "react";

const Sponser1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="154"
      height="96"
      viewBox="0 0 154 96"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7776 95.6619H13.1075C12.9806 95.6619 12.8749 95.5351 12.8749 95.4295V90.6548C12.8749 89.3661 13.0018 88.2041 13.0018 87.2746C13.0018 87.1478 13.1286 86.8098 13.0018 86.8098C12.8749 86.8098 12.8749 87.0422 12.8749 87.0422C12.5155 87.5069 12.0504 88.2041 11.8179 88.7957L7.56853 94.6267H7.33598L3.08664 88.7957C2.85409 88.2041 2.36785 87.4013 2.02959 87.0422C1.90275 86.9154 1.90275 86.8098 1.90275 86.8098C1.7759 86.9365 1.7759 87.1689 1.7759 87.2746C1.90275 88.2041 1.90275 89.2605 1.90275 90.6548V95.4084C1.90275 95.5351 1.90275 95.6408 1.7759 95.6408H0.126907C6.10352e-05 95.6619 6.10352e-05 95.5351 6.10352e-05 95.4084V81.4013C6.10352e-05 81.2746 0.126907 81.1689 0.232612 81.2746C1.54335 83.1337 6.38464 90.021 7.31484 91.3098C7.31484 91.4365 7.44169 91.4365 7.54739 91.3098C8.49873 90.021 13.34 83.1337 14.6296 81.2746C14.7565 81.1478 14.989 81.2746 14.989 81.4013V95.4084C15.0102 95.5351 14.8833 95.6619 14.7776 95.6619Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5338 96C25.7496 96 23.7412 93.7817 23.7412 89.8099V81.7606C23.7412 81.6338 23.8681 81.5282 23.9738 81.5282H25.3902C25.5171 81.5282 25.6228 81.655 25.6228 81.7606V89.8099C25.6228 92.6198 26.9124 94.0141 29.5127 94.0141C32.113 94.0141 33.4026 92.6198 33.4026 89.8099V81.7606C33.4026 81.6338 33.5295 81.5282 33.6352 81.5282H35.1785C35.3053 81.5282 35.3053 81.655 35.3053 81.7606V89.8099C35.3265 93.6761 33.2124 96 29.5338 96Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7046 88.0775C52.5777 87.9507 52.472 87.7183 52.7046 87.6127C53.4234 87.0212 54.0153 86.0916 54.0153 85.0352C54.0153 82.9437 52.3663 81.5282 49.8717 81.5282H45.2629C45.1361 81.5282 45.0304 81.655 45.0304 81.7606V95.4085C45.0304 95.5352 45.1572 95.6409 45.2629 95.6409H50.2311C53.064 95.6409 54.9667 94.0141 54.9667 91.4366C54.9455 89.8099 53.8885 88.6479 52.7046 88.0775ZM47.0388 83.5141C47.0388 83.3874 47.1656 83.2817 47.2713 83.2817H49.5123C51.0556 83.2817 52.1126 84.1057 52.1126 85.2676C52.1126 86.4296 50.9287 87.2535 49.5123 87.2535H47.2713C47.1445 87.2535 47.0388 87.1268 47.0388 86.8944V83.5141ZM50.1042 93.9085H47.2713C47.1445 93.9085 47.0388 93.7817 47.0388 93.5493V89.1127C47.0388 88.9859 47.1656 88.8803 47.2713 88.8803H49.9985C51.6475 88.8803 52.8314 89.9367 52.8314 91.4578C52.8314 92.8521 51.7532 93.9085 50.1042 93.9085Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.6802 81.5282H84.4796C84.3527 81.5282 84.247 81.655 84.247 81.7606V95.4085C84.247 95.5352 84.3739 95.6409 84.4796 95.6409H89.8071C93.9507 95.6409 97.0162 92.5986 97.0162 88.6479C97.0162 84.5705 93.9507 81.5282 89.6802 81.5282ZM89.8071 93.7817H86.5091C86.3823 93.7817 86.2765 93.655 86.2765 93.5493V83.6409C86.2765 83.5141 86.4034 83.4085 86.5091 83.4085H89.7014C92.7668 83.4085 95.0289 85.6268 95.0289 88.669C95.0078 92.0282 92.4074 93.7817 89.8071 93.7817Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.935 95.662H123.844C123.717 95.662 123.717 95.5352 123.717 95.4296V81.7817C123.717 81.655 123.717 81.5493 123.844 81.5493H125.493C125.62 81.5493 125.725 81.6761 125.725 81.7817V93.5704C125.725 93.6972 125.725 93.8028 125.852 93.8028H133.653C133.78 93.8028 133.886 93.9296 133.886 94.0352L133.167 95.5564C133.061 95.5352 133.061 95.662 132.935 95.662Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.446 95.6619H74.6701L74.5433 95.5351L73.4862 93.3168L73.3594 93.1901H67.2074C66.9748 93.1901 66.9748 93.0633 66.9748 93.0633L67.7993 91.5422C67.7993 91.4154 67.7993 91.4154 67.9262 91.4154H72.3023C72.4292 91.4154 72.5349 91.2887 72.4292 91.183L69.3637 84.7605H69.2369L63.9094 95.4929C63.9094 95.6196 63.9094 95.6196 63.7825 95.6196H62.1547C62.0278 95.6196 61.9221 95.4929 62.0278 95.3872L69.1101 81.1478C69.2369 81.021 69.3426 81.021 69.4695 81.1478L76.5517 95.3872C76.6997 95.5351 76.5728 95.6619 76.446 95.6619Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.994 95.6619H115.345C115.218 95.6619 115.218 95.5351 115.218 95.5351L114.035 93.3168C114.035 93.3168 114.035 93.1901 113.908 93.1901H107.756C107.629 93.1901 107.523 93.0633 107.629 93.0633L108.348 91.5422C108.348 91.4154 108.474 91.4154 108.474 91.4154H112.956C113.083 91.4154 113.083 91.2887 113.083 91.183L109.891 84.7605H109.764L104.563 95.4929C104.563 95.6196 104.437 95.6196 104.331 95.6196H102.682C102.555 95.6196 102.555 95.4929 102.555 95.3872L109.637 81.1478C109.764 81.021 109.87 81.021 109.997 81.1478L117.206 95.3872C117.227 95.5351 117.1 95.6619 116.994 95.6619Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.04 95.6619H151.369C151.243 95.6619 151.137 95.5351 151.137 95.5351L150.08 93.3168C150.08 93.3168 150.08 93.1901 149.953 93.1901H143.801C143.674 93.1901 143.568 93.0633 143.568 93.0633L144.393 91.5422C144.393 91.4154 144.52 91.4154 144.52 91.4154H148.896C149.023 91.4154 149.128 91.2887 149.023 91.183L145.957 84.7605H145.83L140.503 95.4929C140.503 95.6196 140.376 95.6196 140.376 95.6196H138.727C138.6 95.6196 138.495 95.4929 138.6 95.3872L145.683 81.1478C145.809 81.021 145.915 81.021 146.042 81.1478L153.124 95.3872C153.272 95.5351 153.145 95.6619 153.04 95.6619Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.88 39.0158C109.695 41.9138 113.86 40.7114 115.063 40.0332C116.452 39.17 117.13 38.6767 117.809 37.3202C119.351 34.9464 117.809 33.5591 116.76 33.4049C116.081 33.4049 114.693 33.5899 113.644 35.4396C113.12 36.457 112.965 37.6593 112.965 38.6767C111.423 39.5399 111.423 37.8135 111.423 37.1352C111.423 36.7961 111.423 36.1179 111.608 35.5938C111.608 34.3914 111.269 34.5764 110.405 35.4088C109.881 35.9329 106.086 38.985 100.07 42.2221C94.0236 45.2742 85.4165 48.5112 71.7808 51.5941C55.5846 55.3553 39.6968 54.4921 37.1054 50.3918C34.6991 46.2915 47.2859 38.985 48.304 38.4609C48.9827 37.9368 48.1189 37.7827 47.4402 37.9368C47.4402 37.9368 32.4471 46.1065 35.3778 51.9024C38.4937 57.8525 53.8261 57.6983 70.2074 54.2763C97.2937 48.4804 108.492 38.615 109.695 37.7518C110.405 37.4744 110.066 38.1526 109.88 39.0158Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.9052 28.2871C92.2958 28.6262 90.2288 29.3045 89.1799 29.6436C88.1619 29.9827 87.2981 30.5068 87.2981 31.0001C87.2981 31.185 87.4832 31.3392 88.5012 31.185C89.7044 31.0001 95.5659 29.9827 99.0211 29.9827C103.34 29.9827 105.746 30.5068 106.61 31.6783C107.659 32.8807 103.865 37.6284 90.2288 42.2219C75.7293 47.1546 64.5308 49.0352 49.5377 50.2375C48.4888 50.4225 47.9952 50.9157 49.8771 51.1007C52.4685 51.6248 66.4435 51.9639 84.5524 46.168C102.846 40.3722 109.911 33.898 107.844 30.6609C106.95 29.1195 103.155 27.6089 95.9052 28.2871Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.9375 22.3066C69.8682 20.2719 76.9329 18.2063 83.8433 21.6283C93.8386 26.4069 97.4789 37.9678 92.2961 47.1858C87.1133 55.8796 79.1849 56.712 76.2542 56.897C73.6628 57.0819 68.5108 56.712 65.9194 56.3729C64.7163 56.1879 64.7163 56.897 65.395 57.0511C68.6651 58.0685 77.9818 60.6273 86.6197 55.6946C96.2758 50.2379 98.3427 41.5748 96.0907 32.5419C93.4993 22.6765 84.3677 17.2198 74.8659 17.713C69.3438 17.898 66.413 20.7651 66.0737 21.8133C65.7343 22.6765 66.2588 22.6765 66.9375 22.3066Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.4642 25.5433C63.328 24.6801 63.6673 24.1868 64.0067 23.8477C64.346 23.6627 63.4822 22.9845 61.7546 23.8477C60.2121 24.8651 56.0783 29.6436 55.0294 35.2545C53.6411 42.5919 57.6207 49.0352 60.027 51.1007C62.4333 53.1355 62.6184 51.964 62.094 51.6248C61.7546 51.1007 58.4845 47.7095 57.4356 42.5919C56.5718 38.6766 56.757 36.1177 57.9601 32.5416C59.009 29.4586 61.9397 26.0674 62.4642 25.5433Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.8229 33.0351C80.4189 28.9348 71.1022 26.4068 65.7652 25.5436C64.562 25.3586 63.6982 26.5609 64.9014 26.7459C72.8298 28.2874 78.3519 29.6439 87.8229 33.7133C87.8229 33.8983 88.1622 33.8983 88.3473 33.7133C88.3165 33.405 88.1622 33.22 87.8229 33.0351Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.4809 38.4916C81.7759 32.8807 67.8009 30.661 61.9394 29.9828C60.7363 29.7978 60.0576 31.1851 61.2607 31.3392C75.39 33.0349 84.8918 35.7787 92.1415 39.1699C92.3266 39.1699 92.4809 39.3548 92.666 39.1699C92.8202 39.0157 92.8202 38.6766 92.4809 38.4916Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.397 36.6112C71.7807 36.9503 83.3186 39.5091 87.6376 40.7115C87.9769 40.8965 88.162 40.7115 88.162 40.5265C88.162 40.3415 88.162 40.1874 87.6376 40.0024C72.7987 35.563 62.6491 35.2239 60.5513 35.2239C59.3481 35.2547 58.6694 36.6112 60.397 36.6112Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.254 19.2546L76.7784 19.0696V18.7305V18.5455H76.5933C74.187 18.5455 71.7499 20.4261 69.6829 23.8173C67.2766 27.5785 65.8884 32.8503 65.7033 38.6462C65.3639 51.4095 69.3127 62.6621 73.9711 62.6621C75.1742 62.6621 76.5625 61.9839 76.9018 60.2883C76.9018 60.1033 76.9018 59.4251 76.5625 59.4251C76.3774 59.4251 76.2231 59.7642 76.038 60.1033C75.6987 60.9665 74.8349 61.1207 73.9711 61.1207C72.4286 61.1207 70.701 58.901 69.4978 54.3074C68.1096 49.3748 67.0915 43.7638 67.4309 37.9679C67.9862 25.7287 72.9839 19.1005 76.254 19.2546Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.0951 58.2535C86.0951 58.0686 85.91 57.7294 85.7558 57.7294H85.5707C85.3856 57.7294 85.3856 57.8836 85.3856 58.2535C85.2005 58.9318 85.0462 59.4559 84.3367 59.61C84.1516 59.61 83.8122 59.61 83.658 59.425C81.4059 57.8836 77.7965 49.2206 76.5933 38.8311C75.2051 27.2393 78.1358 19.7478 80.2028 19.2546L80.5421 18.9154V18.7305C80.357 18.7305 80.2028 18.9154 80.0177 18.9154L79.8326 18.7305C76.3774 19.7478 73.786 28.9349 75.3593 41.205C76.5625 51.5944 80.0177 58.9009 82.7633 60.6274C83.1027 60.9665 83.6271 61.1515 83.9665 61.1515H84.4909C85.4164 60.8124 86.0951 59.795 86.0951 58.2535Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.7069 41.3896C84.7069 41.0504 84.5218 40.8655 84.3676 39.8481C83.1644 35.4087 82.1155 28.5954 83.1644 23.5086V23.1695C83.1644 22.8304 83.1644 22.6454 82.9793 22.6454C82.7942 22.6454 82.4549 22.8304 82.4549 23.1695V23.3545C81.2517 28.6263 81.9304 36.4569 83.5038 41.7595C83.8431 43.0852 85.0463 42.4069 84.7069 41.3896Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0065 20.796C63.8214 19.9328 63.1427 19.2545 62.2789 19.2545C61.4151 19.2545 60.7364 20.1177 60.8907 20.9501C60.8907 21.8133 61.5694 22.4916 62.4332 22.4916C63.3278 22.3066 64.0065 21.6284 64.0065 20.796Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.793 45.305C111.793 44.4418 111.115 43.9485 110.251 43.9485C109.387 43.9485 108.708 44.4726 108.708 45.305C108.708 46.1682 109.387 47.0006 110.251 47.0006C111.084 47.0006 111.793 46.1682 111.793 45.305Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.3077 16.8806C67.3077 15.6782 66.2588 15 65.0556 15C64.0067 15.185 63.1738 16.0174 63.1738 17.0347C63.3589 18.2371 64.2227 19.0695 65.4258 19.0695C66.4439 19.1003 67.3077 18.0521 67.3077 16.8806Z"
        fill="#00B1B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.315 52.6422C116.976 51.6249 115.927 49.9293 114.199 48.3878C113.15 47.7096 112.318 48.2028 113.15 48.9119C114.014 49.5901 119.876 55.7252 111.608 56.2493C108.862 56.2493 103.494 55.9101 93.1597 53.0122C92.6353 52.8272 91.9566 52.6731 91.2779 52.4881C90.4141 52.3031 89.5503 51.964 88.6865 51.8098C87.4833 51.2857 86.0951 50.9466 84.7068 50.4534C83.5037 49.9293 82.1154 49.5901 80.7272 48.9119C79.8634 48.5728 78.9996 48.3878 78.3209 48.0487C78.1358 48.0487 77.9815 47.8637 77.9815 47.8637C77.3028 47.5246 76.5933 47.3396 75.9146 47.0005C75.5752 46.8155 75.2359 46.8155 74.8657 46.6614C72.6136 45.644 70.0531 44.4417 67.6159 43.2701C66.9372 42.931 66.2277 42.5919 65.549 42.2528C62.6182 40.7113 59.5024 39.2007 56.9418 37.3201C56.078 36.981 55.3993 36.4569 54.6898 35.9636C53.3015 34.9463 51.2346 33.4048 49.8463 32.5724C40.5297 24.7418 49.6613 24.7418 59.5024 25.9441C60.1811 25.9441 60.8906 26.1291 61.5693 26.2832L62.4331 24.7418C61.9087 24.5568 61.3842 24.5568 60.8906 24.4027C53.4867 23.3853 48.3039 23.8786 46.2369 25.0809C44.6944 26.0983 42.9668 28.6571 48.8283 33.7747C50.3708 35.1312 52.4377 36.6727 54.5047 38.0292C55.3685 38.5533 56.0472 39.2315 56.911 39.7248C59.5024 41.4204 62.5874 43.116 65.7032 44.8424C66.3819 45.1816 67.0915 45.5207 67.7702 46.0448C68.8191 46.3839 69.8371 46.908 70.886 47.4013C71.9349 47.9254 72.953 48.4186 74.0019 48.9427C74.8657 49.2818 75.5444 49.621 76.4082 49.8059C76.4082 49.9909 76.5933 49.9909 76.5933 49.9909C77.272 50.33 77.9815 50.515 78.6602 50.8541C79.1847 51.0391 79.8634 51.3782 80.5421 51.7174C82.4548 52.3956 84.3366 53.0738 86.2185 53.5979C87.0823 53.9371 87.9461 54.122 88.6248 54.4612C89.4886 54.6461 90.3524 54.9853 91.2162 55.1394C103.124 58.1915 109.664 58.7156 113.459 57.8524C113.675 57.7291 117.994 56.7117 117.315 52.6422Z"
        fill="#00B1B0"
      />
    </svg>
  );
};

export default Sponser1;
