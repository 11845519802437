import React from "react";
import { useCurrentWidth } from "react-socks";
import { useLanguage } from "../../../Contexts/LanguageContext";
import QrCode from "../icons/QrCode";

const Footer = () => {
  const width = useCurrentWidth();
  const { language } = useLanguage();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: width >= 768 ? "space-between" : "center",
        alignItems: "center",
        padding: width > 768 ? "0 100px" : "0",
        backgroundColor: "#2B4D70",
        height: width >= 768 ? 200 : 50,
      }}
    >
      <span
        style={{
          color: "white",
          direction: language === "ar" ? "rtl" : "ltr",
          fontFamily: language === "en" ? "Inter" : "cairo",
          fontSize: width >= 768 ? 20 : 10,
          fontWeight: 400,
        }}
      >
        {language === "en"
          ? " © 2023 Ministry of Climate Change and Environment. All rights reserved."
          : "حقوق الطبع © 2023 جميع الحقوق محفوظة. وزارة التغيّر المناخي والبيئة. "}
      </span>
      {width >= 768 && <QrCode />}
    </div>
  );
};

export default Footer;
