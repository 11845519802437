import React from "react";

const PlusIcon = ({ width = 24, height = 24, color = "#1C1C1C" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C12.5523 5 13 5.44772 13 6V11L18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13L13 13L13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18L11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11H11V6C11 5.44772 11.4477 5 12 5Z"
        fill={color}
      />
    </svg>
  );
};

export default PlusIcon;
