import { ApiUrlConstants } from "../constants";
import http from "../http";

class AdminLogin {
  loginAdmin({ data }) {
    return http.post(ApiUrlConstants.adminLogin, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const adminLoginInstance = new AdminLogin();
export default adminLoginInstance;
