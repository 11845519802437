import React from "react";

const OverviewBGAr = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 815 572"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-119.099 574.587C-123.997 571.65 -127.696 567.072 -129.539 561.667C-131.383 556.262 -131.252 550.378 -129.169 545.06C-127.087 539.742 -123.188 535.334 -118.164 532.618C-113.14 529.902 -107.316 529.053 -101.726 530.223C-34.2648 544.378 35.6476 541.658 101.806 522.307C167.964 502.955 228.321 467.569 277.52 419.29C282.098 414.807 288.27 412.325 294.678 412.392C301.086 412.458 307.205 415.068 311.689 419.646C316.173 424.224 318.654 430.396 318.588 436.804C318.521 443.212 315.912 449.331 311.333 453.815C256.472 507.657 189.166 547.12 115.389 568.701C41.6127 590.283 -36.3511 593.314 -111.58 577.526C-114.235 576.981 -116.778 575.987 -119.099 574.587Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M174.41 658.049C170.535 655.72 167.392 652.349 165.338 648.321C163.284 644.294 162.402 639.77 162.792 635.266C163.182 630.761 164.828 626.457 167.544 622.842C170.26 619.227 173.935 616.447 178.153 614.818C288.693 572.067 379.468 495.621 440.665 393.771L443.168 389.412C445.383 385.778 446.861 381.744 447.517 377.54C448.173 373.335 447.995 369.043 446.992 364.907C445.989 360.772 444.182 356.874 441.673 353.437C439.164 350 436.003 347.091 432.369 344.876C428.736 342.66 424.702 341.183 420.497 340.526C416.293 339.87 412 340.049 407.865 341.051C403.729 342.054 399.832 343.862 396.395 346.371C392.958 348.88 390.048 352.041 387.833 355.674L385.029 360.342C378.01 372.023 370.403 383.519 362.442 394.524C358.658 399.66 352.997 403.092 346.693 404.071C340.389 405.05 333.953 403.496 328.79 399.749C323.627 396.002 320.155 390.365 319.132 384.068C318.108 377.771 319.616 371.325 323.327 366.135C330.48 356.263 337.298 345.947 343.605 335.451L346.409 330.784C351.869 321.697 359.066 313.774 367.588 307.468C376.111 301.161 385.791 296.596 396.078 294.031C406.365 291.466 417.056 290.952 427.542 292.519C438.027 294.086 448.101 297.703 457.188 303.164C466.276 308.624 474.199 315.821 480.505 324.343C486.811 332.865 491.377 342.546 493.942 352.833C496.507 363.12 497.02 373.811 495.453 384.296C493.886 394.782 490.269 404.856 484.809 413.943L482.314 418.29C415.307 529.807 316.233 613.217 195.598 659.882C192.162 661.217 188.464 661.746 184.791 661.428C181.118 661.11 177.566 659.954 174.41 658.049Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M-145.023 682.633C-149.938 679.68 -153.641 675.076 -155.473 669.643C-157.305 664.21 -157.146 658.303 -155.023 652.977C-152.901 647.651 -148.953 643.254 -143.887 640.57C-138.821 637.887 -132.965 637.092 -127.367 638.328C-50.1784 655.183 29.7918 654.665 106.755 636.811C109.846 636.092 113.049 635.989 116.18 636.507C119.312 637.026 122.31 638.156 125.005 639.834C127.699 641.511 130.037 643.703 131.884 646.284C133.731 648.865 135.052 651.784 135.771 654.876C136.491 657.967 136.594 661.17 136.075 664.301C135.556 667.433 134.426 670.431 132.749 673.125C131.071 675.82 128.879 678.157 126.298 680.005C123.718 681.852 120.798 683.173 117.707 683.892C33.7203 703.354 -53.5418 703.906 -137.768 685.508C-140.327 684.954 -142.779 683.983 -145.023 682.633Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M-65.8946 474.814C-70.6229 471.976 -74.2381 467.606 -76.1402 462.429C-78.0423 457.253 -78.1168 451.581 -76.3513 446.357C-74.5858 441.132 -71.0866 436.668 -66.4345 433.707C-61.7825 430.745 -56.2574 429.463 -50.7765 430.075C8.42736 436.644 68.277 426.194 121.751 399.949C124.6 398.552 127.697 397.731 130.863 397.531C134.03 397.331 137.205 397.757 140.207 398.784C143.209 399.811 145.98 401.42 148.36 403.518C150.741 405.616 152.685 408.162 154.081 411.011C155.478 413.86 156.299 416.957 156.499 420.123C156.699 423.29 156.273 426.465 155.246 429.467C154.219 432.47 152.61 435.24 150.512 437.62C148.414 440.001 145.868 441.945 143.019 443.341C81.2979 473.645 12.2108 485.707 -56.1271 478.109C-59.5829 477.73 -62.9153 476.606 -65.8946 474.814Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M186.119 399.665C182.804 397.677 180.014 394.923 177.985 391.633C175.955 388.343 174.745 384.614 174.455 380.76C174.166 376.905 174.806 373.038 176.321 369.482C177.837 365.926 180.184 362.785 183.165 360.325C210.443 337.756 233.679 310.706 251.876 280.337C305.142 191.687 420.596 162.901 509.231 216.158C597.879 269.424 626.665 384.878 573.399 473.527C560.891 494.292 547.314 514.393 532.722 533.751C530.811 536.284 528.42 538.416 525.686 540.025C522.951 541.633 519.927 542.688 516.785 543.128C513.643 543.568 510.445 543.385 507.373 542.589C504.302 541.793 501.418 540.4 498.885 538.49C496.352 536.579 494.22 534.188 492.611 531.454C491.002 528.719 489.947 525.695 489.507 522.552C489.067 519.41 489.25 516.212 490.046 513.141C490.842 510.07 492.235 507.185 494.146 504.652C507.715 486.646 520.333 467.943 531.949 448.619C541.371 432.951 547.615 415.579 550.322 397.497C553.029 379.415 552.146 360.977 547.725 343.236C543.304 325.495 535.43 308.799 524.555 294.102C513.679 279.405 500.014 266.995 484.34 257.581C418.544 218.047 332.843 239.415 293.3 305.225C272.287 340.292 245.453 371.522 213.949 397.575C210.097 400.76 205.351 402.671 200.366 403.045C195.382 403.42 190.404 402.239 186.119 399.665Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M-9.04375 375.446C-13.4466 372.805 -16.8939 368.83 -18.8855 364.098C-20.8771 359.366 -21.3096 354.122 -20.1203 349.128C-18.9309 344.133 -16.1816 339.648 -12.2709 336.321C-8.36023 332.994 -3.49158 331 1.629 330.627C35.2723 328.19 67.838 317.707 96.5855 300.061C125.333 282.414 149.421 258.121 166.823 229.225C174.981 215.636 184.235 202.736 194.491 190.653C198.64 185.768 204.558 182.732 210.945 182.212C214.108 181.954 217.29 182.322 220.311 183.294C223.331 184.266 226.13 185.824 228.549 187.878C230.967 189.932 232.957 192.442 234.406 195.266C235.854 198.089 236.732 201.17 236.989 204.332C237.247 207.495 236.879 210.677 235.907 213.697C234.935 216.718 233.377 219.517 231.323 221.936C222.772 232.019 215.054 242.781 208.247 254.115C186.853 289.652 157.233 319.53 121.882 341.231C86.5306 362.932 46.483 375.823 5.11047 378.817C0.157759 379.178 -4.78552 378.001 -9.04375 375.446Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M261.188 174.298C257.945 172.358 255.209 169.677 253.204 166.474C251.509 163.792 250.36 160.802 249.822 157.675C249.285 154.548 249.369 151.346 250.07 148.252C250.772 145.157 252.076 142.232 253.91 139.642C255.743 137.053 258.069 134.851 260.755 133.161C354.111 74.3524 470.805 73.0912 565.309 129.875C592.214 146.009 616.322 166.404 636.691 190.265C640.763 195.151 642.745 201.446 642.206 207.784C641.666 214.123 638.649 219.992 633.808 224.12C628.968 228.248 622.695 230.3 616.351 229.831C610.008 229.363 604.104 226.412 599.923 221.618C582.939 201.738 562.843 184.745 540.419 171.299C502.035 148.088 457.937 136.047 413.084 136.531C368.231 137.015 324.402 150.003 286.528 174.037C282.744 176.427 278.37 177.717 273.895 177.763C269.42 177.809 265.02 176.609 261.188 174.298Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M633.814 532.66C628.386 529.393 624.457 524.124 622.875 517.989C621.293 511.853 622.183 505.342 625.354 499.857C662.959 434.799 668.307 357.463 640.012 287.672C637.604 281.732 637.655 275.079 640.153 269.177C642.651 263.275 647.391 258.606 653.331 256.199C659.271 253.792 665.924 253.843 671.826 256.341C677.728 258.839 682.397 263.579 684.804 269.519C718.726 353.217 712.313 445.993 667.2 524.043C663.985 529.586 658.703 533.629 652.512 535.285C646.321 536.941 639.726 536.075 634.173 532.876L633.814 532.66Z"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
};

export default OverviewBGAr;
