import React from "react";

const ArrowDown = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45488 10.3922L14 12L12.6198 6.3939L10.898 8.0468L8.12069 5.15373C8.02641 5.05552 7.89615 5 7.76 5C7.62385 5 7.49359 5.05552 7.39931 5.15373L5.36 7.27801L2.36069 4.15373C2.16946 3.95453 1.85294 3.94807 1.65373 4.13931C1.45453 4.33054 1.44807 4.64706 1.63931 4.84627L4.99931 8.34627C5.09359 8.44448 5.22385 8.5 5.36 8.5C5.49615 8.5 5.62641 8.44448 5.72069 8.34627L7.76 6.22199L10.1766 8.73933L8.45488 10.3922Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default ArrowDown;
