import React from "react";

const AdSectionIcon2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 89 70"
      fill="none"
    >
      <mask
        id="mask0_3129_908"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="89"
        height="70"
      >
        <path d="M89 0H0V70H89V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3129_908)">
        <path
          d="M9.41751 70C6.04349 64.0868 3.29045 59.7932 -0.000244141 53.7036C1.42218 52.9673 2.18913 52.5495 3.58024 51.855C7.4139 49.9418 11.2874 48.1084 15.0839 46.1203C18.2074 44.4871 21.2189 42.627 24.327 40.9602C26.6038 39.7104 29.0544 38.8244 31.5936 38.3329C32.9797 38.1391 34.3875 38.1772 35.7616 38.4458C40.6963 39.1692 45.6277 39.9108 50.556 40.6704C52.4354 40.9586 54.3116 41.2738 56.1846 41.601C57.0438 41.7195 57.822 42.1816 58.3483 42.8859C58.8746 43.5903 59.106 44.4793 58.9918 45.3579C58.9274 46.21 58.5512 47.0058 57.9383 47.5868C57.3253 48.1678 56.5206 48.4912 55.6847 48.4925C54.3848 48.5072 53.084 48.4117 51.7841 48.3547C47.767 48.1789 43.7499 47.9995 39.7328 47.8165C39.5003 47.7727 39.2603 47.8175 39.058 47.9424C38.8556 48.0672 38.705 48.2635 38.6346 48.4942C38.5723 48.7062 38.5866 48.9341 38.675 49.1362C38.7633 49.3383 38.9199 49.5012 39.116 49.5951C40.9318 50.7815 42.8751 51.7503 44.9076 52.4822C49.7824 54.3761 54.7649 55.9663 59.8277 57.2441C61.5757 57.6814 63.408 57.6146 65.121 57.0509C71.4381 55.0668 77.7519 53.071 84.0626 51.0634C84.9653 50.7416 85.9548 50.7895 86.8236 51.197C87.6925 51.6046 88.3733 52.3402 88.7233 53.2497C89.0733 54.1591 89.0653 55.1717 88.7011 56.0753C88.3369 56.9789 87.6446 57.7032 86.7695 58.0965C81.6833 60.4937 76.6135 62.9354 71.4927 65.2517C68.7392 66.5386 65.8606 67.5247 62.9045 68.1937C61.899 68.413 60.874 68.5261 59.8458 68.5312C54.5028 68.5247 49.1599 68.4862 43.8169 68.4156C40.0284 68.2835 36.2876 67.5088 32.7475 66.1232C30.867 65.4389 28.987 64.7466 27.08 64.147C24.6486 63.383 20.2248 62.5659 17.9972 64.1921C15.3928 66.0911 12.6652 67.8182 9.99073 69.618C9.81451 69.7374 9.63671 69.8535 9.41751 69.9979"
          fill="white"
        />
        <path
          d="M35.1125 0C35.7112 1.6533 36.2961 3.20133 36.8327 4.76401C38.2402 8.86388 41.0065 11.8574 44.302 14.4288C46.6156 16.234 48.9912 17.9643 51.2231 19.8705C52.6726 21.08 53.8683 22.5762 54.7386 24.2694C55.609 25.9627 56.136 27.8181 56.2881 29.7246C56.2972 29.872 56.2972 30.0199 56.2881 30.1674C55.8709 29.1711 55.4023 28.1981 54.8842 27.2525C53.6051 25.144 51.5713 24.1054 49.2948 23.6171C47.5616 23.2871 45.8132 23.047 44.0563 22.8976C41.9975 22.6752 39.9806 22.3295 38.1516 21.2649C38.0881 21.2372 38.0217 21.217 37.9536 21.2047L37.8772 21.3561C38.418 21.6686 38.9387 22.0278 39.5029 22.2845C41.3392 23.038 43.2564 23.5662 45.2144 23.858C46.6612 24.1293 48.1144 24.4006 49.5305 24.8C52.974 25.7663 54.9336 28.1218 55.4935 31.7138C55.5209 32.0494 55.5758 32.3821 55.6576 32.7083C55.8322 33.2292 55.5875 33.3915 55.1581 33.5418C53.5613 34.1216 51.8632 34.3523 50.1737 34.2188C48.4842 34.0853 46.8409 33.5907 45.3503 32.7669C39.315 29.6123 35.8025 24.5585 34.7792 17.7429C34.1544 13.1362 34.103 8.46701 34.6263 3.84702C34.7367 2.72276 34.8752 1.60121 35.0047 0.478571C35.0196 0.349433 35.0621 0.224093 35.1141 0.00217039"
          fill="#00B783"
        />
        <path
          d="M53.3117 13.5899C55.8424 15.9047 56.8232 18.947 57.2345 22.2482C57.5075 24.8378 57.4653 27.4521 57.1087 30.0312C57.1056 30.0627 57.0727 30.0903 57.0238 30.1668C56.8503 29.2444 56.7139 28.3605 56.5132 27.4891C55.6168 23.5965 53.4518 20.5439 50.4499 18.0702C48.5301 16.489 46.5058 15.0343 44.6418 13.3892C41.177 10.2554 38.5281 6.29072 36.9331 1.85137C36.906 1.78083 36.8896 1.70595 36.8694 1.63433C36.9166 1.60774 36.9591 1.56976 36.9755 1.58007C39.6585 3.18236 42.5984 4.13788 45.5207 5.13409C48.7677 6.15337 51.9006 7.51976 54.8674 9.21062C56.467 10.1211 57.9002 11.3082 59.1033 12.7191C61.349 15.4695 61.9625 18.7088 61.6345 22.1603C61.2821 25.87 59.6102 29.0708 57.622 32.1441C57.6655 31.7919 57.7064 31.4387 57.7541 31.0865C57.8019 30.7344 57.864 30.3812 57.907 30.0274C58.3056 26.7566 58.3905 23.4923 57.6034 20.2633C57.044 17.9692 56.0939 15.8873 54.2989 14.3029C54.1078 14.1347 53.9062 13.9773 53.7018 13.827C53.5792 13.7359 53.4417 13.667 53.3106 13.5888"
          fill="#00B783"
        />
        <path
          d="M67.7087 24.5287C67.0725 25.4555 66.5991 26.4885 66.3096 27.5813C65.6419 30.1223 63.9743 31.7593 61.5779 32.984C61.4787 31.7315 61.449 30.4743 61.4888 29.2183C61.8353 25.1418 63.6728 21.9389 67.1572 19.8482C69.1471 18.6545 71.3927 18.31 73.659 18.2747C75.5979 18.2443 77.5388 18.4049 79.4798 18.425C80.8274 18.4386 82.1761 18.3572 83.4318 18.3214C82.5678 19.8672 78.8445 24.2492 77.2289 25.5422C77.1758 24.3073 77.3945 23.0642 76.7215 21.9502C76.772 22.8947 76.7635 23.8415 76.696 24.7848C76.6286 25.2497 76.472 25.6965 76.2351 26.0994C75.9983 26.5023 75.686 26.8534 75.316 27.1326C72.4738 29.485 69.2109 31.2494 65.7093 32.3274C65.1138 32.4962 64.5066 32.6242 63.814 32.7941C63.8952 32.6687 63.9164 32.602 63.9616 32.5721C64.7464 32.0358 65.4113 31.3358 65.9128 30.5181C66.4143 29.7004 66.7409 28.7835 66.8712 27.8277C67.0792 26.6936 67.4348 25.5884 67.7082 24.5292"
          fill="#00B783"
        />
        <path
          d="M62.1438 34.3155C66.0528 34.3286 69.8212 34.5326 73.3428 36.2911C72.4241 35.222 71.2144 34.4571 69.8684 34.0941C68.5527 33.704 67.1977 33.4555 66.0125 33.1799C73.1482 30.7485 79.2712 25.9241 83.3884 19.489L83.5301 19.5666C83.3566 20.7517 83.2154 21.9421 83.0047 23.1196C82.5174 26.2176 81.5256 29.2102 80.0706 31.9731C78.7666 34.3361 77.0724 36.2944 74.5927 37.4534C71.2898 38.9965 65.3411 38.7605 62.1438 34.3155Z"
          fill="#00B783"
        />
      </g>
    </svg>
  );
};

export default AdSectionIcon2;
