import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminsListInstance from "../../api/Services/adminsList";

export const getAdminsListApi = createAsyncThunk(
  "admins/adminslist",
  async (_, { rejectWithValue }) => {
    try {
      const adminListRes = await AdminsListInstance.getAdminsList();
      return adminListRes.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdminsByIdApi = createAsyncThunk(
  "admins/adminsById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await AdminsListInstance.getAdminsById({ id });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editAdminApi = createAsyncThunk(
  "admins/edit",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await AdminsListInstance.editAdminsById({ data });
      if (res.data.success) {
        dispatch(getAdminsListApi());
      }
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addAdminApi = createAsyncThunk(
  "admins/add",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await AdminsListInstance.addAdmin({ data });
      if (res.data.success) {
        dispatch(getAdminsListApi());
      }
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
