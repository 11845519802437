import { createAsyncThunk } from "@reduxjs/toolkit";
import RewardsInstance from "../../api/Services/rewardsService";

export const getRewardsProviderList = createAsyncThunk(
  "rewards/rewardsProviderList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await RewardsInstance.getRewardsProviderList();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRewardByIdAPi = createAsyncThunk(
  "rewards/rewardById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await RewardsInstance.getRewardProviderById({ id });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addRewardProviderApi = createAsyncThunk(
  "rewards/add",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await RewardsInstance.addRewardProvider({ data });
      if (res.data.success) {
        dispatch(getRewardsProviderList());
      }
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editRewardProviderApi = createAsyncThunk(
  "rewards/edit",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await RewardsInstance.editRewardProvider({ data });
      if (res.data.success) {
        dispatch(getRewardsProviderList());
      }
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRewardsRulesList = createAsyncThunk(
  "rewards/rewardsRuleList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await RewardsInstance.getRewardsRuleList();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRulesByIdAPi = createAsyncThunk(
  "rewards/rewardRuleById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await RewardsInstance.getRewardRuleById({ id });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addRewardRulesApi = createAsyncThunk(
  "rewards/addRule",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await RewardsInstance.addRewardRule({ data });
      if (res.data.success) {
        dispatch(getRewardsRulesList());
      }
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editRewardRulesApi = createAsyncThunk(
  "rewards/editRule",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await RewardsInstance.editRewardRule({ data });
      if (res.data.success) {
        dispatch(getRewardsRulesList());
      }
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
