import React from "react";

const GreenIcon = ({ width = 48, height = 48 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M32.2201 33.3198L34.0001 43.9998L24.0001 41.9998L14.0001 43.9998L15.7801 33.3198"
        stroke="#00B783"
        strokeWidth="3.01924"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9999 34C31.7319 34 37.9999 27.732 37.9999 20C37.9999 12.268 31.7319 6 23.9999 6C16.2679 6 9.99988 12.268 9.99988 20C9.99988 27.732 16.2679 34 23.9999 34Z"
        stroke="#2B4D70"
        strokeWidth="3.01924"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GreenIcon;
