import React, { createContext, useContext, useState } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const switchLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("lang", newLanguage);
  };

  const style = {
    textAlign: language === "en" ? "left" : "right",
    fontFamily: language === "en" ? "Inter" : "cairo",
    direction: language === "en" ? "ltr" : "rtl",
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage, style }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
