import { createSlice } from "@reduxjs/toolkit";
import {
  addRewardProviderApi,
  addRewardRulesApi,
  editRewardProviderApi,
  editRewardRulesApi,
  getRewardByIdAPi,
  getRewardsProviderList,
  getRewardsRulesList,
  getRulesByIdAPi,
} from "../Thunks/RewardsThunk";

const RewardsSlice = createSlice({
  name: "rewards",
  initialState: {
    loading: false,
    selectedLoading: false,
    error: null,
    rewardsProviderList: [],
    rewardsRulesList: [],
    selectedReward: {},
    selectedRule: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRewardsProviderList.pending, (state) => {
      state.loading = true;
      state.rewardsProviderList = [];
      state.error = null;
    });
    builder.addCase(getRewardsProviderList.fulfilled, (state, action) => {
      state.loading = false;
      state.rewardsProviderList = action.payload.reward_providers;
      state.error = null;
    });
    builder.addCase(getRewardsProviderList.rejected, (state, action) => {
      state.loading = false;
      state.rewardsProviderList = [];
      state.error = action.payload.errorMessage;
    });

    builder.addCase(addRewardProviderApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addRewardProviderApi.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addRewardProviderApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(getRewardByIdAPi.pending, (state) => {
      state.selectedLoading = true;
    });
    builder.addCase(getRewardByIdAPi.fulfilled, (state, action) => {
      state.selectedLoading = false;
      state.selectedReward = action.payload.reward_provider;
    });
    builder.addCase(getRewardByIdAPi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(editRewardProviderApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editRewardProviderApi.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action?.payload?.message;
    });
    builder.addCase(editRewardProviderApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    ////////////////////////////////

    builder.addCase(getRewardsRulesList.pending, (state) => {
      state.loading = true;
      state.rewardsRulesList = [];
      state.error = null;
    });
    builder.addCase(getRewardsRulesList.fulfilled, (state, action) => {
      state.loading = false;
      state.rewardsRulesList = action.payload.reward_rules;
      state.error = null;
    });
    builder.addCase(getRewardsRulesList.rejected, (state, action) => {
      state.loading = false;
      state.rewardsRulesList = [];
      state.error = action.payload.errorMessage;
    });

    builder.addCase(addRewardRulesApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addRewardRulesApi.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addRewardRulesApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(getRulesByIdAPi.pending, (state) => {
      state.selectedLoading = true;
    });
    builder.addCase(getRulesByIdAPi.fulfilled, (state, action) => {
      state.selectedLoading = false;
      state.selectedRule = action.payload.reward_rule;
    });
    builder.addCase(getRulesByIdAPi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(editRewardRulesApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editRewardRulesApi.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action?.payload?.message;
    });
    builder.addCase(editRewardRulesApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });
  },
});

export default RewardsSlice;
