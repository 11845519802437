import React from "react";
import { sections } from "../constants/landingAdvantage";
import { useLanguage } from "../../../Contexts/LanguageContext";
import AdvantageIcon from "../icons/AdvantageIcon";
import { useCurrentWidth } from "react-socks";

const AdvantageSection = () => {
  const { language, style } = useLanguage();
  const width = useCurrentWidth();
  return (
    <div
      style={{
        display: width <= 1200 ? "flex" : "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: 50,
        direction: language === "en" ? "ltr" : "rtl",
        flexDirection: "column",
        backgroundColor: "#2B4D70",
        padding: width <= 1200 ? "40px 16px" : "110px 96px",
      }}
    >
      {width <= 1200 ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: 32,
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                // flexDirection: language === "en" ? "row" : "row-reverse",
                height: 45,
              }}
            >
              <span
                style={{
                  ...style,
                  fontSize: 32,
                  fontWeight: 500,
                  color: "#00B783",
                  marginLeft: language === "en" ? 0 : 5,
                }}
              >
                {language === "en" ? "Key" : "المزايا"}
              </span>
              <AdvantageIcon />
            </div>
            <span
              style={{
                ...style,
                fontSize: 32,
                fontWeight: 500,
                color: "white",
                marginTop: language === "en" ? 0 : 5,
              }}
            >
              {language === "en" ? "Advantages" : "الرئيسة"}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {sections.map((item) => (
              <div
                key={item.id}
                style={{
                  marginTop: 16,
                  marginBottom: 21,
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: language === "en" ? "flex-start" : "flex-end",
                  ...style,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    // alignItems: language === "en" ? "flex-start" : "flex-end",
                    ...style,
                  }}
                >
                  <div
                    style={{
                      width:
                        item.id === 1
                          ? 67
                          : item.id === 2
                          ? 89
                          : item.id === 3
                          ? 59
                          : 75,

                      height: 70,
                      ...style,
                    }}
                  >
                    {item.type === "icon" ? (
                      item.icon
                    ) : (
                      <img src={item?.image} alt={item.enTitle1} />
                    )}
                  </div>
                  <p
                    style={{
                      ...style,
                      fontSize: 22,
                      fontWeight: 500,
                      marginTop: 12,
                      marginBottom: 15,
                    }}
                  >
                    {language === "en" ? item.enTitle : item.arTitle}
                  </p>
                  <p
                    style={{
                      ...style,
                      fontSize: 18,
                      fontWeight: 400,
                      marginBottom: 15,
                    }}
                  >
                    {language === "en" ? item.enBody : item.arBody}
                  </p>
                </div>
                <div
                  style={{
                    height: 2,
                    width: 310,
                    backgroundColor: "#D19631",
                  }}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              marginTop: language === "en" ? 35 : 65,
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                height: 45,
              }}
            >
              <span
                style={{
                  ...style,
                  fontSize: width > 1439 && width < 1920 ? 55 : 64,
                  fontWeight: 500,
                  color: "#00B783",
                  marginLeft: language === "en" ? 0 : 5,
                }}
              >
                {language === "en" ? "Key" : "المزايا"}
              </span>
              <AdvantageIcon width={136} height={150} />
            </div>
            <span
              style={{
                ...style,
                fontSize: width > 1439 && width < 1920 ? 55 : 64,
                fontWeight: 500,
                color: "white",
                marginTop: language === "en" ? 45 : 45,
              }}
            >
              {language === "en" ? "Advantages" : "الرئيسة"}
            </span>
          </div>

          {sections.map((item, index) => (
            <div
              key={item.id}
              style={{
                marginTop: 16,
                maxWidth: 506,
                height: 480,
                minHeight: 484,
                marginBottom: 21,
                color: "white",
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                // alignItems: language === "en" ? "flex-start" : "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  // justifyContent: "center",
                  // gap: 50,
                  // alignItems: language === "en" ? "flex-start" : "flex-end",
                }}
              >
                <div>
                  <div
                    style={{
                      width:
                        item.id === 1
                          ? 113
                          : item.id === 2
                          ? 134
                          : item.id === 3
                          ? 117
                          : item.id === 4
                          ? 153
                          : 152,
                      height: item.id === 1 ? 117 : item.id === 2 ? 103 : 142,
                    }}
                  >
                    {item.type === "icon" ? (
                      item.icon
                    ) : (
                      <img src={item?.image} alt={item.enTitle1} />
                    )}
                  </div>
                  <p
                    style={{
                      ...style,
                      fontSize: width > 1200 && width < 1920 ? 21 : 35,
                      fontWeight: 500,
                      marginTop: 12,
                      marginBottom: language === "en" ? 0 : 15,
                      textAlign: "justify",
                    }}
                  >
                    {language === "en" ? item.enTitle1 : item.arTitle}
                  </p>
                  <p
                    style={{
                      ...style,
                      fontSize: width > 1200 && width < 1920 ? 20 : 35,
                      fontWeight: 500,
                      // marginTop: 12,
                      marginBottom: 15,
                    }}
                  >
                    {language === "en" ? item.enTitle2 : ""}
                  </p>
                </div>

                <p
                  style={{
                    ...style,
                    fontSize: width > 1200 && width < 1920 ? 20 : 25,
                    fontWeight: 400,
                    marginBottom: 15,
                    width: "101%",
                    height:
                      (language !== "en" && index === 1) || index === 4
                        ? "32%"
                        : "auto",
                    marginTop: width <= 1910 ? "15%" : "5%",
                  }}
                >
                  {language === "en" ? item.enBody : item.arBody}
                </p>
              </div>
              <div
                style={{
                  height: 4,
                  width: 310,
                  backgroundColor: "#D19631",
                }}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default AdvantageSection;
