import React from "react";
import { useLanguage } from "../../../Contexts/LanguageContext";
import GreenIcon from "../icons/GreenIcon";
import { useCurrentWidth } from "react-socks";

const GreenPoints = () => {
  const { language, style } = useLanguage();
  const width = useCurrentWidth();

  return (
    <div
      style={{
        display: "flex",
        fontFamily: "Inter",
        padding: width <= 1200 ? "40px 16px" : "70px 96px",
        flexDirection:
          width <= 1200
            ? "column"
            : width > 1200 && language === "en"
            ? "row"
            : "row-reverse",
        gap: width <= 1200 ? 0 : 125,
        justifyContent: "space-between",
        height:
          language === "en" && width <= 1200 ? 308 : width > 1200 ? 370 : 255,
      }}
    >
      {language === "en" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // alignItems: width <= 1200 ? "center" : "self-start",
            height: "100%",
          }}
        >
          <GreenIcon
            width={width > 1200 ? 80 : 48}
            height={width > 1200 ? 80 : 48}
          />
          <span
            style={{
              ...style,
              fontSize: width <= 1200 ? "40px" : 50,
              fontWeight: 500,
              color: "#00B783",
            }}
          >
            Green
          </span>
          <span
            style={{
              ...style,
              fontSize: width <= 1200 ? "40px" : 50,
              fontWeight: 500,
              color: "#2B4D70",
            }}
          >
            &nbsp;Points
          </span>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <GreenIcon
            width={width > 1200 ? 124 : 48}
            height={width > 1200 ? 124 : 48}
          />{" "}
          <span
            style={{
              ...style,
              fontSize: width <= 1200 ? "36px" : 50,
              fontWeight: 500,
              color: "#2B4D70",
            }}
          >
            نقاط
          </span>
          <span
            style={{
              ...style,
              fontSize: width <= 1200 ? "36px" : 50,
              fontWeight: 500,
              color: "#00B783",
            }}
          >
            &nbsp;خضراء
          </span>
        </div>
      )}
      <div
        style={{
          fontSize: width <= 1200 ? 18 : 28,
          fontWeight: language === "en" ? 400 : 300,
          fontFamily: language === "en" ? "Inter" : "cairo",
          color: "#2B4D70",
          marginTop: width <= 1200 ? 0 : 0,
          display: "flex",
          alignItems: "center",
          ...style,
        }}
      >
        {language === "en" ? (
          <div>
            <p
              style={{
                ...style,
                color: "#2B4D70",
                fontSize: width <= 1200 ? 18 : 25,
                fontWeight: language === "en" ? 400 : 600,
                fontFamily: language === "en" ? "Inter" : "cairo",
              }}
            >
              The upcoming application will feature a rewards system, allowing
              users to reduce their CO2 emissions and earn green points that can
              be redeemed for a range of loyalty programs.
            </p>
            <p
              style={{
                ...style,
                color: "#2B4D70",
                fontSize: width <= 1200 ? 18 : 25,
                fontWeight: 600,
                fontFamily: language === "en" ? "Inter" : "cairo",
              }}
            >
              Stay tuned, as the launch is just around the corner!
            </p>
          </div>
        ) : (
          <div>
            <p
              style={{
                ...style,
                color: "#2B4D70",
                fontSize: width <= 1200 ? 18 : 28,
                fontWeight: language === "en" ? 400 : 300,
                fontFamily: language === "en" ? "Inter" : "cairo",
              }}
            >
              يتميّز البرنامج المرتقب بنظام حوافز يتيح للمستخدمين خفض انبعاثاتهم
              الكربونية وكسب نقاط خضراء يمكن استبدالها عبر مجموعة من برامج
              الولاء،
            </p>
            <p
              style={{
                ...style,
                color: "#2B4D70",
                direction: language === "ar" ? "rtl" : "ltr",
                fontSize: width <= 1200 ? 18 : 28,
                fontWeight: 600,
                fontFamily: language === "en" ? "Inter" : "cairo",
              }}
            >
              ابقو على اطلاع، إطلاق البرنامج بات وشيكاً
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default GreenPoints;
