import React from "react";
import { useLanguage } from "../../../Contexts/LanguageContext";
import { useCurrentWidth } from "react-socks";

const AimSection = () => {
  const { language, style } = useLanguage();
  const width = useCurrentWidth();
  const aimIcon = "./images/aimIcon.png";

  return (
    <div
      style={{
        display: "flex",
        color: "#2B4D70",
        fontFamily: "Inter",
        padding: width <= 1024 ? "40px 16px" : "70px 96px",
        flexDirection:
          width > 1024 && language === "en"
            ? "row"
            : width > 1024 && language !== "en"
            ? "row-reverse"
            : "column",
        justifyContent: "center",
        // height:
        //   language === "en" && width <= 1024 ? 318 : width > 1024 ? 520 : 255,
      }}
    >
      {width <= 1024 ? (
        <>
          <p
            style={{
              fontSize: language === "en" ? 40 : 36,
              fontWeight: 500,
              fontFamily: language === "en" ? "Inter" : "cairo",
              ...style,
            }}
          >
            {language === "en" ? "The Aim" : "الهدف"}
          </p>
          <p
            style={{
              fontSize: 18,
              fontWeight: language === "en" ? 400 : 300,
              fontFamily: language === "en" ? "Inter" : "cairo",
              ...style,
            }}
          >
            {language === "en"
              ? "By participating in this program, individuals are demonstrating their concern for the environment and communities in the UAE. They have the opportunity to offset their carbon footprint and actively contribute to the preservation and protection of the natural surroundings. "
              : "يُفسح الاشتراك في هذا البرنامج المجال أمام الأفراد ليؤكّدوا حرصهم على البيئة والمجتمعات المحلية الإماراتية ويمنحهم الفرصة لتعويض بصمتهم الكربونية والإسهام الفعّال في حماية البيئة المحيطة بهم والحفاظ عليها."}
          </p>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
            }}
          >
            <img
              src={aimIcon}
              alt="aim-icon"
              style={{
                width: 405,
                height: 377,
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: language === "en" ? "flex-start" : "flex-end",
              width: "50%",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontSize: width >= 1820 ? 88 : 60,
                fontWeight: 500,
                fontFamily: language === "en" ? "Inter" : "cairo",
                ...style,
              }}
            >
              {language === "en" ? "The Aim" : "الهدف"}
            </p>
            <p
              style={{
                fontSize: width >= 1820 ? 28 : 20,
                fontWeight: language === "en" ? 400 : 300,
                fontFamily: language === "en" ? "Inter" : "cairo",
                ...style,
              }}
            >
              {language === "en" ? (
                <div>
                  By participating in this program, individuals are
                  demonstrating their concern for the environment and
                  communities in the UAE. They have the opportunity to offset
                  their carbon footprint and actively contribute to the
                  preservation and protection of the natural surroundings.
                </div>
              ) : (
                "يُفسح الاشتراك في هذا البرنامج المجال أمام الأفراد ليؤكّدوا حرصهم على البيئة والمجتمعات المحلية الإماراتية ويمنحهم الفرصة لتعويض بصمتهم الكربونية والإسهام الفعّال في حماية البيئة المحيطة بهم والحفاظ عليها."
              )}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default AimSection;
