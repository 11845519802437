import React from "react";

const OverviewBGIcon = ({ stroke = "white" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 815 652"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M933.288 574.587C938.186 571.65 941.885 567.073 943.729 561.667C945.572 556.262 945.441 550.378 943.359 545.06C941.276 539.742 937.377 535.334 932.353 532.618C927.329 529.902 921.505 529.053 915.915 530.223C848.454 544.378 778.542 541.658 712.384 522.307C646.226 502.955 585.868 467.569 536.669 419.29C532.091 414.807 525.919 412.325 519.511 412.392C513.103 412.458 506.984 415.068 502.5 419.646C498.016 424.224 495.535 430.396 495.602 436.804C495.668 443.212 498.278 449.331 502.856 453.815C557.717 507.657 625.024 547.12 698.8 568.701C772.577 590.283 850.54 593.314 925.77 577.526C928.425 576.981 930.967 575.987 933.288 574.587Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M639.779 658.049C643.654 655.72 646.798 652.349 648.851 648.321C650.905 644.294 651.787 639.77 651.397 635.266C651.007 630.761 649.361 626.457 646.645 622.842C643.93 619.227 640.254 616.447 636.036 614.818C525.496 572.068 434.722 495.621 373.524 393.771L371.021 389.412C368.806 385.778 367.328 381.744 366.672 377.54C366.016 373.335 366.194 369.043 367.197 364.907C368.2 360.772 370.007 356.874 372.516 353.437C375.025 350 378.187 347.091 381.82 344.876C385.453 342.661 389.488 341.183 393.692 340.526C397.896 339.87 402.189 340.049 406.325 341.051C410.46 342.054 414.358 343.862 417.795 346.371C421.232 348.88 424.141 352.041 426.356 355.674L429.16 360.342C436.179 372.023 443.787 383.519 451.748 394.524C455.531 399.66 461.193 403.092 467.497 404.071C473.801 405.05 480.236 403.496 485.399 399.749C490.562 396.002 494.034 390.365 495.058 384.068C496.081 377.771 494.573 371.325 490.862 366.135C483.709 356.263 476.891 345.948 470.585 335.451L467.78 330.784C462.32 321.697 455.123 313.774 446.601 307.468C438.079 301.161 428.398 296.596 418.111 294.031C407.824 291.466 397.133 290.952 386.648 292.519C376.162 294.087 366.088 297.703 357.001 303.164C347.913 308.624 339.99 315.821 333.684 324.343C327.378 332.865 322.812 342.546 320.247 352.833C317.683 363.12 317.169 373.811 318.736 384.297C320.303 394.782 323.92 404.856 329.38 413.943L331.876 418.29C398.882 529.808 497.956 613.217 618.591 659.882C622.028 661.217 625.725 661.746 629.398 661.428C633.071 661.11 636.623 659.955 639.779 658.049Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M959.213 682.633C964.127 679.68 967.831 675.076 969.663 669.643C971.494 664.21 971.335 658.303 969.212 652.977C967.09 647.651 963.143 643.254 958.076 640.57C953.01 637.887 947.154 637.092 941.556 638.328C864.368 655.183 784.398 654.665 707.434 636.811C704.343 636.092 701.14 635.989 698.009 636.507C694.878 637.026 691.879 638.156 689.185 639.834C686.49 641.511 684.153 643.703 682.305 646.284C680.458 648.865 679.137 651.784 678.418 654.876C677.699 657.967 677.596 661.17 678.114 664.301C678.633 667.432 679.763 670.431 681.441 673.125C683.118 675.82 685.31 678.157 687.891 680.005C690.472 681.852 693.391 683.173 696.483 683.892C780.469 703.354 867.731 703.906 951.957 685.508C954.517 684.954 956.968 683.983 959.213 682.633Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M880.084 474.814C884.812 471.976 888.427 467.606 890.329 462.429C892.231 457.253 892.306 451.581 890.541 446.357C888.775 441.132 885.276 436.668 880.624 433.707C875.972 430.745 870.447 429.463 864.966 430.075C805.762 436.644 745.912 426.194 692.438 399.949C689.589 398.552 686.493 397.731 683.326 397.531C680.159 397.331 676.984 397.757 673.982 398.784C670.98 399.811 668.209 401.42 665.829 403.518C663.448 405.616 661.504 408.162 660.108 411.011C658.712 413.86 657.89 416.957 657.69 420.123C657.49 423.29 657.916 426.465 658.943 429.467C659.971 432.47 661.579 435.24 663.677 437.62C665.775 440.001 668.321 441.945 671.17 443.341C732.891 473.645 801.978 485.707 870.316 478.109C873.772 477.73 877.105 476.606 880.084 474.814Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M628.07 399.665C631.385 397.677 634.175 394.923 636.204 391.633C638.234 388.343 639.444 384.614 639.734 380.76C640.023 376.905 639.383 373.038 637.868 369.482C636.352 365.926 634.005 362.785 631.024 360.325C603.746 337.756 580.51 310.706 562.313 280.337C509.048 191.687 393.593 162.901 304.958 216.158C216.31 269.424 187.524 384.878 240.79 473.527C253.298 494.292 266.876 514.393 281.467 533.751C283.378 536.284 285.769 538.416 288.503 540.025C291.238 541.633 294.262 542.688 297.405 543.128C300.547 543.568 303.745 543.385 306.816 542.589C309.887 541.793 312.772 540.4 315.305 538.49C317.838 536.579 319.969 534.188 321.578 531.454C323.187 528.719 324.242 525.695 324.682 522.552C325.122 519.41 324.939 516.212 324.143 513.141C323.347 510.07 321.954 507.185 320.043 504.652C306.474 486.646 293.856 467.943 282.241 448.619C272.818 432.951 266.575 415.579 263.868 397.497C261.161 379.415 262.043 360.977 266.464 343.236C270.885 325.495 278.759 308.799 289.635 294.102C300.51 279.405 314.175 266.995 329.849 257.581C395.645 218.047 481.346 239.415 520.889 305.225C541.902 340.292 568.737 371.522 600.24 397.575C604.093 400.76 608.838 402.671 613.823 403.045C618.807 403.42 623.785 402.239 628.07 399.665Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M960.573 791.024C965.432 788.11 969.112 783.58 970.97 778.228C972.827 772.876 972.745 767.041 970.737 761.743C968.729 756.445 964.923 752.021 959.985 749.244C955.046 746.468 949.289 745.515 943.719 746.553C871.711 759.929 797.929 760.719 725.652 748.887C719.332 747.877 712.87 749.413 707.682 753.161C702.494 756.908 699.004 762.561 697.977 768.878C696.951 775.195 698.472 781.661 702.207 786.858C705.941 792.055 711.585 795.559 717.899 796.601C795.67 809.317 875.057 808.461 952.536 794.069C955.376 793.546 958.099 792.514 960.573 791.024Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M823.233 375.446C827.636 372.805 831.083 368.83 833.075 364.098C835.066 359.366 835.499 354.122 834.309 349.128C833.12 344.133 830.371 339.648 826.46 336.321C822.549 332.994 817.681 331 812.56 330.627C778.917 328.19 746.351 317.707 717.604 300.061C688.856 282.414 664.768 258.121 647.366 229.225C639.208 215.636 629.955 202.736 619.698 190.652C615.55 185.768 609.631 182.732 603.244 182.212C600.081 181.954 596.899 182.322 593.879 183.294C590.858 184.266 588.059 185.824 585.641 187.878C583.222 189.932 581.232 192.442 579.784 195.266C578.335 198.089 577.457 201.17 577.2 204.332C576.942 207.495 577.31 210.677 578.282 213.697C579.254 216.718 580.812 219.517 582.866 221.935C591.418 232.019 599.135 242.781 605.942 254.115C627.337 289.652 656.956 319.53 692.307 341.231C727.659 362.932 767.706 375.823 809.079 378.817C814.031 379.178 818.975 378 823.233 375.446Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M553.001 174.298C556.244 172.358 558.98 169.677 560.985 166.474C562.68 163.792 563.829 160.802 564.367 157.675C564.905 154.548 564.82 151.346 564.119 148.252C563.418 145.157 562.113 142.232 560.279 139.642C558.446 137.053 556.12 134.85 553.434 133.161C460.078 74.3523 343.384 73.0912 248.88 129.875C221.975 146.009 197.867 166.404 177.499 190.264C173.426 195.151 171.444 201.446 171.984 207.784C172.523 214.123 175.541 219.992 180.381 224.12C185.221 228.247 191.494 230.3 197.838 229.831C204.182 229.363 210.085 226.412 214.267 221.618C231.25 201.738 251.346 184.745 273.77 171.299C312.154 148.088 356.252 136.047 401.106 136.531C445.959 137.014 489.787 150.003 527.661 174.037C531.445 176.427 535.819 177.717 540.294 177.763C544.769 177.809 549.169 176.609 553.001 174.298Z"
        stroke={stroke}
        strokeWidth="3"
      />
      <path
        d="M180.375 532.66C185.804 529.393 189.732 524.124 191.314 517.989C192.897 511.853 192.006 505.342 188.835 499.857C151.23 434.799 145.883 357.463 174.178 287.672C176.585 281.732 176.534 275.079 174.036 269.177C171.538 263.275 166.798 258.606 160.858 256.199C154.918 253.792 148.265 253.843 142.363 256.341C136.461 258.839 131.793 263.579 129.385 269.519C95.4629 353.217 101.876 445.993 146.989 524.043C150.204 529.586 155.486 533.629 161.677 535.285C167.868 536.941 174.463 536.075 180.016 532.876L180.375 532.66Z"
        stroke={stroke}
        strokeWidth="3"
      />
    </svg>
  );
};

export default OverviewBGIcon;
