import React from "react";

const MobileWeb = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 781 574"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-153.099 645.587C-157.997 642.65 -161.696 638.073 -163.539 632.667C-165.383 627.262 -165.252 621.378 -163.169 616.06C-161.087 610.742 -157.188 606.334 -152.164 603.618C-147.14 600.902 -141.316 600.053 -135.726 601.223C-68.2647 615.378 1.64777 612.658 67.8058 593.307C133.964 573.955 194.321 538.569 243.52 490.291C248.098 485.807 254.27 483.325 260.678 483.392C267.086 483.458 273.205 486.068 277.689 490.646C282.173 495.224 284.655 501.396 284.588 507.804C284.521 514.212 281.912 520.331 277.334 524.815C222.472 578.657 155.166 618.12 81.3893 639.702C7.61279 661.283 -70.351 664.314 -145.58 648.526C-148.235 647.981 -150.778 646.987 -153.099 645.587Z"
        stroke="#2B4D70"
        strokeWidth="3"
      />
      <path
        d="M140.41 729.049C136.535 726.72 133.392 723.349 131.338 719.321C129.284 715.294 128.402 710.77 128.792 706.266C129.182 701.761 130.829 697.457 133.544 693.842C136.26 690.227 139.936 687.447 144.153 685.818C254.693 643.067 345.468 566.621 406.665 464.771L409.168 460.412C411.383 456.778 412.861 452.744 413.517 448.54C414.173 444.335 413.995 440.043 412.992 435.907C411.99 431.772 410.182 427.874 407.673 424.437C405.164 421 402.003 418.091 398.369 415.876C394.736 413.66 390.702 412.183 386.497 411.526C382.293 410.87 378 411.049 373.865 412.051C369.729 413.054 365.832 414.862 362.395 417.371C358.958 419.88 356.049 423.041 353.833 426.674L351.029 431.342C344.01 443.023 336.403 454.519 328.442 465.524C324.658 470.66 318.997 474.092 312.693 475.071C306.389 476.05 299.953 474.496 294.79 470.749C289.627 467.002 286.155 461.365 285.132 455.068C284.108 448.771 285.616 442.325 289.327 437.135C296.48 427.263 303.298 416.947 309.605 406.451L312.409 401.784C317.869 392.697 325.066 384.774 333.588 378.468C342.111 372.161 351.792 367.596 362.078 365.031C372.365 362.466 383.057 361.952 393.542 363.519C404.027 365.086 414.101 368.703 423.189 374.164C432.276 379.624 440.199 386.821 446.505 395.343C452.811 403.865 457.377 413.546 459.942 423.833C462.507 434.12 463.02 444.811 461.453 455.296C459.886 465.782 456.269 475.856 450.809 484.943L448.314 489.29C381.307 600.807 282.233 684.217 161.598 730.882C158.162 732.217 154.464 732.746 150.791 732.428C147.118 732.11 143.567 730.954 140.41 729.049Z"
        stroke="#2B4D70"
        strokeWidth="3"
      />
      <path
        d="M-99.8945 545.814C-104.623 542.976 -108.238 538.606 -110.14 533.429C-112.042 528.253 -112.117 522.581 -110.351 517.357C-108.586 512.132 -105.086 507.668 -100.434 504.707C-95.7824 501.745 -90.2573 500.463 -84.7764 501.074C-25.5725 507.644 34.2772 497.194 87.7511 470.949C90.6003 469.552 93.6967 468.731 96.8634 468.531C100.03 468.331 103.205 468.757 106.207 469.784C109.209 470.811 111.98 472.42 114.36 474.518C116.741 476.616 118.685 479.162 120.081 482.011C121.478 484.86 122.299 487.957 122.499 491.123C122.699 494.29 122.273 497.465 121.246 500.467C120.219 503.469 118.61 506.24 116.512 508.62C114.414 511.001 111.868 512.945 109.019 514.341C47.298 544.645 -21.7891 556.706 -90.127 549.108C-93.5827 548.73 -96.9152 547.606 -99.8945 545.814Z"
        stroke="#2B4D70"
        strokeWidth="3"
      />
      <path
        d="M152.119 470.665C148.804 468.677 146.015 465.923 143.985 462.633C141.955 459.343 140.745 455.614 140.456 451.76C140.166 447.905 140.806 444.037 142.322 440.481C143.837 436.926 146.184 433.785 149.165 431.325C176.443 408.756 199.679 381.706 217.876 351.336C271.142 262.687 386.596 233.901 475.231 287.158C563.879 340.424 592.665 455.878 539.4 544.527C526.891 565.292 513.314 585.393 498.722 604.751C496.811 607.284 494.421 609.415 491.686 611.024C488.952 612.633 485.927 613.688 482.785 614.128C479.643 614.568 476.445 614.385 473.374 613.589C470.302 612.793 467.418 611.4 464.885 609.49C462.352 607.579 460.22 605.188 458.611 602.454C457.002 599.719 455.948 596.694 455.507 593.552C455.067 590.41 455.251 587.212 456.046 584.141C456.842 581.07 458.235 578.185 460.146 575.652C473.715 557.646 486.333 538.943 497.949 519.619C507.372 503.95 513.615 486.579 516.322 468.497C519.029 450.415 518.146 431.977 513.725 414.236C509.304 396.495 501.431 379.799 490.555 365.102C479.679 350.405 466.014 337.995 450.34 328.581C384.544 289.047 298.843 310.415 259.301 376.224C238.287 411.291 211.453 442.522 179.949 468.575C176.097 471.76 171.351 473.671 166.367 474.045C161.382 474.42 156.404 473.239 152.119 470.665Z"
        stroke="#2B4D70"
        strokeWidth="3"
      />
      <path
        d="M-43.0436 446.446C-47.4465 443.805 -50.8937 439.83 -52.8854 435.098C-54.877 430.366 -55.3095 425.122 -54.1202 420.128C-52.9308 415.133 -50.1814 410.648 -46.2708 407.321C-42.3601 403.994 -37.4915 402 -32.3709 401.627C1.27243 399.19 33.8381 388.707 62.5856 371.061C91.3332 353.414 115.421 329.121 132.823 300.225C140.981 286.636 150.235 273.736 160.491 261.653C164.64 256.768 170.558 253.732 176.945 253.212C180.108 252.954 183.29 253.322 186.311 254.294C189.331 255.266 192.13 256.824 194.549 258.878C196.967 260.932 198.957 263.442 200.406 266.266C201.854 269.089 202.732 272.17 202.99 275.332C203.247 278.495 202.879 281.677 201.907 284.697C200.935 287.718 199.377 290.517 197.323 292.936C188.772 303.019 181.054 313.781 174.248 325.115C152.853 360.652 123.233 390.53 87.8818 412.231C52.5307 433.932 12.4831 446.823 -28.8894 449.817C-33.8421 450.178 -38.7854 449.001 -43.0436 446.446Z"
        stroke="#2B4D70"
        strokeWidth="3"
      />
      <path
        d="M227.188 245.298C223.945 243.358 221.209 240.677 219.204 237.474C217.509 234.792 216.36 231.802 215.822 228.675C215.285 225.548 215.369 222.346 216.07 219.252C216.772 216.157 218.076 213.232 219.91 210.642C221.743 208.053 224.069 205.851 226.755 204.161C320.111 145.352 436.806 144.091 531.309 200.875C558.215 217.009 582.322 237.404 602.691 261.265C606.764 266.151 608.745 272.446 608.206 278.784C607.666 285.123 604.649 290.992 599.808 295.12C594.968 299.248 588.695 301.3 582.352 300.831C576.008 300.363 570.104 297.412 565.923 292.618C548.939 272.738 528.844 255.745 506.419 242.299C468.035 219.088 423.937 207.047 379.084 207.531C334.231 208.015 290.402 221.003 252.528 245.037C248.744 247.427 244.37 248.717 239.895 248.763C235.42 248.809 231.02 247.609 227.188 245.298Z"
        stroke="#2B4D70"
        strokeWidth="3"
      />
      <path
        d="M599.814 603.66C594.386 600.393 590.457 595.124 588.875 588.989C587.293 582.853 588.183 576.342 591.354 570.857C628.959 505.799 634.307 428.463 606.012 358.672C603.604 352.732 603.655 346.079 606.153 340.177C608.651 334.275 613.391 329.606 619.331 327.199C625.271 324.792 631.924 324.843 637.826 327.341C643.728 329.839 648.397 334.579 650.804 340.519C684.726 424.217 678.313 516.993 633.2 595.043C629.985 600.586 624.703 604.629 618.512 606.285C612.321 607.941 605.726 607.075 600.173 603.876L599.814 603.66Z"
        stroke="#2B4D70"
        strokeWidth="3"
      />
    </svg>
  );
};

export default MobileWeb;
