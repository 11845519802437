import React from "react";
import Modal from "./Modal";
import LandingLogo from "../icons/LandingLogo";
import { useLanguage } from "../../../Contexts/LanguageContext";

const SuccessModal = ({ successModal, setSuccessModal, name, uaeUserType }) => {
  const { language } = useLanguage();

  const handleCloseSuccess = () => {
    setSuccessModal(false);
  };
  return (
    <Modal
      isOpen={successModal}
      onClose={handleCloseSuccess}
      // title={<LandingLogo width={182} height={64} />}
      height={355}
      innerHeight={200}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          color: "#2B4D70",
          fontSize: 24,
          padding: 32,
          textAlign: "center",
          flexDirection: "column",
          // ...style,
        }}
      >
        <span>{uaeUserType === "SOP1" ? "" : name}</span>
        {language === "en" ? (
          <span
            style={{
              direction: "ltr",
              fontFamily: "Inter",
            }}
          >
            {uaeUserType === "SOP1"
              ? "You are not eligible to access this service. Your account is either not upgraded or you have a visitor account. Please contact UAEEI to access the services."
              : "Thank you for early registration. We'll inform you once your account is activated."}
          </span>
        ) : (
          <span
            style={{
              direction: "rtl",
              fontFamily: "cairo",
            }}
          >
            {uaeUserType === "SOP1"
              ? "أنت غير مؤهل للوصول إلى هذه الخدمة. إما أن حسابك لم تتم ترقيته أو لديك حساب زائر. يرجى الاتصال بـ UAEEI لتتمكن من الوصول إلى الخدمة."
              : " شكراً لك على التسجيل المبكر، سنقوم بإعلامك بمجرد تنشيط الحساب."}
          </span>
        )}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {uaeUserType === "SOP1" ? (
          <a
            style={{
              width: 118,
              height: 56,
              backgroundColor: "#00B783",
              color: "white",
              borderRadius: 8,
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 22,
              marginTop: language === "en" ? 10 : 5,
            }}
            href={process.env.REACT_APP_UAEPASS_LOGOUT}
            // onClick={handleCloseSuccess}
          >
            {language === "en" ? "Close" : "إغلاق"}
          </a>
        ) : (
          <button
            style={{
              width: 118,
              height: 56,
              backgroundColor: "#00B783",
              color: "white",
              borderRadius: 8,
              alignSelf: "center",
            }}
            onClick={handleCloseSuccess}
          >
            {language === "en" ? "Close" : "إغلاق"}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default SuccessModal;
// process.env.REACT_APP_UAEPASS_LOGOUT
