import React, { useEffect, useState } from "react";
import { useLanguage } from "../../Contexts/LanguageContext";
import LandingHeader from "./Components/LandingHeader";
import VideoBackground from "./Components/VideoBackground/VideoBackground";
import OverlayComponent from "./Components/VideoBackground/OverlayComponent";
import OverviewSection from "./Components/OverviewSection";
import AimSection from "./Components/AimSection";
import AdvantageSection from "./Components/AdvantageSection";
import GreenPoints from "./Components/GreenPoints";
import Objectives from "./Components/Objectives";
import MobileLaptopSection from "./Components/MobileLaptopSection";
import Partners from "./Components/Partners";
import { useCurrentWidth } from "react-socks";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Sponsers from "./Components/Sponsers";
import SuccessModal from "./Components/SuccessModal";
import LandingFrom from "./Components/LandingFrom";
import FailureModal from "./Components/FailureModal";
import Footer from "./Components/Footer";
import Lottie from "lottie-react";
import uaeSpinner from "../../Icons/animation/spinner.json";

const Landing = () => {
  const location = useLocation();
  const width = useCurrentWidth();
  const { language, switchLanguage } = useLanguage();
  const [isModalOpen, setModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [uaeUserType, setUaeUserType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const error = queryParams.get("error");

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      switchLanguage(lang);
    }
  }, []);

  useEffect(() => {
    if (error !== null) {
      setFailureModal(true);
    }
  }, [error]);

  useEffect(() => {
    const callUAEPassToken = async (code) => {
      try {
        setIsLoading(true);
        const res = await axios.get(process.env.REACT_APP_UAEPASS_API, {
          params: {
            code: code,
          },
        });
        if (res.data.success) {
          setUserFullName(res.data.fullname);
          setUaeUserType(res.data.userType);
          setSuccessModal(true);
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    if (code) {
      callUAEPassToken(code);
    }
  }, [code]);

  const openModal = () => {
    setModalOpen(true);
  };

  const videoUrl = "./videos/landingVideo.mp4";

  return (
    <div>
      <LandingHeader />
      <div id="home">
        <VideoBackground videoUrl={videoUrl} />
        <OverlayComponent
          openModal={openModal}
          code={code}
          uaeUserType={uaeUserType}
        />
      </div>
      <div
        id="overview"
        style={{
          height: "100%",
        }}
      >
        <OverviewSection />
      </div>
      <div
        style={{
          zIndex: 999,
          backgroundColor: "white",
        }}
      >
        <AimSection />
      </div>
      <div id="keyAdvantages">
        <AdvantageSection />
      </div>
      <GreenPoints />
      <Objectives />
      <MobileLaptopSection />
      <div
        style={{
          width: "100%",
          height: 350,
          display: "flex",
          flexDirection:
            width <= 1024
              ? "column"
              : width >= 1024 && language === "en"
              ? "row"
              : "row-reverse",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Partners />
        <Sponsers />
      </div>
      <Footer />
      <LandingFrom
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        setSuccessModal={setSuccessModal}
        setUserFullName={setUserFullName}
      />
      <SuccessModal
        name={userFullName}
        successModal={successModal}
        setSuccessModal={setSuccessModal}
        uaeUserType={uaeUserType}
      />
      <FailureModal
        error={error}
        failModal={failureModal}
        setFailModal={setFailureModal}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10000,
          }}
        >
          <Lottie animationData={uaeSpinner} loop />
        </div>
      )}
    </div>
  );
};

export default Landing;
