import React from "react";

const TipsCard = ({ image, tip, title, body }) => {
  return (
    <div className="flex gap-[12px] w-full -h-full items-center justify-start">
      <img src={image} alt="tip-cover" className="w-[120px] h-[96px] rounded-md object-cover"/>
      <div className="flex flex-col items-start">
        <span className="text-[#D19631] xs:text-[12px] sm:text-[12px] md:text-[16px] font-semibold">{tip}</span>
        <span className="text-[#1C1C1C] xs:text-[16px] sm:text-[16px] md:text-[18px] font-semibold">{title}</span>
        <p className="text-[#1C1C1C] xs:text-[12px] sm:text-[12px] md:text-[16px] font-normal">{body}</p>
      </div>
    </div>
  );
};

export default TipsCard;
