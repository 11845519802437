import React from "react";

const ProgressBar = ({ progressValue, month, total }) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full gap-[17px] items-center">
        <div className="xs:text-[12px] sm:text-[12px] md:text-[16px] text-[#9FA1A2] font-normal">{month}</div>
        <div className="xs:h-[8px] sm:h-[8px] md:h-[20px] rounded-lg w-full bg-[#E7ECF0]">
          <div
            className="h-full bg-[#2B4D70] rounded-lg"
            style={{
              width: `${(progressValue / total) * 100}%`,
            }}
          />
        </div>
      </div>
      <div className="xs:text-[10px] sm:text-[10px] md:text-[14px] w-full flex justify-end font-[600] text-[#7C7C7C]">
        {progressValue} Kg
      </div>
    </div>
  );
};

export default ProgressBar;
