export function calculateTotals(data) {
  const totals = {};

  for (const city in data) {
    if (data.hasOwnProperty(city)) {
      let totalElectricity = 0;
      let totalWater = 0;

      for (const year in data[city]) {
        if (data[city].hasOwnProperty(year)) {
          for (const month in data[city][year]) {
            if (data[city][year][month].hasOwnProperty("Electricity")) {
              totalElectricity += data[city][year][month].Electricity;
            }

            if (data[city][year][month].hasOwnProperty("Water")) {
              totalWater += data[city][year][month].Water;
            }
          }
        }
      }

      totals[city] = {
        electricity: totalElectricity,
        water: totalWater,
        combined: totalElectricity + totalWater,
      };
    }
  }

  return totals;
}

export function calculateTotalsForAllCities(data) {
  const totals = {
    electricity: 0,
    water: 0,
    combined: 0,
  };

  for (const city in data) {
    if (data.hasOwnProperty(city)) {
      totals["electricity"] += data[city].electricity || 0;
      totals["water"] += data[city].water || 0;
      totals["combined"] += data[city].combined || 0;
    }
  }

  return totals;
}

export function calculateTotalsForAllMonths(data) {
  const result = [];

  // Iterate through cities
  for (const city in data) {
    if (Object.hasOwnProperty.call(data, city)) {
      const cityData = data[city];

      // Iterate through years
      for (const year in cityData) {
        if (Object.hasOwnProperty.call(cityData, year)) {
          const yearData = cityData[year];

          // Iterate through months
          for (const month in yearData) {
            if (Object.hasOwnProperty.call(yearData, month)) {
              const monthData = yearData[month];

              // Find the corresponding entry in the result array or create a new one
              const entry = result.find(
                (item) => item.month === parseInt(month)
              );
              if (entry) {
                entry.totalElectricity += monthData.Electricity;
                entry.totalWater += monthData.Water;
              } else {
                result.push({
                  month: parseInt(month),
                  totalElectricity: monthData.Electricity,
                  totalWater: monthData.Water,
                });
              }
            }
          }
        }
      }
    }
  }

  return result;
}
