import AdSectionIcon1 from "../icons/AdSectionIcon1";
import AdSectionIcon2 from "../icons/AdSectionIcon2";
import AdSectionIcon4 from "../icons/AdSectionIcon4";
import AdSectionIcon5 from "../icons/AdSectionIcon5";
import AdSectionIcon3 from "./image-12.png";

export const sections = [
  {
    id: 1,
    enTitle1: "Incorporating Data-Driven",
    enTitle2: "Insights",
    arTitle: "دمج الرؤى القائمة على البيانات",
    arBody:
      'تعمل مبادرة "الهوية البيئية الإماراتية" على إمداد صنّاع القرار والمسؤولين بتحليلات بيانيّة دقيقة تسهم في تعزيز رسم السياسات والاستراتيجيات المسقبلية الناجعة.',
    enBody:
      "UAEEI initiative equips decision makers and officials with precise, data-supported analytics, enhancing the sophistication of policy development for more effective and informed strategies.",
    icon: <AdSectionIcon1 />,
    type: "icon",
  },
  {
    id: 2,
    enTitle1: "Environmental",
    enTitle2: "Awareness",
    arTitle: "الوعي البيئي",
    arBody:
      "من خلال قياس البصمة البيئية للأفراد، تزيد هذه المبادرة الوعي حول تأثير الخيارات اليومية على البيئة وتشجع على سلوك الوعي البيئي.",
    enBody:
      "UAEEI initiative equips decision makers and officials with precise, data-supported analytics, enhancing the sophistication of policy development for more effective and informed strategies.",
    type: "icon",
    icon: <AdSectionIcon2 />,
  },
  {
    id: 3,
    enTitle1: "Driving Sustainable",
    enTitle2: "Engagement",
    arTitle: "التأثير البيئي الإيجابي",
    arBody:
      'تهدف مبادرة "الهوية البيئية الإماراتية" إلى خفض معدل البصمة البيئية على مستوى الدولة، والنهوض نحو مستقبل أكثر اخضرارًا واستدامة وتحقيق فوائد ملموسة.',
    enBody:
      "UAEEI initiative introduces an incentive system that rewards sustainable practices, motivating individuals and businesses to reduce their environmental footprint.",
    image: AdSectionIcon3,
  },
  {
    id: 4,
    enTitle1: "Global Role",
    enTitle2: "Model",
    arTitle: "نموذج عالمي يُحتذى به",
    arBody:
      "تضع هذه المبادرة دولة الإمارات على مسار الريادة العالمية في مجال الإشراف البيئي، ممّا يجعلها قدوة ملهمة للدول الأخرى لتحذوا حذوها.",
    enBody:
      "UAEEI initiative positions the country as a global leader in environmental stewardship, setting an inspiring example for others to follow.",
    type: "icon",
    icon: <AdSectionIcon4 />,
  },
  {
    id: 5,
    enTitle1: "Positive Environmental ",
    enTitle2: "Impact",
    arTitle: "تعزيز المشاركة المستدامة",
    arBody:
      'تقدّم مبادرة "الهوية البيئية الإماراتية" نظام حوافز يكافئ الممارسات المستدامة، ويحفّز الأفراد والشركات على تقليل أثرهم البيئي.',
    enBody:
      "Ultimately, UAEEI initiative aims to reduce the national environmental footprint, driving progress towards a greener, more sustainable future with tangible benefits.",
    type: "icon",
    icon: <AdSectionIcon5 />,
  },
];
