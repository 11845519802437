import React, { useEffect } from "react";
import Modal from "../../Pages/Landing/Components/Modal";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import uaeSpinner from "../../Icons/animation/spinner.json";
import {
  editRewardRulesApi,
  getRulesByIdAPi,
} from "../../redux/Thunks/RewardsThunk";
import Select from "react-select";

const options = [
  { id: 1, label: "All", value: "All" },
  {
    id: 2,
    label: "ADDC",
    value: "ADDC",
  },
  {
    id: 3,
    label: "SIWA",
    value: "SIWA",
  },
  {
    id: 2,
    label: "Eithad",
    value: "Eithad",
  },
];

const periods = [
  { id: 1, label: "1 month", value: "1 month" },
  {
    id: 2,
    label: "3 months",
    value: "3 month",
  },
  {
    id: 3,
    label: "6 months",
    value: "6 month",
  },
  {
    id: 2,
    label: "1 year",
    value: "1 year",
  },
];

const EditRewardsRuleModal = ({
  isModalOpen,
  closeModal,
  selectedRewardToEdit,
}) => {
  const dispatch = useDispatch();
  const { selectedRule, selectedLoading } = useSelector(
    (state) => state.rewards
  );
  useEffect(() => {
    dispatch(getRulesByIdAPi({ id: selectedRewardToEdit }));
  }, [dispatch, selectedRewardToEdit]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("service_provider", data.provider);
      formData.append("emission", data.emission);
      formData.append("emission_period", data.period);
      formData.append("green_points", data.points);
      formData.append("id", selectedRewardToEdit);

      dispatch(editRewardRulesApi({ data: formData }));
      reset();
      closeModal();
    } catch (error) {
      console.error("An error occurred during form submission", error);
    }
  };

  console.log(selectedRule);

  useEffect(() => {
    if (selectedRule) {
      setValue("provider", selectedRule.service_provider);
      setValue("emission", selectedRule.emission);
      setValue("period", selectedRule.emission_period);
      setValue("points", selectedRule.green_points);
    }
  }, [selectedRule, setValue]);
  if (selectedLoading)
    return (
      <div className="w-full h-[50%] flex justify-center items-center">
        <Lottie animationData={uaeSpinner} loop />
      </div>
    );
  return (
    <Modal
      closeIcon
      isOpen={isModalOpen}
      onClose={() => {
        reset();
        closeModal();
      }}
      closeOnOverlayClick={false}
      title="Edit Rewards Rule"
      customTitle
      circleClose
      height={500 + Object.keys(errors).length * 25}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 30,
          gap: 15,
        }}
      >
        <div className="w-full flex flex-col">
          <label
            htmlFor="provider"
            className="text-[14px] font-[600] font-inter"
          >
            Service Provider
          </label>
          <Controller
            control={control}
            id="provider"
            name="provider"
            rules={{
              required: {
                value: true,
                message: "Service Provider is required",
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    height: 40,
                    backgroundColor: "#f8f8f8",
                    borderRadius: 8,
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: 8,
                    backgroundColor: "#f8f8f8",
                  }),
                }}
                defaultValue={options.find(
                  (item) => item.value === selectedRule?.service_provider
                )}
                inputRef={ref}
                value={value ? options.find((x) => x.value === value) : value}
                onChange={(option) => onChange(option ? option.value : option)}
                options={options}
              />
            )}
          />
          {errors["provider"] && (
            <p className="text-red-500">{errors["provider"].message}</p>
          )}
        </div>
        <div className="w-full flex flex-col">
          <label
            htmlFor="emission"
            className="text-[14px] font-[600] font-inter"
          >
            Reduced Emission
          </label>
          <div className="w-full flex items-center gap-1">
            <input
              id="emission"
              name="emission"
              type="number"
              step="0.1"
              min={0}
              max={100}
              className="border-[1px] h-[40px]  w-[95%] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
              {...register("emission", {
                required: {
                  value: true,
                  message: "Reduced Emission is required",
                },
              })}
            />
            %
          </div>
          {errors["emission"] && (
            <p className="text-red-500">{errors["emission"].message}</p>
          )}
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="period" className="text-[14px] font-[600] font-inter">
            Emission Period
          </label>
          <Controller
            control={control}
            id="period"
            name="period"
            rules={{
              required: {
                value: true,
                message: "Emmission Period is required",
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    height: 40,
                    backgroundColor: "#f8f8f8",
                    borderRadius: 8,
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: 8,
                    backgroundColor: "#f8f8f8",
                  }),
                }}
                inputRef={ref}
                defaultValue={periods.find(
                  (item) => item.value === selectedRule?.emission_period
                )}
                value={value ? periods.find((x) => x.value === value) : value}
                onChange={(option) => onChange(option ? option.value : option)}
                options={periods}
              />
            )}
          />
          {errors["period"] && (
            <p className="text-red-500">{errors["period"].message}</p>
          )}
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="points" className="text-[14px] font-[600] font-inter">
            Green Points
          </label>
          <input
            id="points"
            name="points"
            type="number"
            step="0.1"
            min={0}
            max={100}
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("points", {
              required: {
                value: true,
                message: "Green Points is required",
              },
            })}
          />

          {errors["points"] && (
            <p className="text-red-500">{errors["points"].message}</p>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <button
            style={{
              backgroundColor: "#2B4D70",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              width: 128,
              height: 40,
              borderRadius: 8,
            }}
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditRewardsRuleModal;
