import React from "react";

const MenuClose = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="32"
        y="32"
        width="32"
        height="32"
        rx="16"
        transform="rotate(-180 32 32)"
        fill="#E4E4E4"
      />
      <path
        d="M10.7114 10.7114L21.12 21.12"
        stroke="#2B4D70"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M21.12 10.7114L10.7114 21.12"
        stroke="#2B4D70"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MenuClose;
