import React, { useState } from "react";
import { ArrowContainer, Popover } from "react-tiny-popover";

const MapLocations = ({ fillColor = "#B22222", styles, data }) => {
  const mapsLocation = [
    { id: 1, lat: "297", long: "160", city: "Al-Ain" },
    { id: 2, lat: "327", long: "98", city: "Fujairah" },
    { id: 3, lat: "307", long: "55", city: "Ras Al Khaimah" },
    { id: 4, lat: "290", long: "70", city: "Umm Al Quwain" },
    { id: 5, lat: "275", long: "80", city: "Ajman" },
    { id: 6, lat: "265", long: "90", city: "Sharjah" },
    { id: 7, lat: "255", long: "98", city: "Dubai" },
    { id: 8, lat: "215", long: "141", city: "Abu Dhabi" },
  ];

  const [popoverStates, setPopoverStates] = useState(
    mapsLocation.map(() => false)
  );

  const handlePopoverToggle = (index) => {
    const newPopoverStates = [...popoverStates];
    newPopoverStates[index] = !newPopoverStates[index];
    setPopoverStates(newPopoverStates);
  };
  return (
    <svg
      width="392"
      height="282"
      viewBox="0 0 392 282"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      {mapsLocation.map((location, index) => (
        <Popover
          key={location.id}
          isOpen={popoverStates[index]}
          positions={["top", "bottom", "left", "right"]}
          onClickOutside={() => handlePopoverToggle(index)}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={fillColor}
              arrowSize={10}
              arrowStyle={{ opacity: 0.7 }}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <div
                className="flex flex-col w-[250px] p-2 rounded-lg"
                style={{
                  backgroundColor: fillColor,
                  color: "white",
                }}
              >
                <div className="w-full flex justify-between items-center my-[2px] text-[16px] font-medium">
                  <span>City</span>
                  <span>{location.city}</span>
                </div>
                <div className="w-full flex justify-between items-center my-[2px] text-[16px] font-medium">
                  <span>Total</span>
                  {data?.length > 0 ? (
                    <span>
                      {data?.find((item) => item.city === location.city)
                        ?.combined / 1000}{" "}
                      t
                    </span>
                  ) : (
                    <span>no data</span>
                  )}
                </div>
                <div className="w-full flex justify-between items-center my-[2px] text-[16px] font-medium">
                  <span>Water</span>
                  {data?.length > 0 ? (
                    <span>
                      {(
                        (data?.find((item) => item.city === location.city)
                          ?.water /
                          data?.find((item) => item.city === location.city)
                            ?.combined) *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                  ) : (
                    <span>no data</span>
                  )}
                </div>
                <div className="w-full flex justify-between items-center my-[2px] text-[16px] font-medium">
                  <span>Electricity</span>
                  {data?.length > 0 ? (
                    <span>
                      {(
                        (data?.find((item) => item.city === location.city)
                          ?.electricity /
                          data?.find((item) => item.city === location.city)
                            ?.combined) *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                  ) : (
                    <span>no data</span>
                  )}
                </div>
              </div>
            </ArrowContainer>
          )}
        >
          <circle
            key={location.id}
            cx={location.lat}
            cy={location.long}
            r="4"
            fill={fillColor}
            style={{
              cursor: "pointer",
            }}
            onClick={() => handlePopoverToggle(index)}
          />
        </Popover>
      ))}
    </svg>
  );
};

export default MapLocations;
