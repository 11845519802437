import React from "react";

const Widget = ({
  bg,
  title,
  icon,
  value,
  sign,
  percentage,
  percentageIcon,
}) => {
  return (
    <div
      className={`flex flex-col p-[24px] rounded-[16px] xs:w-full sm:-w-full sm:mt-3 xs:mt-3 md:mt-3 laptop:mt-0 lg:mx-0 lg:w-[250px] md:mx-2  md:w-[100%] h-[116px] `}
      style={{
        backgroundColor: bg,
      }}
    >
      <div className="flex justify-between items-center w-full h-1/5">
        <span className="md:text-[14px] lg:text-[16px] font-medium text-[#1C1C1C]">
          {title}{" "}
        </span>
        {icon}
      </div>
      <div className="flex justify-between items-center w-full h-4/5">
        <span className="md:text-[16px] lg:text-[24px] font-semibold text-[#1C1C1C]">
          {value} %
        </span>
        <div className="flex flex-col w-1/2 justify-end items-end">
          <div className=" flex items-end h-full justify-end md:text-[12px] lg:text-[14px] font-normal text-[#1C1C1C]">
            {sign} {percentage}% {percentageIcon}
          </div>
          <span className="md:text-[12px] lg:text-[14px] font-normal text-[#1C1C1C]">
            Variance
          </span>
        </div>
      </div>
    </div>
  );
};

export default Widget;
