import React from "react";

const LandingLogo = ({ width = 123, height = 43 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 123 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.5408 27.5199C54.5603 28.732 54.3232 29.9353 53.844 31.0562C53.4099 32.0622 52.7651 32.9711 51.9499 33.726C51.1289 34.4697 50.1602 35.0456 49.102 35.4191C46.7836 36.2159 44.2506 36.2159 41.9322 35.4191C40.8689 35.0417 39.893 34.4664 39.0601 33.726C38.234 32.9783 37.585 32.0676 37.1575 31.0562C36.6894 29.9326 36.4583 28.7305 36.4777 27.5199V13.8309H40.4707V27.3874C40.4753 27.9526 40.5611 28.5146 40.7258 29.0571C40.8972 29.6387 41.1824 30.1833 41.5662 30.6615C41.9834 31.173 42.5151 31.5871 43.1216 31.8729C43.8784 32.1861 44.6934 32.3477 45.5171 32.3477C46.3408 32.3477 47.1558 32.1861 47.9126 31.8729C48.5189 31.5869 49.0503 31.1728 49.4674 30.6615C49.8512 30.1844 50.1341 29.6392 50.2999 29.0571C50.459 28.5136 50.542 27.9521 50.5466 27.3874V13.8309H54.5396L54.5408 27.5199Z"
        fill="#2B4D70"
      />
      <path
        d="M64.8033 13.8309H68.2528L77.9209 35.46H73.3674L71.277 30.5155H61.5416L59.5021 35.46H55.0334L64.8033 13.8309ZM69.8676 27.207L66.4175 18.4122L62.9031 27.2041L69.8676 27.207Z"
        fill="#2B4D70"
      />
      <path
        d="M80.385 13.8309H95.2694V17.3029H84.3774V22.6397H94.692V26.1111H84.3774V31.9885H95.8136V35.46H80.385V13.8309Z"
        fill="#2B4D70"
      />
      <path
        d="M99.7388 13.8309H114.623V17.3029H103.732V22.6397H114.046V26.1111H103.732V31.9885H115.167V35.46H99.7388V13.8309Z"
        fill="#00B783"
      />
      <path d="M119.007 13.8309H123V35.46H119.007V13.8309Z" fill="#00B783" />
      <path
        d="M1.42634 31.0263C1.20712 31.0259 0.995133 30.9507 0.82793 30.8141C0.660727 30.6775 0.549094 30.4883 0.512807 30.28C0.47652 30.0716 0.517919 29.8577 0.629672 29.676C0.741424 29.4942 0.91632 29.3565 1.12338 29.2871C3.62548 28.4499 5.87437 27.0305 7.67043 25.1548C9.46649 23.2791 10.7542 21.0051 11.4192 18.5348C11.4499 18.4211 11.5035 18.3143 11.5771 18.2206C11.6506 18.1269 11.7426 18.048 11.8477 17.9886C11.9529 17.9291 12.0692 17.8902 12.19 17.874C12.3107 17.8578 12.4336 17.8647 12.5516 17.8943C12.6696 17.9239 12.7804 17.9756 12.8777 18.0465C12.975 18.1173 13.0568 18.2059 13.1185 18.3073C13.1803 18.4086 13.2207 18.5206 13.2374 18.637C13.2542 18.7534 13.247 18.8718 13.2163 18.9855C12.4749 21.7403 11.0391 24.2762 9.03627 26.3679C7.03344 28.4597 4.52559 30.0426 1.73536 30.9761C1.63611 31.0098 1.53157 31.0268 1.42634 31.0263Z"
        fill="#2B4D70"
      />
      <path
        d="M12.745 28.0786C12.5713 28.0786 12.4011 28.0315 12.2537 27.9429C12.1064 27.8542 11.9878 27.7275 11.9115 27.5772C11.8352 27.4268 11.8042 27.2589 11.822 27.0924C11.8399 26.9259 11.9058 26.7676 12.0124 26.6354C14.7954 23.2035 16.3 18.9697 16.2842 14.6144L16.2805 14.4282C16.2816 14.2706 16.2505 14.1144 16.1889 13.9684C16.1273 13.8225 16.0365 13.6896 15.9216 13.5775C15.8067 13.4654 15.6701 13.3761 15.5194 13.3149C15.3688 13.2536 15.207 13.2216 15.0435 13.2205C14.88 13.2195 14.7179 13.2495 14.5664 13.3088C14.4149 13.3682 14.2771 13.4557 14.1607 13.5664C14.0443 13.677 13.9517 13.8087 13.8881 13.9539C13.8245 14.0991 13.7913 14.2549 13.7902 14.4124V14.6138C13.7902 15.1183 13.7672 15.6285 13.7229 16.1318C13.6946 16.3628 13.5741 16.5742 13.3868 16.721C13.1996 16.8679 12.9604 16.9387 12.7198 16.9185C12.4793 16.8983 12.2564 16.7887 12.0983 16.6128C11.9403 16.437 11.8595 16.2087 11.8731 15.9765C11.913 15.5269 11.9336 15.0692 11.9336 14.6162V14.4147C11.9279 14.0187 12.0039 13.6255 12.1572 13.2581C12.3105 12.8906 12.5381 12.5561 12.8267 12.2741C13.1153 11.9921 13.4592 11.7682 13.8384 11.6153C14.2176 11.4625 14.6246 11.3838 15.0357 11.3838C15.4467 11.3838 15.8537 11.4625 16.2329 11.6153C16.6121 11.7682 16.956 11.9921 17.2446 12.2741C17.5332 12.5561 17.7608 12.8906 17.9141 13.2581C18.0674 13.6255 18.1434 14.0187 18.1377 14.4147L18.1413 14.6004C18.1612 19.3592 16.5189 23.9861 13.4787 27.7365C13.3916 27.8437 13.2802 27.9302 13.153 27.9895C13.0258 28.0488 12.8863 28.0793 12.745 28.0786Z"
        fill="#2B4D70"
      />
      <path
        d="M2.71037 34.9514C2.48994 34.9516 2.27663 34.8762 2.10862 34.7387C1.94061 34.6012 1.82886 34.4106 1.79336 34.201C1.75787 33.9914 1.80096 33.7764 1.9149 33.5946C2.02885 33.4128 2.20622 33.276 2.41528 33.2087C5.29115 32.2717 7.91487 30.7304 10.0965 28.6963C10.2751 28.5404 10.5095 28.4574 10.7503 28.4649C10.9911 28.4724 11.2195 28.5698 11.3872 28.7365C11.5549 28.9031 11.6488 29.1261 11.6492 29.3583C11.6495 29.5904 11.5562 29.8136 11.389 29.9807C9.00794 32.1998 6.14481 33.8813 3.00666 34.9035C2.9113 34.9351 2.81119 34.9512 2.71037 34.9514Z"
        fill="#2B4D70"
      />
      <path
        d="M1.20401 26.8456C0.992136 26.8456 0.786633 26.7758 0.621588 26.6478C0.456543 26.5198 0.341872 26.3413 0.2966 26.1418C0.251329 25.9424 0.278176 25.7341 0.372687 25.5513C0.467198 25.3686 0.623696 25.2226 0.816217 25.1373C2.89617 24.2168 4.66053 22.7438 5.90231 20.8912C5.96851 20.7925 6.05423 20.7074 6.15457 20.6407C6.25491 20.574 6.3679 20.527 6.4871 20.5023C6.60629 20.4777 6.72936 20.4759 6.84927 20.4971C6.96918 20.5183 7.08358 20.562 7.18595 20.6258C7.28832 20.6896 7.37665 20.7722 7.44589 20.8689C7.51513 20.9655 7.56393 21.0744 7.5895 21.1893C7.61507 21.3041 7.61692 21.4227 7.59493 21.5382C7.57294 21.6538 7.52754 21.764 7.46134 21.8626C6.02809 24.0013 3.99122 25.7014 1.58998 26.7633C1.469 26.8173 1.33732 26.8453 1.20401 26.8456Z"
        fill="#2B4D70"
      />
      <path
        d="M8.01748 19.6557C7.86894 19.6558 7.72255 19.6216 7.59058 19.5559C7.45861 19.4902 7.34493 19.395 7.25909 19.2782C7.17324 19.1614 7.11775 19.0264 7.09725 18.8847C7.07676 18.7429 7.09188 18.5985 7.14132 18.4635C7.59323 17.2272 7.82333 15.9257 7.82177 14.6149C7.82177 12.7733 8.58106 11.007 9.9326 9.70476C11.2841 8.4025 13.1172 7.6709 15.0286 7.6709C16.94 7.6709 18.773 8.4025 20.1246 9.70476C21.4761 11.007 22.2354 12.7733 22.2354 14.6149C22.2343 15.512 22.185 16.4084 22.0876 17.3005C22.0753 17.4177 22.0391 17.5315 21.9811 17.6352C21.923 17.7389 21.8443 17.8305 21.7494 17.9048C21.6546 17.9791 21.5454 18.0346 21.4282 18.0681C21.3109 18.1016 21.188 18.1125 21.0664 18.1001C20.9448 18.0878 20.827 18.0524 20.7196 17.996C20.6123 17.9396 20.5176 17.8633 20.4409 17.7715C20.3642 17.6798 20.3071 17.5743 20.2729 17.4612C20.2386 17.3481 20.2279 17.2296 20.2413 17.1125C20.3319 16.2825 20.3774 15.4484 20.3777 14.6138C20.3777 13.2468 19.8141 11.9359 18.8109 10.9693C17.8078 10.0028 16.4472 9.45974 15.0286 9.45974C13.6099 9.45974 12.2494 10.0028 11.2462 10.9693C10.2431 11.9359 9.67953 13.2468 9.67953 14.6138C9.68084 16.1275 9.41446 17.6303 8.89183 19.0578C8.82816 19.2324 8.70988 19.3836 8.55329 19.4907C8.3967 19.5978 8.2095 19.6554 8.01748 19.6557Z"
        fill="#2B4D70"
      />
      <path
        d="M4.80852 38.4158C4.59071 38.4161 4.37973 38.3426 4.21248 38.2081C4.04524 38.0737 3.93239 37.8869 3.89369 37.6803C3.85498 37.4738 3.89287 37.2607 4.00074 37.0784C4.10861 36.8961 4.27959 36.7561 4.48375 36.683C7.12036 35.7336 9.56614 34.3509 11.7124 32.5962C11.9011 32.4489 12.1423 32.3787 12.3841 32.4007C12.6259 32.4226 12.8492 32.535 13.0061 32.7136C13.163 32.8923 13.241 33.123 13.2234 33.3564C13.2059 33.5899 13.0941 33.8074 12.9121 33.9624C10.6022 35.8489 7.97058 37.3355 5.1339 38.3562C5.03005 38.3948 4.91982 38.415 4.80852 38.4158Z"
        fill="#2B4D70"
      />
      <path
        d="M12.6377 38.8449C11.411 39.6761 10.1138 40.4063 8.75986 41.0279C8.27088 40.7056 7.72979 40.2642 7.11963 39.7756C8.68468 39.1311 10.1745 38.3288 11.564 37.3819C11.7652 37.2565 12.0089 37.2109 12.2442 37.2546C12.4794 37.2984 12.688 37.4281 12.8263 37.6166C12.9646 37.8051 13.0219 38.0379 12.9862 38.2661C12.9505 38.4944 12.8245 38.7006 12.6347 38.8414L12.6377 38.8449Z"
        fill="#2B4D70"
      />
      <path
        d="M1.10997 22.6087C0.912601 22.6091 0.720266 22.5488 0.560889 22.4366C0.401512 22.3244 0.283391 22.1662 0.223679 21.9849C0.163966 21.8037 0.165767 21.6088 0.228824 21.4286C0.291881 21.2484 0.41291 21.0922 0.574337 20.9828C1.63417 20.264 2.49931 19.3103 3.09696 18.2022C3.69462 17.0941 4.00729 15.8638 4.00869 14.615C4.00844 14.0283 4.05789 13.4425 4.15654 12.8635C4.17643 12.7476 4.21982 12.6366 4.28424 12.5368C4.34866 12.437 4.43285 12.3505 4.53198 12.2821C4.63112 12.2137 4.74328 12.1647 4.86204 12.1381C4.98081 12.1114 5.10386 12.1076 5.22417 12.1267C5.34448 12.1459 5.4597 12.1877 5.56324 12.2498C5.66678 12.3118 5.75661 12.393 5.82762 12.4885C5.89862 12.584 5.94941 12.6921 5.97707 12.8065C6.00474 12.9209 6.00874 13.0395 5.98884 13.1554C5.9075 13.638 5.86697 14.1261 5.86766 14.615C5.86626 16.1507 5.48196 17.6637 4.74711 19.0264C4.01226 20.3892 2.94837 21.5619 1.645 22.4459C1.48842 22.5521 1.3015 22.609 1.10997 22.6087Z"
        fill="#2B4D70"
      />
      <path
        d="M6.02941 11.0718C5.8841 11.072 5.74081 11.039 5.61133 10.9754C5.50249 10.9226 5.40552 10.8496 5.32598 10.7606C5.24644 10.6717 5.18589 10.5685 5.14779 10.4569C5.10969 10.3454 5.09478 10.2277 5.10394 10.1106C5.11309 9.99348 5.14611 9.87927 5.20112 9.77448C6.41025 7.46689 8.441 5.65572 10.9221 4.67215C13.4031 3.68857 16.1687 3.59832 18.7131 4.41788C18.9466 4.49337 19.1393 4.6551 19.249 4.86751C19.3586 5.07992 19.3762 5.3256 19.2979 5.55051C19.2195 5.77541 19.0517 5.96111 18.8312 6.06677C18.6108 6.17243 18.3558 6.18938 18.1224 6.1139C16.0011 5.43123 13.6957 5.50682 11.6276 6.32686C9.55938 7.14691 7.86651 8.65665 6.85831 10.5802C6.78102 10.7281 6.66258 10.8524 6.5162 10.9392C6.36983 11.026 6.20129 11.0719 6.02941 11.0718Z"
        fill="#2B4D70"
      />
      <path
        d="M25.3948 15.3389C25.1514 15.3386 24.9178 15.2463 24.7443 15.0817C24.5708 14.9172 24.4713 14.6936 24.4672 14.4591C24.4469 13.0937 24.1012 11.7512 23.4568 10.5351C22.8123 9.31903 21.8864 8.26195 20.7505 7.44554C20.5536 7.30293 20.4236 7.09082 20.3891 6.85585C20.3545 6.62089 20.4183 6.38233 20.5663 6.19265C20.7143 6.00297 20.9344 5.8777 21.1783 5.84442C21.4221 5.81114 21.6697 5.87255 21.8666 6.01516C23.2288 6.99472 24.3391 8.2628 25.112 9.7215C25.8848 11.1802 26.2994 12.7905 26.3237 14.4281C26.3276 14.6654 26.2337 14.8945 26.0625 15.0651C25.8913 15.2357 25.6569 15.334 25.4106 15.3383L25.3948 15.3389Z"
        fill="#2B4D70"
      />
      <path
        d="M29.1665 12.982C28.9504 12.9811 28.7413 12.9077 28.5754 12.7742C28.4095 12.6408 28.2971 12.4558 28.2576 12.251C27.6855 9.30944 26.0641 6.65312 23.6741 4.7417C21.2841 2.83029 18.2758 1.78407 15.1697 1.78407C12.0637 1.78407 9.05536 2.83029 6.66533 4.7417C4.2753 6.65312 2.65395 9.30944 2.08184 12.251C2.03416 12.4819 1.89395 12.6855 1.69161 12.8175C1.48927 12.9495 1.24109 12.9994 1.00085 12.9563C0.760613 12.9132 0.547676 12.7806 0.408178 12.5873C0.26868 12.394 0.213858 12.1555 0.255598 11.9235C0.908183 8.57238 2.75588 5.54641 5.47904 3.36908C8.20221 1.19175 11.6296 0 15.1682 0C18.7068 0 22.1342 1.19175 24.8574 3.36908C27.5805 5.54641 29.4282 8.57238 30.0808 11.9235C30.1032 12.039 30.1018 12.1577 30.0766 12.2726C30.0514 12.3876 30.0029 12.4967 29.9339 12.5936C29.8649 12.6905 29.7768 12.7733 29.6745 12.8374C29.5722 12.9014 29.4579 12.9454 29.338 12.9668C29.2814 12.9771 29.224 12.9822 29.1665 12.982Z"
        fill="#2B4D70"
      />
      <path
        d="M14.6499 42.9878C10.7596 42.8566 7.07012 41.2906 4.34375 38.6133C1.61739 35.936 0.0623972 32.3521 0 28.6017V14.8719C0 14.6346 0.0978277 14.407 0.271969 14.2392C0.44611 14.0715 0.682296 13.9772 0.928569 13.9772C1.17484 13.9772 1.41103 14.0715 1.58517 14.2392C1.75931 14.407 1.85714 14.6346 1.85714 14.8719V28.3869C1.86189 31.6261 3.13724 34.7438 5.4275 37.1151C7.71776 39.4863 10.8536 40.9357 14.2064 41.1727C14.3288 41.7886 14.4706 42.3911 14.6499 42.9878Z"
        fill="#00B783"
      />
      <path
        d="M29.4259 14.3236C29.1962 14.4129 28.9617 14.5087 28.7382 14.6038L28.4782 14.7112C24.0936 16.6009 20.3698 19.6723 17.7558 23.555C15.1418 27.4377 13.7492 31.9661 13.7459 36.5942C13.7438 38.1319 13.8972 39.666 14.2039 41.1749C14.3251 41.7891 14.4699 42.3916 14.6499 42.9883C14.8232 43 14.9971 43 15.1698 43C15.64 43 16.1047 42.9819 16.5634 42.9416C20.2885 42.6048 23.7536 40.9556 26.2949 38.3098C28.8363 35.6641 30.2752 32.2079 30.336 28.6034V13.9955C30.0257 14.0953 29.7222 14.2121 29.4259 14.3236Z"
        fill="#00B783"
      />
      <path
        d="M15.7637 40.6964C24.678 32.416 20.1075 22.5861 29.1169 15.5475C27.5222 17.3587 26.2549 19.4154 25.3681 21.6316C22.699 29.0333 23.4037 35.6089 15.7637 40.6964Z"
        fill="white"
      />
    </svg>
  );
};

export default LandingLogo;
