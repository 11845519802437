import React from "react";
import CloseIcon from "../icons/CloseIcon";
import { useLanguage } from "../../../Contexts/LanguageContext";
import CircleClose from "../../../Icons/CircleClose";

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  closeIcon,
  height = 650,
  innerHeight = 550,
  closeOnOverlayClick = true,
  customTitle = false,
  circleClose = false,
}) => {
  const { language } = useLanguage();

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999999999,
      }}
      className="modal-overlay"
      onClick={(e) => {
        if (closeOnOverlayClick) {
          handleOverlayClick(e);
        }
      }}
    >
      <div
        style={{
          background: "white",
          margin: "10px",
          borderRadius: "8px",
          position: "relative",
          width: "100%",
          maxWidth: 485,
          height: height,
        }}
        className="modal"
      >
        <div
          style={{
            padding: "24px 24px 0 24px",
            background: "none",
            border: "none",
            cursor: "pointer",
            position: "absolute",
            top: "0px",
            display: "flex",
            flexDirection: language === "en" ? "row" : "row-reverse",
            justifyContent: !closeIcon ? "center" : "space-between",
            alignItems: "center",
            width: "100%",
            height: 75,
          }}
        >
          {customTitle ? (
            <span className="text-[20px] font-[600] font-inter">{title}</span>
          ) : (
            title
          )}

          {closeIcon && (
            <button onClick={onClose}>
              {circleClose ? <CircleClose /> : <CloseIcon />}
            </button>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            top: 75,
            width: "100%",
            height: innerHeight,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
