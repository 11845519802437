import React from "react";

const SortIconUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M16.4745 6.69162C16.5917 6.80883 16.751 6.875 16.9167 6.875C17.0825 6.875 17.2415 6.80915 17.3587 6.69194C17.4759 6.57473 17.5417 6.41576 17.5417 6.25C17.5417 6.08424 17.4759 5.92527 17.3587 5.80806L14.8587 3.30806C14.7415 3.19085 14.5825 3.125 14.4167 3.125C14.251 3.125 14.092 3.19085 13.9748 3.30806L11.4748 5.80806C11.3576 5.92527 11.2917 6.08424 11.2917 6.25C11.2917 6.26001 11.292 6.27002 11.2925 6.28002C11.2999 6.43522 11.3649 6.58207 11.4748 6.69194C11.592 6.80915 11.751 6.875 11.9167 6.875C12.0825 6.875 12.2415 6.80915 12.3587 6.69194L14.4167 4.63388L16.4745 6.69162Z"
        fill="#9F9F9F"
      />
      <path
        d="M15.0417 16.25V3.75C15.0417 3.40482 14.7619 3.125 14.4167 3.125C14.0716 3.125 13.7917 3.40482 13.7917 3.75V16.25C13.7917 16.5952 14.0716 16.875 14.4167 16.875C14.7619 16.875 15.0417 16.5952 15.0417 16.25Z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

export default SortIconUp;
