export function getLast3Months() {
  const currentDate = new Date();
  const months = [];

  for (let i = 2; i >= 0; i--) {
    const date = new Date(currentDate);
    date.setMonth(currentDate.getMonth() - i);
    const monthName = date.toLocaleString("default", { month: "long" });
    months.push({
      label: monthName,
      value: monthName.toLocaleLowerCase(),
      type: "month",
    });
  }

  return months;
}

export const monthToNumber = (monthName) => {
  const months = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  // Convert the input month name to title case (first letter uppercase, rest lowercase)
  const formattedMonth =
    monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase();

  // Check if the provided month name is valid
  if (months.hasOwnProperty(formattedMonth)) {
    return months[formattedMonth];
  } else {
    return "Invalid month";
  }
};

export function getPreviousMonths(numberOfMonths) {
  const currentDate = new Date();
  const months = [];

  for (let i = numberOfMonths - 1; i >= 0; i--) {
    const date = new Date(currentDate);
    date.setMonth(currentDate.getMonth() - i);
    const monthName = date.toLocaleString("default", { month: "long" });
    months.push(monthName);
  }

  return months;
}

export const getStartAndEndMonths = (selectedOption) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
  const currentYear = currentDate.getFullYear();

  let startMonth = currentMonth;
  let endMonth = currentMonth;
  let startYear = currentYear;
  let endYear = currentYear;

  if (selectedOption.type === "monthsGroup") {
    const totalMonths = selectedOption.value;
    endMonth -= totalMonths;

    while (endMonth <= 0) {
      endMonth += 12;
      startYear--;
    }
  } else if (selectedOption.type === "month") {
    startMonth = monthToNumber(selectedOption.value);
    endMonth = monthToNumber(selectedOption.value);
  }

  // Swap startMonth and endMonth values
  [startMonth, endMonth] = [endMonth, startMonth];

  return {
    startMonth,
    endMonth,
    startYear,
    endYear,
  };
};

export function getCurrentAndPreviousMonth() {
  const currentDate = new Date();

  const currentMonthName = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(currentDate);

  const currentYear = currentDate.getFullYear();

  currentDate.setMonth(currentDate.getMonth() - 11);
  const date = new Date(currentDate);
  const monthIndex = date.getMonth();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = months[monthIndex];

  // Get the year
  const year = date.getFullYear();

  return {
    currentMonth: currentMonthName,
    previousMonth: monthName,
    preYear: year,
    currentYear: currentYear,
  };
}

export function getMonthName(monthNumber) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Check if the provided month number is valid
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "Invalid month number";
  }
}

export function getLastThreeMonths() {
  const currentDate = new Date();

  const currentMonthName = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(currentDate);

  const currentYear = currentDate.getFullYear();

  currentDate.setMonth(currentDate.getMonth() - 2);
  const date = new Date(currentDate);
  const monthIndex = date.getMonth();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = months[monthIndex];

  // Get the year
  const year = date.getFullYear();

  return {
    currentMonth: currentMonthName,
    previousMonth: monthName,
    preYear: year,
    currentYear: currentYear,
  };
}
