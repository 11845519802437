import React from "react";

const WasteIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 5.29006C2.25 4.92672 2.50903 4.63217 2.82857 4.63217L4.82675 4.63182C5.22376 4.62038 5.57402 4.33333 5.70911 3.90867C5.71266 3.8975 5.71674 3.88373 5.73139 3.83376L5.81749 3.54C5.87017 3.35989 5.91607 3.20297 5.98031 3.06271C6.23407 2.5086 6.70356 2.12382 7.2461 2.02531C7.38343 2.00037 7.52887 2.00048 7.69582 2.0006H10.3043C10.4713 2.00048 10.6167 2.00037 10.754 2.02531C11.2966 2.12382 11.7661 2.5086 12.0198 3.06271C12.0841 3.20297 12.13 3.35989 12.1826 3.54L12.2687 3.83376C12.2833 3.88373 12.2875 3.8975 12.291 3.90867C12.4262 4.33333 12.8459 4.62073 13.2428 4.63217H15.1714C15.4909 4.63217 15.75 4.92672 15.75 5.29006C15.75 5.6534 15.4909 5.94795 15.1714 5.94795H2.82857C2.50903 5.94795 2.25 5.6534 2.25 5.29006Z"
        fill="#9FA1A2"
      />
      <path
        d="M8.6967 17.0005H9.3033C11.3903 17.0005 12.4339 17.0005 13.1123 16.336C13.7908 15.6716 13.8602 14.5817 13.999 12.4018L14.1991 9.26092C14.2744 8.07817 14.3121 7.48683 13.9717 7.11208C13.6313 6.73734 13.0565 6.73734 11.907 6.73734H6.09304C4.94345 6.73734 4.36866 6.73734 4.02829 7.11208C3.68791 7.48683 3.72558 8.07817 3.80091 9.26092L4.00094 12.4018C4.13977 14.5817 4.20919 15.6716 4.88767 16.336C5.56615 17.0005 6.60967 17.0005 8.6967 17.0005Z"
        fill="#9FA1A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.06907 9.11114C7.37819 9.07859 7.65382 9.31604 7.68472 9.64139L8.05972 13.5888C8.09062 13.9141 7.8651 14.2043 7.55602 14.2369C7.2469 14.2694 6.97124 14.032 6.94033 13.7066L6.56533 9.75921C6.53442 9.43386 6.75995 9.14369 7.06907 9.11114Z"
        fill="#9FA1A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.931 9.11114C11.2401 9.14369 11.4656 9.43386 11.4347 9.75921L11.0597 13.7066C11.0288 14.032 10.7532 14.2694 10.444 14.2369C10.135 14.2043 9.90943 13.9141 9.94033 13.5888L10.3153 9.64139C10.3462 9.31604 10.6219 9.07859 10.931 9.11114Z"
        fill="#9FA1A2"
      />
    </svg>
  );
};

export default WasteIcon;
