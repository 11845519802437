import React, { useEffect, useState } from "react";
import AdminLoginIcon from "../../Icons/AdminLoginIcon";
import { useCurrentWidth } from "react-socks";
import { useDispatch, useSelector } from "react-redux";
import { checkLocalStorage, loginAdmin } from "../../redux/Slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const AdminLogin = () => {
  const width = useCurrentWidth();
  const { isLoggedIn, admin } = useSelector((state) => state.adminLogin);
  const [rememberMe, setRememberMe] = useState(false);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const onSubmit = async (values) => {
    try {
      const formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      await dispatch(loginAdmin({ data: formdata }));
      reset();
    } catch (err) {}
  };

  useEffect(() => {
    dispatch(checkLocalStorage());

    if (admin) {
      navigate("/admin");
    }
  }, [isLoggedIn, navigate, admin, dispatch]);
  return (
    <div className="h-screen w-full flex justify-between flex-col items-center relative">
      <div className={`absolute top-[-120px] right-0`}>
        <AdminLoginIcon
          width={width >= 1200 ? "479px" : width <= 768 ? "200px" : "280px"}
          height={width >= 1200 ? "695px" : width <= 768 ? "420px" : "480px"}
        />
      </div>
      <div
        style={{
          background: "linear-gradient(to right, #42E198, #129FB7)",
          height: 8,
          width: "100%",
        }}
      />
      <div className="w-[400px] flex justify-center flex-col items-center">
        <p className="xs:text-[18px] md:text-[24px] text-[#454F63] font-sans p-2">
          Secure Login
        </p>
        <form
          className="w-full flex flex-col justify-center items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            name="email"
            type="email"
            placeholder="Email"
            className="border-[#78849E] border-[1px] rounded-xl xs:w-5/6 md:w-full xs:h-[40px] md:h-[60px] px-[20px] mt-[12px]"
            {...register("email", {
              required: {
                value: true,
                message: "Email is required",
              },
            })}
          />
          {errors?.email && (
            <p style={{ color: "red" }}>{errors?.email?.message}</p>
          )}
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="border-[#78849E] border-[1px] rounded-xl xs:w-5/6 md:w-full xs:h-[40px] md:h-[60px] px-[20px] mt-[12px]"
            {...register("password", {
              required: {
                value: true,
                message: "Password is required",
              },
            })}
          />
          {errors?.password && (
            <p style={{ color: "red" }}>{errors?.password?.message}</p>
          )}
          <div className="flex items-center justify-start xs:w-5/6 md:w-full mt-[12px]">
            <input
              type="checkbox"
              id="rememberMe"
              className="form-checkbox h-5 w-5 text-indigo-600"
              checked={rememberMe}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="rememberMe"
              className="ml-2 text-gray-700 xs:text-[10px] md:text-[12px]"
            >
              Remember Me
            </label>
          </div>
          <button
            className="flex items-center justify-center rounded-xl xs:w-5/6 md:w-full xs:h-[40px] md:h-[60px] mt-[12px] bg-[#2B4D70] text-white text-[20px] font-sans "
            onClick={() => {
              handleSubmit();
            }}
          >
            Login
          </button>
        </form>
      </div>
      <div className="w-full xs:px-[25px] md:px-[65px]">
        <div className="xs:h-[50px] md:h-[90px] w-full flex justify-center items-center border-[#00A36A] border-t-2">
          <p className="xs:text-[8px] md:text-[12px] text-[#78849E] font-sans font-semibold">
            © 2023 Ministry of Climate Change and Environment. All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
