import React from "react";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import WaterIcon from "../../Icons/WaterIcon";
import ElectrsityIcon from "../../Icons/ElectrsityIcon";
import GasIcon from "../../Icons/GasIcon";
import DGasIcon from "../../Icons/DGasIcon";
import WasteIcon from "../../Icons/WasteIcon";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  aspectRatio: 1,
  maintainAspectRatio: false,
  // responsive: true,
  plugins: {
    legend: {
      display: false,
      // position: "right",
    },
    tooltip: {
      callbacks: {
        title: (xDatapoint) => {
          return xDatapoint.label;
        },
        label: (yDatapoint) => {
          if (yDatapoint.raw === 200) {
            return "0 Kg";
          } else {
            return yDatapoint.raw + "k";
          }
        },
      },
    },
  },
};

export const data = {
  labels: ["Water", "Electricity", "Fuel", "Domestic gas", "Waste"],
  datasets: [
    {
      // label: "# of Votes",
      data: [6560, 5430, 200, 200, 200],
      backgroundColor: ["#2B4D70", "#00B783", "#D19631", "#86BBD8", "#D7B8B3"],
      borderColor: ["white", "white", "white", "white", "white", "white"],
      borderRadius: 5,
      borderWidth: 4,
      hoverBorderWidth: 0,
      borderAlign: "center",
      cutout: "72%",
    },
  ],
};

export const doughntChartItems = [
  {
    id: 1,
    title: "Water",
    color: "#2B4D70",
    value: "24.14",
    disabled: false,
    icon: <WaterIcon />,
    valueKg: 6560,
  },
  {
    id: 2,
    title: "Electricity",
    color: "#00B783",
    value: "29.17",
    disabled: false,
    icon: <ElectrsityIcon />,
    valueKg: 5430,
  },
  {
    id: 3,
    title: "Fuel",
    color: "#D19631",
    value: "18.84",
    disabled: true,
    icon: <GasIcon />,
    valueKg: "---",
  },
  {
    id: 4,
    title: "Domestic gas",
    color: "#86BBD8",
    value: "16.21",
    disabled: true,
    icon: <DGasIcon />,
    valueKg: "---",
  },
  {
    id: 5,
    title: "Waste",
    color: "#D7B8B3",
    value: "11.65",
    disabled: true,
    icon: <WasteIcon />,
    valueKg: "---",
  },
];

const SimpleDoughnt = ({ dataByDateRagne }) => {
  const textCenter = {
    id: "text-center",

    beforeDatasetDraw(chart, args, pluginOptions) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;
      ctx.save();
      ctx.font = width < 768 ? "700 20px Inter" : "700 30px Inter";
      ctx.fillStyle = "#0A0A0A";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("28,052", width / 2, height / 2.1);

      ctx.font = width < 768 ? `600 13px Inter` : `600 13px Inter`;
      ctx.fillStyle = "#737373";
      ctx.fillText("Kg", width / 2, height / 1.6);
    },
  };

  const data = {
    labels: ["Water", "Electricity", "Fuel", "Domestic gas", "Waste"],
    datasets: [
      {
        // label: "# of Votes",
        data: [
          (dataByDateRagne?.water / 1000).toFixed(1),
          (dataByDateRagne?.electricity / 1000).toFixed(1),
          1,
          1,
          1,
        ],
        backgroundColor: [
          "#2B4D70",
          "#00B783",
          "#D19631",
          "#86BBD8",
          "#D7B8B3",
        ],
        borderColor: ["white", "white", "white", "white", "white", "white"],
        borderRadius: 5,
        borderWidth: 4,
        hoverBorderWidth: 0,
        borderAlign: "center",
        cutout: "72%",
      },
    ],
  };
  return (
    <div className=" xs:h-[135px] sm:h-[135px] md:h-[205px]  xs:w-[135px] sm:w-[135px] md:w-[205px] flex justify-center items-center">
      <Doughnut data={data} plugins={[textCenter]} options={options} />
    </div>
  );
};

export default SimpleDoughnt;
