import React from "react";

const NotificationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73682 18.8301C9.15103 18.8301 9.48682 19.1659 9.48682 19.5801C9.48682 19.7533 9.53068 19.9323 9.62567 20.1089C9.72135 20.2868 9.86934 20.4625 10.073 20.6194C10.2768 20.7763 10.5283 20.9079 10.8164 20.9998C11.1044 21.0917 11.4174 21.1402 11.7364 21.1402C12.0554 21.1402 12.3684 21.0917 12.6563 20.9998C12.9445 20.9079 13.196 20.7763 13.3998 20.6194C13.6034 20.4625 13.7514 20.2868 13.8471 20.1089C13.9421 19.9323 13.986 19.7533 13.986 19.5801C13.986 19.1659 14.3218 18.8301 14.736 18.8301C15.1502 18.8301 15.486 19.1659 15.486 19.5801C15.486 20.0136 15.3747 20.4354 15.1682 20.8194C14.9624 21.202 14.6685 21.5356 14.315 21.8078C13.9618 22.0799 13.5519 22.2886 13.1122 22.4289C12.6723 22.5692 12.2052 22.6402 11.7364 22.6402C11.2676 22.6402 10.8005 22.5692 10.3606 22.4289C9.92086 22.2886 9.51101 22.0799 9.15774 21.8078C8.80433 21.5356 8.51043 21.202 8.30462 20.8194C8.09812 20.4354 7.98682 20.0136 7.98682 19.5801C7.98682 19.1659 8.3226 18.8301 8.73682 18.8301Z"
        fill="#00B783"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1527 2.50665C10.0555 1.55972 12.2338 1.38369 14.2422 1.98489C14.639 2.10368 14.8644 2.52166 14.7456 2.91847C14.6268 3.31529 14.2088 3.54067 13.812 3.42188C12.1659 2.92913 10.3805 3.07347 8.82097 3.84955C7.08486 4.71351 5.9873 6.48565 5.9873 8.42485V9.74613C5.9873 11.1699 5.63253 12.5712 4.95505 13.8234L4.72865 14.2419C3.81979 15.9218 4.81343 17.9997 6.69161 18.3468C10.0265 18.9632 13.4462 18.9632 16.7811 18.3468L16.9413 18.3172C18.8192 17.9701 19.8654 15.9452 19.062 14.2127L18.7974 13.642C18.409 12.8044 18.1565 11.9127 18.0473 11.0003C17.9981 10.589 18.2916 10.2157 18.7029 10.1665C19.1142 10.1173 19.4875 10.4108 19.5367 10.8221C19.6272 11.5781 19.8364 12.317 20.1582 13.0109L20.4228 13.5817C21.6385 16.203 20.0553 19.2671 17.214 19.7922L17.0538 19.8218C13.5386 20.4716 9.93413 20.4716 6.41897 19.8218C3.53934 19.2896 2.01589 16.1037 3.40935 13.5281L3.63576 13.1096C4.19463 12.0766 4.4873 10.9206 4.4873 9.74613V8.42485C4.4873 5.91646 5.90701 3.62418 8.1527 2.50665Z"
        fill="#2B4D70"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8602 4.28711C16.8937 4.28711 16.1102 5.07061 16.1102 6.03711C16.1102 7.00361 16.8937 7.78711 17.8602 7.78711C18.8267 7.78711 19.6102 7.00361 19.6102 6.03711C19.6102 5.07061 18.8267 4.28711 17.8602 4.28711ZM14.6102 6.03711C14.6102 4.24218 16.0653 2.78711 17.8602 2.78711C19.6552 2.78711 21.1102 4.24218 21.1102 6.03711C21.1102 7.83203 19.6552 9.28711 17.8602 9.28711C16.0653 9.28711 14.6102 7.83203 14.6102 6.03711Z"
        fill="#00B783"
      />
    </svg>
  );
};

export default NotificationIcon;
