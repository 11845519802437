import React, { useEffect } from "react";
import Modal from "../../Pages/Landing/Components/Modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  editAdminApi,
  getAdminsByIdApi,
} from "../../redux/Thunks/AdminsThunks";
import Lottie from "lottie-react";
import uaeSpinner from "../../Icons/animation/spinner.json";

const EditAdminModal = ({ isModalOpen, closeModal, targetAdmin }) => {
  const dispatch = useDispatch();
  const { getOneAdminLoading, oneAdmin } = useSelector(
    (state) => state.adminsList
  );
  useEffect(() => {
    dispatch(getAdminsByIdApi({ id: targetAdmin }));
  }, [dispatch, targetAdmin]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("uae_id", data.uae_id);
      formData.append("admin_id", targetAdmin);
      formData.append("password", "");
      dispatch(editAdminApi({ data: formData }));
      reset();
      closeModal();
    } catch (error) {
      console.error("An error occurred during form submission", error);
    }
  };

  useEffect(() => {
    if (oneAdmin) {
      setValue("phone", oneAdmin.phone);
      setValue("uae_id", oneAdmin.uae_id);
      setValue("email", oneAdmin.email);
    }
  }, [oneAdmin, setValue]);
  if (getOneAdminLoading)
    return (
      <div className="w-full h-[20%] flex justify-center items-center">
        <Lottie animationData={uaeSpinner} loop />
      </div>
    );
  return (
    <Modal
      closeIcon
      isOpen={isModalOpen}
      onClose={() => {
        reset();
        closeModal();
      }}
      closeOnOverlayClick={false}
      title="Edit Admin"
      customTitle
      circleClose
      height={400 + Object.keys(errors).length * 25}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 30,
          gap: 15,
        }}
      >
        <div className="w-full flex flex-col">
          <label htmlFor="phone" className="text-[14px] font-[600] font-inter">
            Phone Number
          </label>
          <input
            id="phone"
            name="phone"
            type="number"
            defaultValue={oneAdmin && oneAdmin?.phone}
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("phone", {
              required: {
                value: true,
                message: "phone is required",
              },

              minLength: {
                value: 14,
                message: "phone should be 14 digits",
              },
              maxLength: {
                value: 14,
                message: "phone should be 14 digits",
              },
            })}
          />
          {errors["phone"] && (
            <p className="text-red-500">{errors["phone"].message}</p>
          )}
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="uae_id" className="text-[14px] font-[600] font-inter">
            UAE ID
          </label>
          <input
            id="uae_id"
            name="uae_id"
            type="number"
            defaultValue={oneAdmin && oneAdmin?.uae_id}
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("uae_id", {
              required: {
                value: true,
                message: "UAE ID is required",
              },
            })}
          />
          {errors["uae_id"] && <p className="text-red-500">Invalid UAE ID</p>}
        </div>
        <div className="w-full flex flex-col">
          <label htmlFor="email" className="text-[14px] font-[600] font-inter">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            defaultValue={oneAdmin && oneAdmin.email}
            className="border-[1px] h-[40px] bg-[#F8F8F8] rounded-md border-solid border-[#E1E1E6]"
            {...register("email", {
              required: {
                value: true,
                message: "Email is required",
              },
            })}
          />
          {errors["email"] && <p className="text-red-500">Invalid Email</p>}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <button
            style={{
              backgroundColor: "#2B4D70",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              width: 128,
              height: 40,
              borderRadius: 8,
            }}
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditAdminModal;
