import React from "react";

const Logo = () => {
  return (
    <svg
      width="179"
      height="64"
      viewBox="0 0 179 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_1577)">
        <g clipPath="url(#clip1_19_1577)">
          <path
            d="M80.9448 40.2414C80.983 41.9674 80.6705 43.6831 80.0263 45.2848C79.4316 46.7186 78.5453 48.0133 77.4238 49.0865C76.2977 50.1515 74.9666 50.9762 73.5115 51.5103C70.3266 52.6445 66.8478 52.6445 63.6629 51.5103C62.2006 50.9705 60.8594 50.1467 59.7167 49.0865C58.5637 48.0295 57.6577 46.7315 57.0632 45.2848C56.4162 43.6834 56.0981 41.9683 56.1276 40.2414V20.6802H61.6133V40.0118C61.6208 40.8177 61.7381 41.6189 61.9619 42.3931C62.1963 43.2232 62.5891 44.0001 63.1186 44.6809C63.6946 45.3999 64.4239 45.981 65.2533 46.3819C66.2926 46.8308 67.4127 47.0623 68.5447 47.0623C69.6768 47.0623 70.7968 46.8308 71.8361 46.3819C72.6655 45.981 73.3948 45.3999 73.9708 44.6809C74.4979 43.9998 74.8878 43.2228 75.119 42.3931C75.3362 41.6177 75.4506 40.817 75.4592 40.0118V20.7228H80.9448V40.2414Z"
            fill="#2B4D70"
          />
          <path
            d="M95.0459 20.7228H99.7831L113.068 51.5614H106.808L103.942 44.5109H90.5638L87.7572 51.5614H81.6167L95.0459 20.7228ZM102.003 39.7992L97.2571 27.263L92.4264 39.7992H102.003Z"
            fill="#2B4D70"
          />
          <path
            d="M116.453 20.7228H136.907V25.6726H121.938V33.2844H136.107V38.2258H121.938V46.603H137.647V51.5529H116.453V20.7228Z"
            fill="#2B4D70"
          />
          <path
            d="M143.039 20.7228H163.493V25.6726H148.533V33.2844H162.702V38.2258H148.533V46.603H164.224V51.5529H143.039V20.7228Z"
            fill="#00B783"
          />
          <path
            d="M169.514 20.7228H175V51.5614H169.514V20.7228Z"
            fill="#00B783"
          />
          <path
            d="M7.96467 45.2423C7.65927 45.2505 7.36115 45.1482 7.12522 44.9541C6.88929 44.76 6.73141 44.4872 6.68068 44.1859C6.62995 43.8847 6.68978 43.5752 6.84914 43.3145C7.0085 43.0539 7.25665 42.8595 7.54793 42.7673C10.9822 41.5749 14.0695 39.5541 16.5365 36.8838C19.0035 34.2136 20.7741 30.9763 21.6915 27.4586C21.7288 27.2912 21.7995 27.133 21.8993 26.9935C21.9991 26.8541 22.126 26.7361 22.2725 26.6468C22.4189 26.5575 22.5818 26.4987 22.7515 26.4738C22.9212 26.4489 23.0942 26.4584 23.2601 26.5019C23.426 26.5454 23.5815 26.6219 23.7172 26.7268C23.8528 26.8318 23.966 26.963 24.0498 27.1126C24.1336 27.2623 24.1863 27.4273 24.2049 27.5978C24.2235 27.7683 24.2075 27.9408 24.1579 28.105C23.1374 32.0323 21.1636 35.6472 18.4116 38.6291C15.6596 41.6111 12.2144 43.868 8.3814 45.1997C8.2461 45.2384 8.10501 45.2528 7.96467 45.2423Z"
            fill="#2B4D70"
          />
          <path
            d="M23.52 41.0409C23.2369 41.0435 22.9613 40.9506 22.7375 40.7772C22.6042 40.6747 22.4925 40.5469 22.4087 40.4012C22.325 40.2554 22.2709 40.0945 22.2496 39.9277C22.2282 39.7609 22.2401 39.5916 22.2844 39.4294C22.3287 39.2672 22.4047 39.1154 22.5079 38.9827C26.3322 34.0909 28.3993 28.0546 28.3762 21.8454V21.5732C28.3762 21.1221 28.197 20.6895 27.878 20.3705C27.559 20.0515 27.1264 19.8723 26.6753 19.8723C26.2241 19.8723 25.7915 20.0515 25.4725 20.3705C25.1535 20.6895 24.9743 21.1221 24.9743 21.5732V21.8624C24.9743 22.5768 24.9743 23.3082 24.8807 24.0226C24.8662 24.1902 24.8188 24.3532 24.7413 24.5024C24.6638 24.6517 24.5576 24.7841 24.4289 24.8923C24.3002 25.0005 24.1514 25.0823 23.9911 25.133C23.8307 25.1837 23.662 25.2023 23.4944 25.1878C23.3269 25.1733 23.1639 25.1259 23.0147 25.0484C22.8654 24.9709 22.7329 24.8647 22.6248 24.736C22.5166 24.6072 22.4348 24.4585 22.3841 24.2981C22.3334 24.1378 22.3148 23.969 22.3293 23.8015C22.3803 23.1551 22.4143 22.5088 22.4143 21.8624V21.5732C22.4143 20.4454 22.8624 19.3638 23.6598 18.5663C24.4573 17.7688 25.5389 17.3208 26.6667 17.3208C27.7946 17.3208 28.8762 17.7688 29.6737 18.5663C30.4712 19.3638 30.9192 20.4454 30.9192 21.5732V21.8369C30.9436 28.6156 28.688 35.2055 24.515 40.5476C24.3975 40.7 24.2468 40.8236 24.0744 40.909C23.902 40.9945 23.7124 41.0396 23.52 41.0409Z"
            fill="#2B4D70"
          />
          <path
            d="M9.73362 50.8385C9.43163 50.8378 9.13966 50.73 8.90967 50.5343C8.67968 50.3386 8.52657 50.0676 8.47759 49.7697C8.4286 49.4717 8.48693 49.166 8.64217 48.9069C8.79741 48.6479 9.03949 48.4524 9.32538 48.355C13.2784 47.0202 16.884 44.8208 19.8799 41.9169C20.1256 41.694 20.4485 41.5756 20.7801 41.5868C21.1117 41.598 21.4258 41.7379 21.656 41.9769C21.8861 42.2158 22.0141 42.5351 22.0127 42.8668C22.0114 43.1986 21.8809 43.5168 21.6489 43.7539C18.3795 46.9195 14.4456 49.3165 10.1333 50.7704C10.0053 50.817 9.86987 50.84 9.73362 50.8385Z"
            fill="#2B4D70"
          />
          <path
            d="M7.65839 39.2804C7.36812 39.2798 7.08672 39.1803 6.86064 38.9983C6.63456 38.8162 6.47732 38.5625 6.41488 38.2791C6.35244 37.9956 6.38853 37.6993 6.51719 37.4391C6.64586 37.1789 6.8594 36.9704 7.12258 36.848C9.98334 35.5385 12.4093 33.4372 14.1136 30.7925C14.2974 30.5083 14.5866 30.3088 14.9176 30.2378C15.2485 30.1668 15.5941 30.2302 15.8783 30.414C16.1625 30.5979 16.3621 30.8871 16.4331 31.218C16.504 31.549 16.4406 31.8946 16.2568 32.1788C14.2873 35.2271 11.4908 37.6519 8.19418 39.1698C8.02505 39.243 7.84268 39.2806 7.65839 39.2804Z"
            fill="#2B4D70"
          />
          <path
            d="M17.0224 29.032C16.8772 29.0321 16.7332 29.0062 16.5972 28.9555C16.2781 28.8436 16.0165 28.6097 15.8698 28.3052C15.7231 28.0006 15.7033 27.6502 15.8147 27.331C16.4358 25.5689 16.7522 23.7138 16.7502 21.8454C16.7114 20.5204 16.9388 19.201 17.4191 17.9655C17.8993 16.73 18.6226 15.6034 19.5461 14.6524C20.4696 13.7014 21.5746 12.9455 22.7955 12.4292C24.0164 11.913 25.3286 11.647 26.6541 11.647C27.9797 11.647 29.2918 11.913 30.5128 12.4292C31.7337 12.9455 32.8386 13.7014 33.7621 14.6524C34.6856 15.6034 35.4089 16.73 35.8892 17.9655C36.3694 19.201 36.5969 20.5204 36.558 21.8454C36.558 23.1211 36.4815 24.3969 36.3539 25.6726C36.319 26.0109 36.151 26.3215 35.8871 26.5361C35.6231 26.7506 35.2847 26.8515 34.9464 26.8165C34.608 26.7815 34.2974 26.6136 34.0829 26.3496C33.8684 26.0857 33.7675 25.7473 33.8025 25.4089C33.9284 24.2253 33.9909 23.0357 33.9896 21.8454C33.9907 20.8797 33.8015 19.9233 33.4327 19.0308C33.0639 18.1382 32.5228 17.3272 31.8404 16.6439C31.1579 15.9607 30.3475 15.4187 29.4554 15.0489C28.5633 14.6791 27.6071 14.4887 26.6414 14.4887C24.6924 14.4932 22.8247 15.27 21.4474 16.6489C20.0701 18.0278 19.2954 19.8964 19.2932 21.8454C19.2941 24.0033 18.9289 26.1458 18.2131 28.1815C18.1269 28.429 17.9661 28.6437 17.7529 28.796C17.5397 28.9483 17.2844 29.0308 17.0224 29.032Z"
            fill="#2B4D70"
          />
          <path
            d="M12.6167 55.7798C12.3194 55.7781 12.032 55.6726 11.8042 55.4816C11.5763 55.2905 11.4224 55.0259 11.3689 54.7334C11.3155 54.441 11.3659 54.139 11.5114 53.8797C11.6569 53.6204 11.8884 53.4201 12.1659 53.3134C15.7885 51.9592 19.1494 49.9882 22.0996 47.4876C22.2275 47.3792 22.3755 47.2971 22.5351 47.246C22.6947 47.1948 22.8628 47.1756 23.0298 47.1894C23.1969 47.2032 23.3595 47.2498 23.5086 47.3265C23.6576 47.4032 23.79 47.5085 23.8984 47.6364C24.0067 47.7643 24.0888 47.9122 24.14 48.0718C24.1911 48.2314 24.2103 48.3996 24.1965 48.5666C24.1827 48.7336 24.1361 48.8963 24.0594 49.0453C23.9827 49.1944 23.8774 49.3268 23.7495 49.4352C20.5772 52.1297 16.9596 54.2508 13.0589 55.7032C12.9169 55.7534 12.7674 55.7793 12.6167 55.7798Z"
            fill="#2B4D70"
          />
          <path
            d="M23.3669 56.3921C21.6826 57.5772 19.9017 58.6184 18.0428 59.5049C17.371 59.0456 16.631 58.4163 15.7891 57.7189C17.9389 56.8036 19.9858 55.6632 21.8955 54.317C22.1721 54.1309 22.5106 54.0604 22.8384 54.1207C23.1663 54.181 23.4575 54.3673 23.6498 54.6396C23.8421 54.9119 23.9201 55.2486 23.8672 55.5778C23.8144 55.9069 23.6348 56.2022 23.3669 56.4006V56.3921Z"
            fill="#2B4D70"
          />
          <path
            d="M7.53088 33.2419C7.25857 33.244 6.99274 33.1589 6.77227 32.9991C6.55181 32.8392 6.38828 32.613 6.30559 32.3536C6.22291 32.0941 6.22541 31.815 6.31274 31.5571C6.40008 31.2992 6.56766 31.0759 6.79096 30.9201C8.25044 29.8995 9.44177 28.5414 10.2636 26.9614C11.0854 25.3815 11.5134 23.6263 11.5112 21.8454C11.5104 21.0077 11.5787 20.1714 11.7153 19.345C11.7426 19.1775 11.8027 19.017 11.8921 18.8726C11.9815 18.7283 12.0985 18.603 12.2363 18.5039C12.3741 18.4048 12.5301 18.3338 12.6953 18.295C12.8606 18.2562 13.0318 18.2503 13.1994 18.2776C13.3669 18.305 13.5274 18.3651 13.6717 18.4545C13.816 18.5439 13.9413 18.6608 14.0404 18.7986C14.1395 18.9364 14.2105 19.0924 14.2494 19.2577C14.2882 19.4229 14.2941 19.5942 14.2667 19.7617C14.1542 20.4506 14.0973 21.1474 14.0966 21.8454C14.0937 24.035 13.5655 26.192 12.5562 28.1351C11.5469 30.0782 10.086 31.7508 8.29632 33.0123C8.07209 33.1683 7.80395 33.2487 7.53088 33.2419Z"
            fill="#2B4D70"
          />
          <path
            d="M14.2922 16.7935C14.0908 16.791 13.8927 16.7415 13.7139 16.6489C13.414 16.4977 13.1864 16.2336 13.0812 15.9146C12.9759 15.5957 13.0016 15.248 13.1525 14.948C14.8119 11.655 17.6012 9.0693 21.0104 7.66381C24.4195 6.25831 28.2206 6.1269 31.7186 7.2936C32.0389 7.40638 32.3013 7.64178 32.4481 7.94801C32.5948 8.25424 32.6139 8.60623 32.5011 8.92653C32.3883 9.24683 32.1529 9.5092 31.8467 9.65594C31.5404 9.80267 31.1884 9.82176 30.8681 9.70898C27.9546 8.75163 24.7943 8.87145 21.9616 10.0467C19.129 11.2219 16.812 13.3745 15.4318 16.1131C15.3219 16.3196 15.1576 16.4921 14.9567 16.612C14.7559 16.7319 14.5261 16.7947 14.2922 16.7935Z"
            fill="#2B4D70"
          />
          <path
            d="M40.9038 22.917C40.5699 22.9171 40.2492 22.7862 40.0107 22.5524C39.7721 22.3187 39.6348 22.0007 39.6281 21.6668C39.6019 19.7199 39.128 17.8052 38.2431 16.0708C37.3582 14.3364 36.0861 12.829 34.5252 11.6651C34.2545 11.4621 34.0756 11.1599 34.0277 10.8249C33.9799 10.49 34.067 10.1497 34.27 9.87907C34.4731 9.6084 34.7753 9.42946 35.1102 9.38161C35.4452 9.33376 35.7854 9.42092 36.0561 9.62392C37.9275 11.0198 39.453 12.8272 40.5148 14.9065C41.5766 16.9858 42.1462 19.2813 42.1796 21.6158C42.1841 21.7857 42.1544 21.9549 42.0923 22.1132C42.0301 22.2714 41.9367 22.4156 41.8177 22.537C41.6987 22.6584 41.5564 22.7546 41.3994 22.8199C41.2424 22.8852 41.0739 22.9182 40.9038 22.917Z"
            fill="#2B4D70"
          />
          <path
            d="M46.0749 19.5151C45.7777 19.5123 45.4905 19.4066 45.2626 19.2158C45.0346 19.025 44.8798 18.7611 44.8247 18.469C44.0369 14.2766 41.8089 10.4914 38.5258 7.76781C35.2428 5.04423 31.1112 3.55355 26.8454 3.55355C22.5797 3.55355 18.4481 5.04423 15.1651 7.76781C11.882 10.4914 9.65397 14.2766 8.86618 18.469C8.83546 18.6365 8.77205 18.7963 8.67956 18.9394C8.58707 19.0824 8.46733 19.2058 8.32715 19.3026C8.18697 19.3993 8.0291 19.4675 7.86257 19.5032C7.69604 19.5389 7.52409 19.5415 7.35656 19.5108C7.18903 19.4801 7.02919 19.4167 6.88617 19.3242C6.74314 19.2317 6.61974 19.112 6.52299 18.9718C6.42625 18.8316 6.35806 18.6737 6.32232 18.5072C6.28659 18.3407 6.28401 18.1687 6.31472 18.0012C7.21092 13.2226 9.74928 8.90756 13.4906 5.80265C17.232 2.69774 21.941 0.998268 26.8029 0.998268C31.6648 0.998268 36.3738 2.69774 40.1152 5.80265C43.8566 8.90756 46.3949 13.2226 47.2911 18.0012C47.3219 18.1659 47.3199 18.3351 47.2852 18.4991C47.2506 18.6631 47.184 18.8186 47.0892 18.9568C46.9944 19.0951 46.8733 19.2132 46.7329 19.3046C46.5924 19.396 46.4353 19.4589 46.2705 19.4896C46.2062 19.5037 46.1407 19.5123 46.0749 19.5151Z"
            fill="#2B4D70"
          />
          <path
            d="M26.1309 62.303C20.7914 62.1076 15.7303 59.8711 11.9911 56.0543C8.25193 52.2376 6.11967 47.1317 6.03394 41.7893V22.2281C6.03394 21.8898 6.16835 21.5653 6.40759 21.326C6.64684 21.0868 6.97132 20.9524 7.30966 20.9524C7.64801 20.9524 7.97249 21.0868 8.21173 21.326C8.45098 21.5653 8.58539 21.8898 8.58539 22.2281V41.5001C8.59225 46.1192 10.3445 50.565 13.4911 53.9465C16.6377 57.3281 20.946 59.3956 25.5526 59.7345C25.6887 60.5935 25.8843 61.4525 26.1309 62.303Z"
            fill="#00B783"
          />
          <path
            d="M46.432 21.4287L45.488 21.8284L45.1308 21.9815C39.1062 24.6758 33.9897 29.0553 30.3982 34.592C26.8066 40.1288 24.8934 46.5862 24.8892 53.1858C24.8863 55.3783 25.0971 57.5659 25.5186 59.7175C25.6887 60.568 25.8843 61.4185 26.1309 62.269C26.369 62.269 26.6072 62.269 26.8453 62.269C27.4917 62.269 28.1295 62.269 28.7589 62.1839C33.871 61.7037 38.627 59.3566 42.1178 55.5912C45.6087 51.8258 47.5897 46.9061 47.6822 41.7723V20.9609C47.257 21.1055 46.8572 21.2671 46.432 21.4287Z"
            fill="#00B783"
          />
          <path
            d="M27.6619 59.0371C39.9089 47.2239 33.6153 33.2079 46.0068 23.1722C43.8337 25.7588 42.1089 28.691 40.9039 31.8471C37.2383 42.4016 38.1994 51.7825 27.7044 59.0627L27.6619 59.0371Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_19_1577">
          <rect width="179" height="64" fill="white" />
        </clipPath>
        <clipPath id="clip1_19_1577">
          <rect
            width="169"
            height="61.32"
            fill="white"
            transform="translate(6 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
